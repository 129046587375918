import { DeleteOption } from '@app/core/model/delete-options';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import environment from '../../../../environments/environment';
import { ResourceType } from '../../model/resource-type';
import { PropertyName } from '@app/core/model/properties/property-name';
/**
 * This class holds the complete api urls for various BSSH APIs
 * Can be used when making direct HTTP calls instead of using SDK.
 */
export class BsApiEndPoints {
    // Any endpoints belonging to the cloud.web(old site) apis, should be added in 'LegacyBsApiEndpointsService'
    static v2Prefix = 'v2/';
    static v1Prefix = 'v1pre3/';
    static v2KeepAlive = `${environment.apiEndpoint}/session/keepalive`;
    static v1GetInvite = `${environment.apiUrl}/v1pre3/users/current/invites`;
    static v1UpdateInvite = `${environment.apiUrl}/v1pre3/invites/`;
    static v1Meta = `${environment.apiUrl}/v1pre3/meta`;
    static v1GetProject = `${environment.apiUrl}/v1pre3/users/current/projects`;
    static V1PRE3_URL = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v1pre3/`;

    static readonly ARCHIVE_URL = `${environment.apiUrl}/v2/archive`;
    static readonly RESTORE_URL = `${environment.apiUrl}/v2/restore`;
    static readonly UNZIP_URL = `${environment.apiUrl}/v2/unzip`;
    static readonly LIBRARY_PREP_KITS_URL = `${environment.apiUrl}/v2/libraryprepkits`;
    static readonly BULK_DELETE_URL = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v1pre3/bulkdelete`;
    static readonly TRASH_ITEMS_URL = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v2/trash`;

    static readonly LANES_URL = `${environment.apiEndpoint}/lanes/`;

    static readonly ANALYSES_URL = `${environment.apiEndpoint}/appsessions/`;
    static readonly ANALYSES_V1PRE3_URL = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v1pre3/appsessions`;
    static readonly ANALYSES_TRACK_URL = `${BsApiEndPoints.ANALYSES_URL}track`;

    static TRANSFER = {
        LIST_PENDING_TRANSFER_URL: `${BsApiEndPoints.V1PRE3_URL}/users/current/invites`,
    };

    static TRASH_RUN_API_URL = `${BsApiEndPoints.V1PRE3_URL}runs/`;
    static TRASH_PROJECT_API_URL = `${BsApiEndPoints.V1PRE3_URL}projects/`;

    static V2_APPSESSION_PROPERTIES_LOGS_TAIL = 'v2/appsessions/{Id}/Properties/Logs.Tail';
    static V2_APPSESSION_PROPERTIES = 'v2/appsessions/{id}/Properties';
    static V2_APPSESSION_REPORTS = 'v2/appsessions/{id}/reports';
    static V2_APPSESSION_ICA_STEPS = 'v2/appsessions/{id}/icasteps';

    static V2_USER_NOTIFICATIONS_API_PATH = 'v2/users/current/notifications';

    static V1PRE3_APPRESULTS_PATH =  "v1pre3/appresults";
    static V1PRE3_APPRESULTS = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${BsApiEndPoints.V1PRE3_APPRESULTS_PATH}`;

    static V1_APPSESSION_APPRESULT_PATH =  "/v1pre3/appsessions/{Id}/appresults";
    static V1_APPRESULT_FILE_PATH = '/v1pre3/appresults/{Id}/files';

    static V1PRE3_APPSESSIONS = `${StringUtilities.trimTrailingSlash(BsApiEndPoints.V1PRE3_URL)}/appsessions`;
    static V2_DATASETS = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v2Prefix)}/datasets`;
    static DATASET_TYPES_PATH = 'v2/datasettypes/{Id}';

    static V1PRE3_INVITES_API_PATH = 'v1pre3/users/current/invites';

    static getCmsContentsUrl(contentGroupName: string) {
        return `${this.V1PRE3_URL}/contentgroups/${contentGroupName}/contententries`;
    }

    static getRunInfoEditUrl(resourceId: string) {
        return `${this.V1PRE3_URL}${ResourceType.RUNS.toString()}/${resourceId}`;
    }

    static getDownloadInfoUrl(resourceType: ResourceType, resourceId: string) {
        return `${this.V1PRE3_URL}${resourceType.toString()}/${resourceId}`;
    }

    static getChangeLaneUrl(laneId: string) {
        return `${this.LANES_URL}${laneId}`;
    }

    static getTransferUrl(resourceType: ResourceType, id: string) {
        return `${this.V1PRE3_URL}${resourceType}/${id}/invites`;
    }

    static getCancelInvitationUrl(invitationId: string) {
        return `${this.V1PRE3_URL}/invites/${invitationId}/cancel`;
    }

    static getFilesFromDatasetUrl(datasetId: string) {
        return `${this.V2_DATASETS}/${datasetId}/files/`;
    }

    static getFileUrl(fileId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v2/files/${fileId}`;
    }

    static getDatasetType(datasetTypeId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v2Prefix)}/datasettypes/${datasetTypeId}`;
    }

    static getTrashResouceUrl(resourceType: ResourceType, resourceId: string, deleteOption?: DeleteOption) {
        let trashResourceUrl = `${this.V1PRE3_URL}${resourceType.toString()}/${resourceId}`;
        if (deleteOption === DeleteOption.PRESERVE_METADATA) {
            trashResourceUrl = trashResourceUrl + `?Preserve=metadata`;
        }
        return trashResourceUrl;
    }

    static getTrashResouceUrlWithV2(resourceType: ResourceType, resourceId: string, deleteOption?: DeleteOption) {
        let trashResourceUrl = `${environment.apiEndpoint}/${resourceType.toString()}/${resourceId}`;
        if (deleteOption === DeleteOption.PRESERVE_METADATA) {
            trashResourceUrl = trashResourceUrl + `?Preserve=metadata`;
        }

        return trashResourceUrl;
    }

    static getCapabilitiesUrl(resourceType: ResourceType, resourceId: string) {
        return `${environment.apiEndpoint}/${resourceType.toString()}/${resourceId}/capabilities`;
    }

    static getProjectCapabilitiesUrl(resourceId: string) {
        return `${environment.apiUrl}/v1pre3/projects/${resourceId}/capabilities`;
    }

    static getRunSequencingStatsUrl(runId: string): string {
        return `${environment.apiEndpoint}/runs/${runId}/sequencingstats`;
    }

    static getInstrumentListUrl(): string {
        return `${environment.apiEndpoint}/instruments`;
    }

    static getTrashAppSessionUrl(appsessionsId: string, ByPassTrash: boolean = false): string {
        let url = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v1pre3/appsessions/${appsessionsId}`;
        if(ByPassTrash) {
            return `${url}?ByPassTrash=true`;
        } else {
            return url;
        }
    }

    static getRunLaneIndexSummaryUrl(resourceId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v2/${ResourceType.RUNS.toString()}/${resourceId}/laneindexsummaries`;
    }
    static getBiosampleWorkflowUploadUrl(): string {
        return `${environment.apiEndpoint}/biosamples/manifestimport`;
    }

    static getPlotOptionsUrl(runId: string, chartType: string): string {
        return `${this.V1PRE3_URL}runs/${runId}/${chartType}/options`;
    }
    static getPlotDataUrl(runId: string, chartType: string): string {
        return `${this.V1PRE3_URL}runs/${runId}/${chartType}/data`;
    }

    static getRunRequeueableUrl(runId: string): string {
        return `${environment.apiEndpoint}/runs/${runId}/requeueable`;
    }

    static getValidateSamplesheetUrl(runId: string): string {
      return `${environment.apiEndpoint}/runs/${runId}/validatesamplesheet`;
    }

    static getRequeueSamplesUrl(runId: string): string {
        return `${environment.apiEndpoint}/runs/${runId}/requeue`;
    }

    // Fetch a run's biosamples via the lane library mappings
    static getRunsBiosamplesUrl(runId: string): string {
        return `${environment.apiEndpoint}/runs/${runId}/lanelibrarymappings`;
    }

    static getSamplesheetRequestUrl(runId: string): string {
        return `${environment.siteUrl}/api/run/${runId}/samplesheet`;
    }

    static getBiosampleLibrariesPath(biosampleId: string): string {
        return `v2/biosamples/${biosampleId}/libraries`;
    }

    static getBiosamplesPath(biosampleId: string) {
        return `biosamples/${biosampleId}`;
    }

    static getV2BiosamplesApiUrl(biosampleId: string) {
        return `${StringUtilities.trimTrailingSlash(BsApiEndPoints.v2Prefix)}/${this.getBiosamplesPath(biosampleId)}`;
    }

    static getBiosampleLibrariesUrl(biosampleId: string): string {
        return `${environment.apiEndpoint}/biosamples/${biosampleId}/libraries`;
    }

    static getProjectDetailsUrl(projectId: string): string {
        return `${environment.apiUrl}/v1pre3/projects/${projectId}`;
    }

    static getV2ProjectsApiUrl(projectId: string) {
        return `${BsApiEndPoints.v2Prefix}${this.getProjectsPath(projectId)}`;
    }

    static getProjectsPath(projectId: string) {
        return `projects/${projectId}`;
    }

    static getProjectSamplesUrl(projectId: string): string {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v1Prefix)}/projects/${projectId}/samples`;
    }

    static getSamplesPath(sampleId: string) {
        return `samples/${sampleId}`;
    }

    static getSamplesApiUrl(sampleId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v1Prefix)}/${this.getSamplesPath(sampleId)}`;
    }

    static postV2UsersIdV2biosampleregistryApiUrl(userId: string) {
        return `${environment.apiEndpoint}/users/${userId}/v2biosampleregistry`;
    }

    static getV2ApplicationsLaunchApiUrl(appId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v2Prefix)}/applications/${appId}/launch`;
    }

    static getApplicationLogoUrl(appId: string): string {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v1pre3/applications/${appId}/icon/?ImageSize=large`;
    }

    static getAppResultFilePropertiesUrl(fileId: string): string {
        return `${environment.apiUrl}/v1pre3/files/${fileId}/properties`;
    }

    static getAppResultFilesListUrl(appResultId: string, queryParams?: { propertyName?: PropertyName, limit?: number }): string {
        let url = `${environment.apiUrl}/v1pre3/appresults/${appResultId}/files`;
        if(queryParams) {
            url = `${url}?${this.getQueryParamsUrl(queryParams)}`;
        }
        return url;
    }

    static getSampleFilesListUrl(sampleId: string, queryParams?: { propertyName?: PropertyName, limit?: number }): string {
        let url = `${environment.apiUrl}/v1pre3/samples/${sampleId}/files`;
        if(queryParams) {
            url = `${url}?${this.getQueryParamsUrl(queryParams)}`;
        }
        return url;
    }

    static getFilesUrl(fileId: string, queryParams?: { Statuses?: string[] }): string {
        let url = `${environment.apiUrl}/v1pre3/files/${fileId}`;
        if(queryParams) {
            url = `${url}?${this.getQueryParamsUrl(queryParams)}`;
        }
        return url;
    }

    static getFileContentUrl(fileId: string, redirect?: string): string {
        let url = `${environment.apiUrl}/v1pre3/files/${fileId}/content`;
        if (redirect) {
            url = `${url}?redirect=${redirect}`
        }
        return url;
    }

    static getQueryParamsUrl(queryParamsObject: any): string {
        const queryArray = [];
        for(const [key, value] of Object.entries(queryParamsObject)) {
            if(value instanceof Array) {
                value.forEach((subsetValue) => {
                    queryArray.push(`${key}=${subsetValue}`);
                });
            } else {
                queryArray.push(`${key}=${value}`);
            }
        }
        return queryArray.join('&');
    }

    static getAppResultPropertiesUrl(appResultId: string): string {
        return `${environment.apiUrl}/v1pre3/appresults/${appResultId}/properties`;
    }

    static getAppSessionPropertiesUrl(appSessionId: string): string {
        return `${environment.apiUrl}/v1pre3/appsessions/${appSessionId}/properties`;
    }
    static getAppSessionReport(appSessionId: string): string{
        return `${environment.apiUrl}/v2/appsessions/${appSessionId}/reports?status=active`;
    }

    static getAppSessionUrl(appSessionId: string): string {
        return `${BsApiEndPoints.ANALYSES_V1PRE3_URL}/${appSessionId}`;
    }

    static getAppSessionIcaStepsUrl(appSessionId: string) {
        return `${environment.apiUrl}/v2/appsessions/${appSessionId}/icasteps`;
    }

    static postV2UsersIdAnalysisConfigTemplateRequestApiUrl(userId: string) {
        return `${environment.apiEndpoint}/users/${userId}/v2analysisconfigtemplate`;
    }

    static getAppsessionPropertyApiUrl(appSessionId: string, propertyName: string ) {
        return `${environment.apiEndpoint}/appsessions/${appSessionId}/Properties/${propertyName}`;
    }

    static getAppsessionAppResultsApiUrl(appSessionId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v1Prefix)}/appsessions/${appSessionId}/appresults`;
    }

    static getDataSetApiUrl() {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v2Prefix)}/datasets`;
    }

    static getFileSetApiUrl(appSessionId: string, fileSetId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v1Prefix)}/appsessions/${appSessionId}/filesets/${fileSetId}/files`;
    }

    static getAppResultApiUrl(appResultId: number) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v1Prefix)}/appresults/${appResultId}/files`;
    }

    static getIcaDataDownloadUrl() {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${BsApiEndPoints.v2Prefix}icaDownloads/create-download-url`;
    }

    static getChildSampleQueryUrl(sampleId: string){
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v1Prefix)}/samples/${sampleId}/childsamples`;
    }

    static getLibraryPoolsPath(poolId: string) {
        return `librarypools/${poolId}`;
    }

    static getPoolApiUrl(poolId: string) {
        return `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/${StringUtilities.trimTrailingSlash(BsApiEndPoints.v2Prefix)}/${this.getLibraryPoolsPath(poolId)}`;
    }
}

export interface IBsshApiEndpoint {
    method: string;
    path: string;
    code?: string;
    url?: string;
    responseCodesOK?: number[];
    pathRegex?: RegExp;
}
