import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DescriptionParser, EMPTY_DESCRIPTION, IParsedDescription } from './description-parser';

@Component({
  selector: 'app-description-text',
  templateUrl: './description-text.component.html',
  styleUrls: ['./description-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DescriptionTextComponent implements OnChanges, AfterViewInit {

  @Input() description = '';
  @Input() lengthLimit = 140;
  @Input() parseAsMarkdown = true;
  @Input() checkFromIllumina = false;
  @Input() avoidPopover = false;
  @ViewChild('content', {
    static: false,
    read: ElementRef
  }) content: ElementRef;

  public formattedDescription: IParsedDescription = EMPTY_DESCRIPTION;
  public isInPopover = false;

  showLongContent = false;

  private _elementRef: ElementRef;

  constructor(private elementRef: ElementRef) { 
    this._elementRef = elementRef;
  }

  ngAfterViewInit() {
    this.isInPopover = this._elementRef.nativeElement.parentElement == null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.description) {
      this.formattedDescription = EMPTY_DESCRIPTION;
    } else if (this.parseAsMarkdown) {
      this.formattedDescription = DescriptionParser.parse(
        this.description, // Raw markdown input
        this.lengthLimit, // Max characters to be included in short content
        this.checkFromIllumina, // if true, only render links of illumina.com domains
        true, // short content for preview should only render links, not other HTML elements
      );
    } else {
      this.formattedDescription = DescriptionParser.parsePlainText(this.description, this.lengthLimit);
    }
    if (this.avoidPopover) {
      // Alternative used to collapse and expand from short to long content, rather than using a popover 
      const ellipses = '<p>...</p>';
      if (this.formattedDescription.longContent && this.formattedDescription.shortContent.endsWith(ellipses)) {
        this.formattedDescription.shortContent = this.formattedDescription.shortContent.slice(0, -ellipses.length);
      }
    }
  }

  changeToLongForm(): void {
    this.showLongContent = true;
    this.content.nativeElement.innerHTML = this.formattedDescription.longContent;
  }

}
