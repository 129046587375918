import marked from 'marked';
import DOMPurify from 'dompurify';

const DEFAULT_PREVIEW_CHAR_LIMIT = 40;

export interface IParsedDescription {
  shortContent: string;
  longContent?: string;
}

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.NodeType === 'A') {
    node.setAttribute('target', '_blank');
  }
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

export class DescriptionParser {
  /**
   * Parse Markdown String and returns HTML string
   * @param mdContent Markdown content to be parsed
   * @param previewCharLimit Max number of characters to show in preview
   * @param removeNonIlluminaLinks Option to remove non-Illumina links
   * @param previewOnlyLinks Preview to not include other HTML elements other than a tags
   */
  public static parse(mdContent: string, previewCharLimit = DEFAULT_PREVIEW_CHAR_LIMIT, removeNonIlluminaLinks = false, previewOnlyLinks = false): IParsedDescription {
    const fullHtmlElement = this.buildHtmlElement(mdContent);
    let [ previewHtmlElement, expandedHtmlElement ] = this.splitContent(
      fullHtmlElement, previewCharLimit, previewOnlyLinks
    );
    if (removeNonIlluminaLinks) {
      [ previewHtmlElement, expandedHtmlElement ] = this.removeNonIlluminaLinks(previewHtmlElement, expandedHtmlElement);
    }
    const previewHtmlString = DOMPurify.sanitize(previewHtmlElement);
    const expandedHtmlString = DOMPurify.sanitize(expandedHtmlElement || '');
    return { shortContent: previewHtmlString, longContent: expandedHtmlString };
  }

  public static parsePlainText(content: string, previewCharLimit = DEFAULT_PREVIEW_CHAR_LIMIT): IParsedDescription {
    if (content.length <= previewCharLimit) {
      return { shortContent: content };
    } else {
      const preview = content.substr(0, previewCharLimit) + '...';
      return { shortContent: preview, longContent: content };
    }
  }

  private static buildHtmlElement(mdContent: string): HTMLElement {
    const container = document.createElement('fragment');
    const fullHtmlContent = DOMPurify.sanitize(marked.parse(mdContent) as string);
    container.innerHTML = fullHtmlContent;
    return container;
  }

  private static splitContent(el: HTMLElement, previewCharLimit: number, previewOnlyLinks: boolean): [HTMLElement, HTMLElement | null] {
    let previewHtmlElement: HTMLElement;
    let expandedHtmlElement: HTMLElement | null = null;

    previewHtmlElement = this.cutContent(el, previewCharLimit, previewOnlyLinks);
    if (this.needExpandedContent(el, previewCharLimit)) {
      expandedHtmlElement = el;
    }
    return [ previewHtmlElement, expandedHtmlElement ];
  }

  private static needExpandedContent(el: HTMLElement, previewCharLimit: number): boolean {
    // `textContent` returns content as in markup while `innerText` returns as rendered
    return el.innerText.trim().length > previewCharLimit;
  }

  private static cutContent(template: HTMLElement, maxChars: number, previewOnlyLinks: boolean) {
    // BFS the DOM element
    let truncatedHtml = '';
    let currentLength = 0;
    let exceeded = false;

    function traverseNode(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingLength = maxChars - currentLength;
        const textContent = node.textContent;

        if (textContent === null) {
          // Do nothing
        } else if (textContent.length <= remainingLength) {
          truncatedHtml += textContent;
          currentLength += textContent.length;
        } else {
          truncatedHtml += textContent.substring(0, remainingLength);
          currentLength = maxChars;
          exceeded = true;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName === 'A') {
          // Handle link element
          const linkText = node.textContent;
          const remainingLength = maxChars - currentLength;

          if (linkText === null) {
            // Do nothing
          } else if (linkText.length <= remainingLength) {
            truncatedHtml += node.outerHTML;
            currentLength += linkText.length;
          } else {
            const copiedNode = node.cloneNode(true);
            copiedNode.innerText = linkText.substring(0, remainingLength);
            truncatedHtml += copiedNode.outerHTML;
            currentLength = maxChars;
            exceeded = true;
          }
        } else {
          // Continue traversing for other elements
          if (currentLength >= maxChars) {
            return;
          } else {
            if (!previewOnlyLinks) {
              truncatedHtml += `<${node.tagName.toLowerCase()}>`;
            }
            for (const childNode of node.childNodes) {
              if (currentLength >= maxChars) {
                break;
              }
              traverseNode(childNode);
            }
            if (!previewOnlyLinks) {
              truncatedHtml += `</${node.tagName.toLowerCase()}>`;
            }
          }
        }
      }
    }

    traverseNode(template);

    const wrapper = document.createElement('fragment');
    if (exceeded) {
      wrapper.innerHTML = truncatedHtml.replace(/^<fragment>/, '').replace(/<\/fragment>$/, '') + '<p>...</p>';
    } else {
      wrapper.innerHTML = truncatedHtml.replace(/^<fragment>/, '').replace(/<\/fragment>$/, '');
    }
    return wrapper as HTMLElement;
  }

  private static removeNonIlluminaLinks(previewHtmlElement: HTMLElement, expandedHtmlElement: HTMLElement | null): [HTMLElement, HTMLElement | null] {
    const ILMN_REGEX = /https?:\/\/[\w\-\.]*\.illumina\.com.*/;

    const newPreviewHtmlElement = previewHtmlElement.cloneNode(true) as HTMLElement;
    const newExpandedHtmlElement = expandedHtmlElement ? expandedHtmlElement.cloneNode(true) as HTMLElement : null;

    function replaceLinks(node: HTMLElement | null) {
      if (node === null || node.nodeType !== Node.ELEMENT_NODE) {
        return;
      }

      const linkElements = node.querySelectorAll('a');
      linkElements.forEach(linkElement => {
        if (!linkElement.href.match(ILMN_REGEX)) {
          const textNode = document.createTextNode(linkElement.innerText);
          linkElement.replaceWith(textNode);
        }
      });

      node.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          replaceLinks(child as HTMLElement);
        }
      });
    }

    replaceLinks(newPreviewHtmlElement);
    replaceLinks(newExpandedHtmlElement);
    return [ newPreviewHtmlElement, newExpandedHtmlElement ];
  }
}

export const EMPTY_DESCRIPTION = DescriptionParser.parse('');
