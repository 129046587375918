import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { ReloadPageService } from '../reload-page.service';
import { CodeFeaturesService } from '../user/code-features.service';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedirectPathResolverService implements Resolve<string | null> {

  constructor(private reloadPageService: ReloadPageService, 
              private codeFeatureService: CodeFeaturesService,
              private currentUserStore: CurrentUserStore) { }

  resolve(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<string> | null {

    const routerStateUrl = routerState.url;
    return this.currentUserStore.stateChanged.pipe(
      filter(state => state != null && state.currentUserCodeFeatures != null),
      first(),
      switchMap(() => {
        if (this.codeFeatureService.redirect404sToCloudWebEnabled) {
          this.reloadPageService.reloadPage(routerStateUrl);
          return EMPTY;
        } else {
          return of(`${routerStateUrl}`);
        }
      }
      ));
  }
}
