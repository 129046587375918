<div
  class="description-panel"
  [ngClass]="{'popover-description': isInPopover}">
  <span
     #content
     class='form-field-description-content'
     [innerHtml]="formattedDescription.shortContent">
  </span>
  <lib-popover
      *ngIf="!avoidPopover && formattedDescription.longContent"
      class="ml-2"
      popoverType="ellipsis"
      popoverSize="medium"
      popoverPlacement="right">
      <span class="link-break-all" [innerHtml]="formattedDescription.longContent"></span>
  </lib-popover>
  <button
    [ngClass]="{'hidden': showLongContent}"
    class="lib-popover__ellipsis-button"
    *ngIf="avoidPopover && formattedDescription.longContent"
    (click)="changeToLongForm()"
  ></button>
</div>
