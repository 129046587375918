import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';
import { AWSError } from 'aws-sdk/lib/error';
import { BehaviorSubject } from 'rxjs';

const PART_SIZE: number = 1024 * 1024 * 50; //Minimum 50MB per chunk
const MAX_SINGLE_FILE_SIZE_IN_BYTES: number = 1024 * 1024 * 100; // 100MB
const MIN_PART_SIZE: number = 1024 * 1024 * 5; // Minimum part size in AWS SDK
const MAX_UPLOAD_RETRIES = 2; // Maximum number of times to retry upload in case of failures
export interface UploadCredential {
  AuthKey: string,
  AuthSecret: string,
  AuthToken: string,
  ExpiresOn?: string,
  BucketName: string,
  Prefix: string,
  Region: string
}

@Injectable({
  providedIn: 'root'
})
export class S3DirectUploadService {

  private bytesLoadedSubject = new BehaviorSubject<number>(0);
  public bytesLoaded$ = this.bytesLoadedSubject.asObservable();

  private s3: S3;
  private bucketAndKey: {
    Bucket: string,
    Key: string
  };

  private runningUploads: {[key: string]: S3.ManagedUpload} = {};
  /**
   * Upload progress modal may open before `startFileUpload` here is called.
   * -> `this.runningUploads` is not yet set for this filename
   * When user cancel upload via the Upload progress modal in this case,
   * the upload will still start and not be cancelled.
   * Tracking the uploads to cancel (by fileName) to prevent this issue.
   */
  private uploadsToCancel = new Set<string>();

  constructor() { }

  /**
   * start a upload. Switch to multipart when file is larger than 100MB
   * @param credentials
   * @param file
   * @param callback
   * @returns ETag in the callback successData when upload completed
   */
  startFileUpload(credentials: UploadCredential, file: File, callback?: (error, successData: string) => void) {

    this.prepareForFileUpload(credentials, file);

    let partSize: number = file.size > MAX_SINGLE_FILE_SIZE_IN_BYTES ? PART_SIZE : file.size;

    // A "rule" from AWS SDK: when performing multi-part upload, if file size is smaller than 5 MB,
    // set part size to 5 MB (it won't affect the actual file size uploaded). Otherwise, it will throw error
    if (partSize < MIN_PART_SIZE) {
      partSize = MIN_PART_SIZE;
    }

    const queueSize: number = file.size > MAX_SINGLE_FILE_SIZE_IN_BYTES ? 5 : 1;

    const managedUpload = new S3.ManagedUpload({
      partSize,
      queueSize,
      params: {
        ...this.bucketAndKey,
        Body: file,
      },
      service: this.s3
    });

    if (this.uploadsToCancel.has(file.name)) {
      this.uploadsToCancel.delete(file.name);
    } else {
      this.runningUploads[file.name] = managedUpload;

      managedUpload.send((err: AWSError, data: S3.ManagedUpload.SendData) => {
        delete this.runningUploads[file.name];
        if (err) {
          callback(err, null);
        } else {
          callback(null, data.ETag || "");
        }
      });

      managedUpload.on('httpUploadProgress', (progress) => {
        this.bytesLoadedSubject.next(progress.loaded);
      });
    }
  }

  abortUpload(fileName: string) {
    if (this.runningUploads[fileName]) {
      this.runningUploads[fileName].abort();
      delete this.runningUploads[fileName];
    } else {
      this.uploadsToCancel.add(fileName);
    }
  }

  private prepareForFileUpload(credentials: UploadCredential, file: File) {

    this.bucketAndKey = {
      Bucket: credentials.BucketName,
      Key: `${credentials.Prefix}/${file.name}`,
    }

    const config: S3.ClientConfiguration = {
      accessKeyId: credentials.AuthKey,
      secretAccessKey: credentials.AuthSecret,
      sessionToken: credentials.AuthToken,
      region: credentials.Region,
      maxRetries: MAX_UPLOAD_RETRIES,
      httpOptions: {
        // TODO: Might want to capture chunk upload status and hook into a progress bar
        // so user has more feedback for long uploads.

        // timeout=0 is necessary for large file uploads. Microarray analysis files can be up to 1GB.
        // The default is 2 minutes, this removes it. See related github issue:
        // https://github.com/aws/aws-sdk-js/issues/1704#issuecomment-326058806
        // 50MB chunks may work on a good network (upload speeds ~100MB) but failed consistenly
        // on everyone's home networks over VPN which avg. about 10MB.
        timeout: 0
      }
    }
    this.s3 = new S3(config);
  }
}
