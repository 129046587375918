<ng-container [formGroup]="parentFormGroup">
  <ng-container *ngIf="!(isLoading$ | async); else loadingOptionsSkeleton">
    <combobox autoselect autodisable
        #genomeCombobox
        [enableDeleteBtn]="true"
        [searchMode]="true"
        [formControlName]="genomeFormControlName"
        [label]="fieldLabel"
        [isLoading]="isLoading$ | async"
        data-cy="referenceGenome">
      <!-- Genome Grouped Options -->
      <ng-container *ngFor="let genome of referenceGenomeOptions | comboBoxFilter:genomeCombobox.searchText:'':'options':'text' as result">
        <!-- Group Label (Custom/Standard) -->
        <combobox-option [isHeader]="true" [label]="genome.groupName"></combobox-option>
        <!-- Genome options -->
        <combobox-option *ngFor="let options of genome.options"
                        [value]="options.value"
                        [label]="options.text">
        </combobox-option>
      </ng-container>
    </combobox>
    <app-description-text [description]="genomeControl.value ? genomeDescription : ''" [parseAsMarkdown]="showHyperlinkInDescription"></app-description-text>
  </ng-container>

  <!-- Loading Skeleton Options -->
  <ng-template #loadingOptionsSkeleton>
    <lib-skeleton
      type="text"
      [lines]="1">
    </lib-skeleton>
  </ng-template>
</ng-container>
