import { IApplication } from '../applications/applications';


export interface IUserAgreement {
    Agreement?: IAgreementCompact;
    Status?: string;
    DateSigned?: string;
}


export interface IAgreement extends IAgreementCompact {
    Html?: string;
    Rtf?: string;
}

export interface IAgreementCompact {
    Id?: number;
    Href?: string;
    Title?: string;
    Category?: string;
    ExpiredOn?: string;
    Application?: IApplication;
}

export enum AgreementCategory {
    USER = 'USER',
    DEVELOPER = 'DEVELOPER',
    APP_EULA = 'APP_EULA',
    STORE_TERMS = "STORE_TERMS",
    USER_PRIVACY = "USER_PRIVACY",
    PUBLISHING = "PUBLISHING",
    APP_PRIVACY = "APP_PRIVACY"
    // add more as necessary.
}

export enum AgreementStatus {
    PENDING = 'pending',
    SIGNED = 'signed'
    // add more as necessary.
}
