<label *ngIf="headerLabel" [class]="type + '__header-label'">
    {{ headerLabel }}<span *ngIf="required" class="required">*</span>
</label>
<fieldset class="checkbox-group-options">
    <div *ngFor="let val of _options; let  i = index" class="checkbox-group-options_check">
        <lib-radio-checkbox (change)="onCheckboxChange(i,$event)" [checked]="valueSelected(val)" [disabled]="disabled"
            [required]="required" [type]="type" [name]='name'>
            {{val[labelProperty]}}
        </lib-radio-checkbox>
        <label *ngIf="tagProperty && val[tagProperty]" class="checkbox-group-options_tag">
            {{val[tagProperty]}}
        </label>
    </div>
    <span *ngIf="required && control.dirty && !isValueSelected" [class]="type + '__validation-text'">{{ errorMessage }}</span>
</fieldset>