import {Injectable} from '@angular/core';
import * as gss from '@stratus/gss-ng-sdk';
import {Observable, ReplaySubject} from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import {GetIndexInfoRequest} from '@stratus/gss-ng-sdk/api/models/get-index-info-request';
import {SequencingRun} from '@stratus/gss-ng-sdk/api/models/sequencing-run';
import {
  ReferenceFileCompactReferenceFileSortFieldsPagedItems
} from '@stratus/gss-ng-sdk/api/models/reference-file-compact-reference-file-sort-fields-paged-items';
import {catchAndThrowGSSApiError, genericRetryWhen} from '@app/core/rxjsutils/rxjs-utilities';
import {cloneDeep, isEmpty} from 'lodash';
import {
  AnalysisConfigurationTemplateCompactAnalysisConfigurationTemplateSortFieldsPagedItems
} from '@stratus/gss-ng-sdk/api/models/analysis-configuration-template-compact-analysis-configuration-template-sort-fields-paged-items';

interface GssApiRequest {
  methodName: string;
  params?: any;
}

class GssApiRequestWithKey implements GssApiRequest {
  constructor(methodName, params = null) {
    this.methodName = methodName;
    this.params = params;
  }
  methodName: string;
  params?: any;
  flattenParams() {
    const sortedParams = Object.keys(this.params).sort().reduce(
      (obj, key) => {
        obj[key] = this.params[key];
        return obj;
      },
      {}
    );
    return JSON.stringify(sortedParams);
  }
  get key() {
    return `${this.methodName}/${this.params? this.flattenParams() : ''}`;
  }
}

/**
 * All calls to GSS API should be done through this class.
 * This class will:
 * - Act as anti-corruption layer, i.e. maintain consistency even when GSS API is not consistent
 * - Enhance GSS API responses, by wrapping the data into classes (see run-planning\ext folder)
 * - Transform, as required, GSS data model into UI data model, i.e. for sample-data table with multi-lane selection
 */
@Injectable({
  providedIn: 'root'
})
export class GssApiCacheService {
  /**
   * Max number of items to retrieve from GSS API for list operations
   */
  public static readonly MAX_ITEMS_COUNT = 1000;

  private withCache(obj) {
    let cache: Map<string, ReplaySubject<any>> = new Map();
    return new Proxy(obj, {
      get(target, prop, receiver) {
        const value = target[prop];
        if (value instanceof Function) {
          return function() {
            const args = arguments[0];
            const forceRefresh = arguments[1];
            const request = new GssApiRequestWithKey(prop, args);
            const cacheKey = request.key;
            if (forceRefresh || !cache.has(cacheKey)) {
              const defaultValue = new ReplaySubject(1);
              cache.set(cacheKey, defaultValue);
              value.apply(this === receiver ? target : this, arguments).subscribe(
                res => {
                  cache.get(cacheKey).next(res);
                },
                err => {
                  cache.get(cacheKey).error(err);
                });
            }
            return cache.get(cacheKey).pipe(
              map(x => cloneDeep(x))
            );
          };
        } else if(prop === 'cache') {
          return cache;
        }
        return value;
      }
    })
  }

  private analysisDefinitionServiceWithCache;
  private analysisVersionDefinitionsServiceWithCache;
  private indexAdapterKitsServiceWithCache;
  private instrumentTypesServiceWithCache;
  private libraryPrepKitsServiceWithCache;
  private genomesServiceWithCache;
  private referenceFilesServiceWithCache;
  private plannedRunsServiceWithCache;
  private analysisConfigurationTemplatesServiceWithCache;


  constructor(
    private analysisDefinitionsService: gss.AnalysisDefinitionsService,
    private analysisVersionDefinitionsService: gss.AnalysisVersionDefinitionsService,
    private indexAdapterKitsService: gss.IndexAdapterKitsService,
    private plannedRunsService: gss.PlannedRunsService,
    private libraryPrepKitsService: gss.LibraryPrepKitsService,
    private genomesService: gss.GenomesService,
    private sequencingRunsService: gss.SequencingRunsService,
    private instrumentTypesService: gss.InstrumentTypesService,
    private referenceFilesService: gss.ReferenceFilesService,
    private samplesheetService: gss.SampleSheetsService,
    private analysisConfigurationTemplatesService: gss.AnalysisConfigurationTemplatesService  ) {
    this.resetCacheService();
  }

  public resetCacheService() {
    this.analysisDefinitionServiceWithCache = this.withCache(this.analysisDefinitionsService);
    this.analysisVersionDefinitionsServiceWithCache = this.withCache(this.analysisVersionDefinitionsService);
    this.indexAdapterKitsServiceWithCache = this.withCache(this.indexAdapterKitsService);
    this.instrumentTypesServiceWithCache = this.withCache(this.instrumentTypesService);
    this.libraryPrepKitsServiceWithCache = this.withCache(this.libraryPrepKitsService);
    this.genomesServiceWithCache = this.withCache(this.genomesService);
    this.referenceFilesServiceWithCache = this.withCache(this.referenceFilesService);
    this.plannedRunsServiceWithCache = this.withCache(this.plannedRunsService);
    this.analysisConfigurationTemplatesServiceWithCache = this.withCache(this.analysisConfigurationTemplatesService);
  }

  public hasCache() {
    return !isEmpty(this.analysisDefinitionServiceWithCache.cache) ||
      !isEmpty(this.analysisVersionDefinitionsServiceWithCache.cache) ||
      !isEmpty(this.indexAdapterKitsServiceWithCache.cache) ||
      !isEmpty(this.instrumentTypesServiceWithCache.cache) ||
      !isEmpty(this.libraryPrepKitsServiceWithCache.cache) ||
      !isEmpty(this.genomesServiceWithCache.cache) ||
      !isEmpty(this.referenceFilesServiceWithCache.cache);
  }

  public listAnalysisDefinitions(params: any, forceRefresh = false): Observable<gss.AnalysisDefinitionCompactAnalysisDefinitionSortFieldsPagedItems> {
    return this.analysisDefinitionServiceWithCache.listAnalysisDefinitions(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public getAnalysisVersionDefinition(params: gss.AnalysisVersionDefinitionsService.GetAnalysisVersionDefinitionParams, forceRefresh = false)
    : Observable<any> {
    return this.analysisVersionDefinitionsServiceWithCache.getAnalysisVersionDefinition(params, forceRefresh)
      .pipe(
        catchAndThrowGSSApiError
      );
  }

  public renderAnalysisVersionDefinitionByIdOrUrn(params: any): Observable<gss.RenderAnalysisVersionDefinitionResponse> {
    return this.analysisVersionDefinitionsService.renderAnalysisVersionDefinitionByIdOrUrn(params);
  }

  public getIndexAdapterKit(params: gss.IndexAdapterKitsService.GetIndexAdapterKitParams, forceRefresh = false): Observable<any> {
    return this.indexAdapterKitsServiceWithCache.getIndexAdapterKit(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public listIndexAdapterKits(params: gss.IndexAdapterKitsService.ListIndexAdapterKitsParams = null, forceRefresh = false):
    Observable<gss.IndexAdapterKitCompactIndexAdapterKitSortFieldPagedItems> {
    return this.indexAdapterKitsServiceWithCache.listIndexAdapterKits(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public getLibraryPrepKit(params: gss.LibraryPrepKitsService.GetLibraryPrepKitParams, forceRefresh = false)
    : Observable<any> {
    return this.libraryPrepKitsServiceWithCache.getLibraryPrepKit(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public listLibraryPrepKits(params: gss.LibraryPrepKitsService.ListLibraryPrepKitsParams, forceRefresh = false)
    : Observable<gss.LibraryPrepKitCompactLibraryPrepKitSortFieldsPagedItems> {
    return this.libraryPrepKitsServiceWithCache.listLibraryPrepKits(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public listAnalysisConfigurationTemplates(
    params: gss.AnalysisConfigurationTemplatesService.ListAnalysisConfigurationTemplatesParams,
    forceRefresh = false,
  ): Observable<AnalysisConfigurationTemplateCompactAnalysisConfigurationTemplateSortFieldsPagedItems> {
    return this.analysisConfigurationTemplatesServiceWithCache.listAnalysisConfigurationTemplates(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError,
      );
  }

  public listGenomes(params: gss.GenomesService.ListGenomesParams, forceRefresh = false):
    Observable<gss.GenomeCompactGenomeSortFieldsPagedItems> {
    return this.genomesServiceWithCache.listGenomes(params, forceRefresh)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public getSequencingRun(params: gss.SequencingRunsService.GetSequencingRunParams): Observable<SequencingRun> {
    return this.sequencingRunsService.getSequencingRun(params)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public listAnalysisConfigurations(params: gss.SequencingRunsService.ListAnalysisConfigurationsParams): 
    Observable<gss.SequencingRunAnalysisConfigurationSequencingRunAnalysisConfigurationSortFieldsPagedItems> {
    return this.sequencingRunsService.listAnalysisConfigurations(params)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public getSequencingRunContents(params: gss.SequencingRunsService.GetSequencingRunContentsParams): Observable<gss.SequencingRunContentsResponse> {
    return this.sequencingRunsService.getSequencingRunContents(params)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }

  public replacePlannedRun(params: gss.PlannedRunsService.ReplacePlannedRunParams): Observable<gss.SequencingRun> {
    return this.plannedRunsService.replacePlannedRun(params)
    .pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  }

  public createPlannedRun(body?: gss.CreatePlannedRunRequest): Observable<gss.SequencingRun> {
    return this.plannedRunsService.createPlannedRun(body)
    .pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  }


  public getIndexInfo(params: GetIndexInfoRequest) {
    return this.plannedRunsServiceWithCache.getIndexInfo(params).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  }

  public prepareRequeue(params: gss.SequencingRunsService.PrepareRequeueParams): Observable<gss.SequencingRun> {
    return this.sequencingRunsService.prepareRequeue(params).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  }

  /**
   * Starts previously prepared requeue run for analysis.
   */
  public startRequeue(runId: string): Observable<gss.SequencingRun> {
    return this.sequencingRunsService.startRequeue(runId).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  }

  public getInstrumentTypes(forceRefresh = false): Observable<gss.SupportedInstrumentTypes> {
    return this.instrumentTypesServiceWithCache.getInstrumentTypes(null, forceRefresh).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError,
    );
  }

  public listReferenceFiles(params: gss.ReferenceFilesService.ListReferenceFilesParams, forceRefresh = false)
    : Observable<ReferenceFileCompactReferenceFileSortFieldsPagedItems> {
    return this.referenceFilesServiceWithCache.listReferenceFiles(params, forceRefresh).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError,
    );
  }
  

  public getReferenceFile(params: gss.ReferenceFilesService.GetReferenceFileParams, forceRefresh = false): 
    Observable<gss.ReferenceFile>{
    return this.referenceFilesServiceWithCache.getReferenceFile(params, forceRefresh).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError,
    );
  }

  public generateSampleSheet(requestBody: gss.GenerateSampleSheetRequest): Observable<gss.SampleSheet> {
    return this.samplesheetService.generateSampleSheet(requestBody)
      .pipe(
        retryWhen(genericRetryWhen()),
        catchAndThrowGSSApiError
      );
  }
  
  public parseSampleSheet(body?: gss.ParseSampleSheetRequest): Observable<gss.ParseSampleSheetResponse> {
    return this.samplesheetService.parseSampleSheet(body).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  }

  public transformSampleSheet(body?: gss.TransformSampleSheetRequest): Observable<gss.TransformSampleSheetResponse> {
    return this.samplesheetService.transformSampleSheet(body).pipe(
      retryWhen(genericRetryWhen()),
      catchAndThrowGSSApiError
    );
  };
}
