<ngx-smart-modal
    #EditSharedBclSettingsModalComponent
    class="edit-shared-bcl-convert-settings-modal"
    [identifier]="modalType"
    [closable]="true"
    [title]="modalTitle"
    [showCloseButton]="true"
    [showConfirmButton]="true"
    [closeButtonText]="closeButtonText"
    [confirmButtonText]="confirmButtonText">
    <div class="mb-5 rad-typography-font-weight--semiBold">
        {{ configTitle }}
    </div>
    <ng-container *ngIf="formGroup">
        <form [formGroup]="formGroup">
            <app-dynamic-settings-form
                [formFields]="bclConvertSettingsFields"
                [showHyperlinkInDescription]="showHyperLink"
                formControlName="bclConvertSettingsValues">
            </app-dynamic-settings-form>
        </form>
    </ng-container>
</ngx-smart-modal>