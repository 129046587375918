import { IBioSample } from '@app/core/model/bioSamples/bioSample';
import { ILabWorkflow } from '@app/core/model/labWorkflow';
import { IProject } from '@app/core/model/projects/project';
import { ResourceType } from '@app/core/model/resource-type';
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { IPrepTabLibraryPrepKit } from '@app/core/model/settings/prep-tab-library-prep-kits';
import { ITrashResult } from '@app/core/services/toolbar/planned-runs/planned-runs-trash.service';
import { IBiosampleItem } from '@app/shared/modals/biosample-unlock-modal/biosample-unlock-modal.component';
import { V2AppSessionCompact, V2LabRequeueCompact } from '@bssh/ng-sdk';
import { ICollaborator } from '@models/collaborator';
import { IRun } from '@models/runs/run';
import { Invitation } from '@models/v2-api/invitation';
import {AnalysisVersionDefinitionAnalysisFileType, RunCompact, Sample} from '@stratus/gms-v2-ng-sdk';
import { ImportPlannedRunWarning, IndexAdapterKit, LibraryPrepKit } from '@stratus/gss-ng-sdk';
import { IRequeueParams, IV2ManifestImportResponse } from '../../../core/bsshapi/api-wrappers';
import { ISearchDataSourceOptions } from '../../../search/datasource/search.datasource';
import { AlertModalType, IAlertModalText } from '../alert-modal/alert-modal-texts';
import { ConfirmationModalType } from '../confirmation-modal/confirmation-modal-texts';
import { GetLinkMode } from '../get-link-modal/get-link-modal.component';
import { RequeueType } from '../requeue-analysis-modal/requeue-analysis-modal.component';
import { IItemToTrash } from '../trash-modal/trash-modal.component';
import { IArchiveOrRestoreResource } from './archive-or-restore-resource';
import { ResourceFieldToValueMap } from './edit-info-editable-field';
import { IApiFile } from './file-preview.interface';
import { IRequeueLibrary } from './requeue-library.interface';
import { CreateAnalysisConfigurationRequest } from '@stratus/gms-v2-ng-sdk';
import { AgreementCategory, AgreementStatus } from '@app/core/model/user-agreements/user-agreements';
import {
  AnalysisConfigurationTemplateCompact
} from '@stratus/gss-ng-sdk/api/models/analysis-configuration-template-compact';
import { IRequeueLibraryPool } from '@app/core/model/pool/requeue-library-pool';
import { IFormField } from '@app/run-planning/model/form-field';
import { FormValueObject } from '@app/run-planning/interface';
import { Observable } from 'rxjs';


export enum ModalStatus {
    OPENED = 'opened',
    CLOSED = 'closed'
}

export interface IModalInput {
    errorMsg?: string;
    alertMsg?: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IModalOutput {
}

export interface IShareModalInput extends IModalInput {
    resourceName: string;
    resourceType: ResourceType;
    collaborators?: ICollaborator[];
}

export interface ITransferModalInput extends IModalInput {
    resourceName: string;
    resourceType: ResourceType;
}

export interface IRequeueAnalysisModalInput extends IModalInput {
    requeueTypes: RequeueType[];
}

export interface IRequeueAnalysisModalOutput extends IModalOutput {
    content?: string;
}

export interface ICancelRequeueModalInput extends IModalInput {
    requeue: V2LabRequeueCompact;
    requeueType: string;
}

export interface IGetLinkModalInput extends IModalInput {
    resourceType: ResourceType;
    title: string;
    shareUrl: string;
    getLinkMode: GetLinkMode;
}

export interface IAlertModalInput extends IModalInput {
    type: AlertModalType;

    // Only used for 'CUSTOM' AlertModalType
    alertModalText?: IAlertModalText;
    showCloseButton?: boolean;
    showConfirmButton?: boolean;
}

export interface IEmptyTrashModalInput extends IModalInput {
    totalSize: number;
}

export interface IDynamicAlertModalInput extends IModalInput {
    title: string;
    content: string | AlertMessages;
    confirmButtonText: string;
    closeButtonText?: string;
    closable?: boolean;
}

/**
 * If string begins with '- ', it will be rendered as an unordered list
 * otherwise, the string will be rendered as a paragraph.
 */
export type AlertMessages = string[];

export interface IConfirmationModalInput extends IModalInput {
    type: ConfirmationModalType;
}

export interface IAppAgreementModalInput extends IModalInput {
    title: string;
    content: string;
    version: string;
    status: AgreementStatus;
    category: AgreementCategory;
    // TODO: Add icon link to style similar to app launch page
}

export interface IMicroarrayCustomConfigModalInput extends IModalInput {
  fileTypes: AnalysisVersionDefinitionAnalysisFileType[];
  definitionName: string;
}

// Should have all properties set except for name and displayName
export interface ICreateConfigForCustomThresholdsModalInput extends IModalInput {
    request: CreateAnalysisConfigurationRequest;
}

export interface IDeleteDataConfirmationModalInput extends IModalInput {
    runs: RunCompact[];
}

export interface ICancelOwnershipTransferModalInput extends IModalInput {
    resourceType: ResourceType;
    invitation: Invitation;
    resourceName: string;
}

export interface IDownloadModalInput extends IModalInput {
    resourceType: ResourceType;
    resourceList: any[];
    downloadUri: string;
}

export interface ITrashModalInput extends IModalInput {
    resourceType: ResourceType;
    infoMessage?: boolean;
    alertMessages?: string[];
    itemToTrash?: IItemToTrash[];
    availableAppSessions?: V2AppSessionCompact[];
}

export interface ITrashModalOutput extends IModalOutput {
    selectedDeleteOption: string;
}

export interface ITrashPlannedRunsModalInput extends IModalInput {
    runsToTrash: IRun[];
}

export interface ITrashPlannedRunsModalOutput extends IModalOutput {
    trashResult: ITrashResult;
}

export interface IShareModalOutput extends IModalOutput {
    collaborators: ICollaborator[];
    includeAssociatedResources: boolean;
}

export interface ITransferModalOutput extends IModalOutput {
    email: string;
    optionalMessage?: string;
}

export interface IFilePreviewModalInput extends IModalInput {
    apiBaseUrl: string;
    requestedFile: IApiFile;
    isPreviewableTextFile?: boolean;
}

// tslint:disable-next-line:no-empty-interface
export interface IFilePreviewModalOutput extends IModalOutput {
    fileName: string; // file to be downloaded
    downloadLink: string; // download link for the file
}

export interface IChangeStatusModalInput<T> extends IModalInput {
    resources: T[];
}
export interface IChangeStatusModalOutput<T> extends IModalOutput {
    newStatus: T;
    comments?: string;
}
export interface ArchiveOrRestoreActionModalInput extends IModalInput {
    resourceType: ResourceType;
    resourceList: IArchiveOrRestoreResource[];
    resourceIdentifierTitle?: string;
    resourceSizeTitle?: string;
    actionMsg?: string;
    totalSizeOfResources?: string;
}

export interface IImportRunErrorsModalInput extends IModalInput {
  title?: string;
  subTitle?: string;
  warnings: ImportPlannedRunWarning[];
  showConfirmButton?: boolean;
  showCloseButton?: boolean;
}

export interface IRequeueRunValidationModalInput extends IModalInput {
  title?: string;
  subTitle?: string;
  warnings: ImportPlannedRunWarning[];
  showSkipValidationCheckBox?: boolean;
  alwaysEnableConfirmButton?: boolean;
}

export interface IRequeueRunValidationModalOutput extends IModalOutput {
    proceed: boolean;
    withoutValidation: boolean;
}


// tslint:disable-next-line:no-empty-interface
export interface ArchiveOrRestoreActionModalOutput extends IModalOutput {
}

export interface EditInfoModalInput extends IModalInput {
    resourceType: ResourceType;
    currentValueMap?: ResourceFieldToValueMap; // map of fields (like name, description) to their current value
    // for the given resource
}

export interface EditInfoModalOutput extends IModalInput {
    newValueMap: ResourceFieldToValueMap; // map of fields (like name, description) to their edited/updated value
}

export interface CreateResourceModalInput extends IModalInput {
    resourceType: ResourceType;
    defaultProject?: IProject;
}

export interface CreateResourceModalOutput extends IModalOutput {
    fieldValueMap: ResourceFieldToValueMap;
    defaultProject?: IProject;
}

export interface ILaneCommentsModalInput extends IModalInput {
    url: string;
    lane: number;
}

export interface IGetLinkModalOutput extends IModalOutput {
    getLinkMode: GetLinkMode;
}

export interface ICreateIndexAdapterKitModalOutput extends IModalOutput {
    indexAdapterKitCreated: IndexAdapterKit;
}

export interface ICancelOwnershipTransferModalOutput extends IModalOutput {
    invitationId: string;
}

export interface ICreateLibraryPrepKitModalOutput extends IModalOutput {
    libraryPrepKitCreated: LibraryPrepKit;
}

export interface IEditIndexAdapterKitModalInput extends IModalInput {
    indexAdapterKit: IndexAdapterKit;
}

export interface INewLibraryRequeueInput extends IModalInput {
    biosample: IBioSample;
    labWorkflows: ILabWorkflow[];
    selectedLabWorkflow?: ILabWorkflow;
}

export interface INewLibraryRequeueOutput extends IModalOutput {
    RequestedAdditionalYield: number;
    PrepRequestId: string;
}

export interface ICreatePrepTabLibraryPrepKitModalOutput extends IModalOutput {
    libraryPrepKitCreated: IPrepTabLibraryPrepKit;
}

export interface IAlertModalOutput extends IModalOutput {
    isProceed: boolean;
}

export interface IAppEulaModalOutput extends IModalOutput {
    isAccepted: boolean;
}

export interface IMicroarrayCustomConfigModalOutput extends IModalOutput {
    isAdded: boolean;
}

export interface ICreateConfigForCustomThresholdsModalOutput extends IModalOutput {
    isCreated: boolean;
}

export interface IImportIdatsModalOutput extends IModalOutput {
  importCompleted: boolean;
  successfullyImportedSamples: Sample[];
  unsuccessfullyImportedSamples: Sample[];
  samplesReadyForAnalysis: Sample[];
  samplesNotReadyForAnalysis: Sample[];
}

export interface IDeleteDataConfirmationModalOutput extends IModalOutput {
  areDeleted: boolean;
}

export interface IBiosampleUploadModalOutput extends IModalOutput {
    preview: boolean; // whether this is preview submission
    biosampleCsvFile: File; // file to be uploaded
}

export interface IBiosampleUploadModalInput extends IModalInput {
    manifestUploadResponse: IV2ManifestImportResponse; // upload response (validation) received from backend
}

export interface ILibraryRequeueModalInput extends IModalInput {
    associatedParentResourceType: ResourceType;
    library: IRequeueLibrary;
}

export interface ILibraryRequeueModalOutput extends IModalOutput {
    requeueParams: IRequeueParams;
}
export interface ILibraryPoolRequeueModalInput extends IModalInput {
    associatedParentResourceType: ResourceType;
    requeueLibraryPool: IRequeueLibraryPool;
}
export interface ILibraryPoolRequeueModalOutput extends IModalOutput {
    requeueParams: IRequeueParams;
}
export interface IChooserModalInput extends IModalInput {
    title: string;
    hasMatcher: boolean;
    rowSelection: 'single' | 'multiple';
    resourceType: SearchResourceType;
    isResourceCreatable?: boolean;
    searchDataSourceOptions?: ISearchDataSourceOptions;
    debounceTimeForRawTextQuery?: number;
    titleTooltip?: string;
    searchPlaceHolderText?: string;
}

export interface IChooserModalOutput<T> extends IModalOutput {
    selectedItems: T[];
}
export interface IDeleteCustomKitModalInput extends IModalInput {
    modalText: {
        MODAL_TITLE: string;
        CONFIRM_BUTTON_TEXT: string;
        CLOSE_BUTTON_TEXT: string;
        ALERT_MESSAGE: string;
        TOAST_ERROR: string;
    };
}

export interface ICommentsModalInput extends IModalInput {
    resourceType: ResourceType;
    id: string;
}
export interface ICancelAnalysisModalInput extends IModalInput {
    Id: string;
    resourceName: string;
}

export interface IFileUploadProgressModalOutput extends IModalOutput {
    isCancel: boolean;
}

/*
IAppLauncherModalInput example:
{
    Id: <projectId>
    resourceType: ResourceType.PROJECTS
}
*/
export interface IAppLauncherModalInput extends IModalInput {
    projectId?: string;
    sampleId?: string;
    resourceType: ResourceType;
}

export interface IDeleteConfigModalInput extends IModalInput {
    configTitle: string;
}

export interface IEditSharedBclSettingsModalInput extends IModalInput {
    configTitle: string;
    bclConvertSettingsFields: IFormField[];
    bclConvertSettingsValues: FormValueObject;
    showHyperLink: boolean;
    saveFunction: (...args: any[]) => Observable<any>;
}

export interface IUnlockBiosamplesModalInput extends IModalInput {
    biosampleItems?: IBiosampleItem[];
    unlockBiosamplesProgress?: number;
}

export interface IMergeSamplesModalInput extends IModalInput {
    alertMessages?: string[];
    errorMessages?: string[];
}

export interface IMergeSamplesModalOutput extends IModalOutput {
    newSampleName: string;
}

export interface IReferenceFileModalInput extends IModalInput {
    fileType: string;
    displayName: string;
    dataLocationUri: string;
    fileName: string;
    referenceFileId: string;
    genomeHashTableVersion?: string;
    supportedGenomeIds?: string[];
    analysisVersionDefinitionId?: string;
}

export interface IFileUploadModalInput extends IModalInput {
    fileName: string;
    totalSizeInBytes: number;
    uploadedSizeInBytes: number;
}

export interface IImportFromActModalInput extends IModalInput {
  selectedAvdId?: string;
  selectedAvdDisplayname?: string;
  compatibleAvdIds: string[];
}

export interface IImportFromActModalOutput extends IModalOutput {
  analysisConfigurationTemplateDetails?: AnalysisConfigurationTemplateCompact;
}

export interface IAnalysisStepLogsInput extends IModalInput {
  appSessionId: string;
}
