import * as gss from '@stratus/gss-ng-sdk';
import {ConfigSetupState} from '@app/run-planning/config-setup/config-setup.state';
import {cloneDeep, get, isEmpty, keyBy, pick, uniq} from 'lodash';
import {getConfigTitle, IMPLICIT_BCL_CONVERT_CONFIG_NAME} from '@app/run-planning/constants';
import {RunSettingsState} from '@app/run-planning/run-settings/run-settings.state';
import {UNSPECIFIED_INDEX_ADAPTER_KIT, UNSPECIFIED_LIB_PREP_KIT} from '@app/cloud-run-prep/constants';
import {ColumnName} from '@app/run-planning/services/sample-table/sample-table-service';
import { RunPlanningState } from '../run-planning.state';

export class CreateSequencingRunAnalysisConfigurationRequest implements gss.CreateSequencingRunAnalysisConfigurationRequest {
  analysisVersionDefinitionId: string;
  description?: string;
  indexAdapterKitId?: string;
  libraryPrepKitId?: string;
  name?: string;
  sampleSettings?: Array<gss.SampleSettingEntry>;
  settings?: any;

  /**
   * Transform a single config to a single GSS AnalysisConfig request (1:1). Assigns the physical & logical level
   * config values across the sampleSettings.sampleIds within the config
   */
  public static fromConfigSetup(state: ConfigSetupState): gss.CreateSequencingRunAnalysisConfigurationRequest {
    let sampleSettings: gss.SampleSettingEntry[] = [];

    if (state.rawAnalysisVersionDefinition.hasSubSamples) {
      const subSampleColumnNameRegex = /([^_]+)_(\w+)/;

      const assocFieldId = state.rawAnalysisVersionDefinition.settings.subSamplesConfiguration.associationFieldId;
      const sampleDataMap = keyBy(state.sampleData, assocFieldId);
      const subSampleDefMap = keyBy(state.rawAnalysisVersionDefinition.settings.subSamplesConfiguration.subSampleDefinitions, 'id');
      const sampleSettingsFieldMap = keyBy(state.rawAnalysisVersionDefinition.analysisSampleSettings.fields.filter(field => !field.hidden), 'id');

      for (const pairId of Object.keys(sampleDataMap)) {
        const row = sampleDataMap[pairId];
        const perSampleSettings = { };

        // Initialize perSampleSettings with subSamples.settings
        for (const subSampleDefId1 of Object.keys(subSampleDefMap)) {
          const subSampleDef = subSampleDefMap[subSampleDefId1];
          const indexIdFieldName = `${subSampleDefId1}_${ColumnName.INDEX_CONTAINER_POSITION}`;
          const rowHasEmptyIndexId = isEmpty(row[indexIdFieldName]);

          if (!rowHasEmptyIndexId) {
            perSampleSettings[subSampleDefId1] = cloneDeep(subSampleDef.settings);
          }
        }

        // For each column in the (sample data) row, copy the value to sample-settings
        // Note that the column may apply to all or some sub-samples
        for (const columnName of Object.keys(row)) {
          const columnIsCommon = columnName in sampleSettingsFieldMap;
          if (columnIsCommon) {
            // Copy the same value to all sub-samples settings
            for (const subSampleDefId2 of Object.keys(perSampleSettings)) {
              perSampleSettings[subSampleDefId2][columnName] = row[columnName];
            }
            continue;
          }

          const arr = subSampleColumnNameRegex.exec(columnName);

          if (isEmpty(arr)) {
            // This case is handled above, i.e. columnName does not contain underscore
            continue;
          }

          const subSampleDefId = arr[1];
          const actualFieldName = arr[2];
          const supportedSubSampleIds = get(sampleSettingsFieldMap[actualFieldName], 'settings.supportedSubSampleIds', []);
          if (supportedSubSampleIds.includes(subSampleDefId) && (subSampleDefId in perSampleSettings)) {
            perSampleSettings[subSampleDefId][actualFieldName] = row[columnName];
          }
        }

        for (const subSampleDefId of Object.keys(perSampleSettings)) {
          const sampleId = `${row[assocFieldId]}_${subSampleDefId}`;
          const settings = {
            ...state.physicalConfigValues,
            ...state.logicalConfigValues,
            ...perSampleSettings[subSampleDefId]
          };
          sampleSettings.push({sampleId, settings});
        }
      }
    } else {
      // collect unique sampleName as sampleId from table (duplicate sampleId not allowed within a single analysisConfig.sampleSettings)
      const uniqueSampleIds: string[] = state.sampleData ? uniq(state.sampleData.map(x => x.sampleName).filter(x => !isEmpty(x))) : [];
      sampleSettings = uniqueSampleIds.map(sampleId => {
        // include perSampleSettingsValues matching the sampleId only (only one sampleSettingValues allowed for one sampleId in config)
        const matchingPerSampleSettingsData = state.perSampleSettingsData
          ? state.perSampleSettingsData.find(x => x.sampleId === sampleId)
          : null;

        // physicalConfig & logicalConfig values are duplicated across all sampleIds within the same config
        const settings = {
          ...state.physicalConfigValues,
          ...state.logicalConfigValues,
          ...(matchingPerSampleSettingsData && matchingPerSampleSettingsData.perSampleSettings)
        };
        return {sampleId, settings};
      });
    }

    return {
      name: getConfigTitle(state),
      analysisVersionDefinitionId: state.analysisVersionDefinitionId,
      description: state.analysisDescription,
      settings: state.analysisSettingsFormValues,
      sampleSettings,
      libraryPrepKitId: state.libraryPrepKitId === UNSPECIFIED_LIB_PREP_KIT.id ? null : state.libraryPrepKitId,
      indexAdapterKitId: state.indexAdapterKitId === UNSPECIFIED_INDEX_ADAPTER_KIT.id ? null : state.indexAdapterKitId,
    };
  }

  /**
   * Generates the request model for implicit BclConvert analysis config, based on the run planning state
   * with selected implicit BclConvert AVD and values. The sampleSettings of the config is always an empty
   * array given that the config is a global one that applies to all samples.
   * @param state state containing the implicit bclConvert AVD and form values
   */
  public static fromRunPlanning(state: RunPlanningState): gss.CreateSequencingRunAnalysisConfigurationRequest {
    const implicitBclSettingFields = get(state, 'implicitBclConvertAvd.analysisSettings.fields', []).map(x => x.id);
    const bclConvertFormValues = pick(state.implicitBclConvertFormValues, implicitBclSettingFields);
    return {
      name: IMPLICIT_BCL_CONVERT_CONFIG_NAME,
      analysisVersionDefinitionId: state.implicitBclConvertAvd.id,
      settings: bclConvertFormValues,
      sampleSettings: []
    };
  }
}
