import { DeleteOption } from '../model/delete-options';

export class ToastrMessages {

    static readonly TRANSFER_INVITATION_SENT = '<p><a href=\"/settings/transfer-history\">Transfer invitation sent.<br/>' +
        'View pending invitations &raquo;</a></p>';

    static readonly CANCEL_OWNERSHIP_SUCCESS_MESSAGE = 'Transfer Invitation Cancelled';
    static readonly CANCEL_REQUEUE_SUCCESS_MESSAGE = 'Lab requeue canceled';

    static readonly LAB_REQUEUE_REQUESTED = 'Lab requeue requested.';
    static EDIT_DEFAULT_PROJECT_SUCCESS(bioSampleCount: number, newProjectName: string) {
        const msg = `The default project for ${bioSampleCount} biosample(s) has been changed to <b>${newProjectName}</b>`;
        return msg;
    }

    static EDIT_DEFAULT_PROJECT_ERROR = (bioSampleName: string, detailedError: string, errorCode: string) => {
        var msg = bioSampleName
            ? `The default project for <b>${bioSampleName}</b> was not changed. <b>${detailedError}</b>`
            : `The default project was not changed. <b>${detailedError}</b>`;

        if ((errorCode).indexOf('BASESPACE.COMMON.INSUFFICIENT_RESOURCE_PERMISSIONS') > -1) {
            msg = bioSampleName
                ? `The default project for <b>${bioSampleName}</b> was not changed because you do not have write access to the specified project.`
                : `The default project was not changed because you do not have write access to the specified project.`;
        }

        return msg;
    }

    static COPY_FASTQ_DATASETS_ERROR(datasetName: string, errorCode: string, errorMessage: string) {
        // Override the error message returned by the API in the case where the dataset's upload status is not complete.
        if (errorCode === 'BASESPACE.DATASETS.CANNOT_COPY_NOTCOMPLETED_DATASET') {
            const msg = `The dataset <strong>` + datasetName +
                `</strong> was not copied because it does not have an upload status of Complete.`
            return msg;
        }
        return 'The dataset <strong>' + datasetName + '</strong> was not copied because \"' + errorMessage + '\"';
    }

    static readonly ANALYSES_QC_STATUS_CHANGED = 'Analyses QC status changed';

    static readonly ANALYSES_DELIVERY_STATUS_CHANGED = 'Analysis delivery status changed';

    static readonly ANALYSES_CANCELED = 'Analysis was canceled';

    static readonly TRASH_COMPLETE_MESSAGE = (deleteOption: DeleteOption, isOwnerOfRuns: boolean, multipleRunsDeleted: boolean) => {
        const resourceName = multipleRunsDeleted ? 'runs' : 'run';
        let trashCompleteMessage = null;

        if (deleteOption === DeleteOption.ASSOC_APPSESSIONS) {
            trashCompleteMessage = 'Run files and associated analyses were moved to trash.';
        } else {
            trashCompleteMessage = 'The '
                + (deleteOption === DeleteOption.PRESERVE_METADATA ? `files associated with the ${resourceName} were` :
                    `${resourceName}` + (multipleRunsDeleted ? ' were' : ' was'))
                + (isOwnerOfRuns ? ' moved to the trash.' : ' removed from your account.');
        }
        return trashCompleteMessage;
    }

    static readonly TRASH_COMPLETE_PROJECT_MESSAGE = (isOwner) => {
        return 'The project was' + (isOwner ? ' moved to the trash.' : ' removed from your account.');
    }

    static readonly TRASH_DATASET_COMPLETE_MESSAGE = (deleteOption: DeleteOption, isOwnerOfDatasets: boolean, multipleDatasetsDeleted: boolean) => {
        let trashCompleteMessage = null;
        const resourceName = multipleDatasetsDeleted ? 'datasets' : 'dataset';

        trashCompleteMessage = 'The '
            + (deleteOption === DeleteOption.PRESERVE_METADATA ? `files associated with the ${resourceName} were` :
                `${resourceName}` + (multipleDatasetsDeleted ? ' were' : ' was'))
            + (isOwnerOfDatasets ? ' moved to the trash.' : ' removed from your account.');

        return trashCompleteMessage;
    }

    static readonly TRASH_ANALYSIS_MESSAGE = (totalSuccessCount: number) => {
        let trashCompleteMessage = null;
        if (totalSuccessCount === 1) {
            trashCompleteMessage = 'The Analysis was moved to trash';
        } else {
            trashCompleteMessage = totalSuccessCount + ' analyses were moved to trash';
        }
        return trashCompleteMessage;
    }

    static readonly TRASH_SAMPLES_MESSAGE = (totalSuccessCount: number) => {
        let trashCompleteMessage = null;
        if (totalSuccessCount === 1) {
            trashCompleteMessage = 'The Sample was moved to trash';
        } else {
            trashCompleteMessage = totalSuccessCount + ' samples were moved to trash';
        }
        return trashCompleteMessage;
    }

    static readonly BIOSAMPLE_UNLOCK_MESSAGE = (biosampleName: string) => {
        return 'The biosample ' + biosampleName + ' has been unlocked';
    }

    static readonly COPY_SAMPLES_MESSAGE = (projectName: string, totalSuccessCount: number) => {
        let copyCompleteMessage = null;
        if (totalSuccessCount === 1) {
            copyCompleteMessage = `The sample was successfully copied to the project ${projectName}`;
        } else {
            copyCompleteMessage = `${totalSuccessCount} samples were copied to project: ${projectName}`;
        }
        return copyCompleteMessage;
    }

    static readonly QC_STATUS_CHANGE_MESSAGE = (successCount: number, entityName: string) => {
        return (successCount > 1) ?
            (`${successCount} ${entityName} QC statuses changed`) :
            (`${entityName} QC status changed`);
    };

    static readonly BIOSAMPLES_UNLOCK = {
        SUCCESS: (biosamplesCount: number) => `${biosamplesCount} biosamples have been unlocked`,
    }

  static readonly MICROARRAY = {
    SUCCESSFUL_ANALYSIS_LAUNCH: (analysisName: string) => `"${analysisName}" was successfully launched`,
    UNEXPECTED_ERROR: (msg: string) => `An unexpected error happened when setting up the analysis: ${msg}`,
    MICRO_ARRAY_NO_PROJECT_SELECTED: () => 'User must have selected a project before importing a sampleSheet',
    MICRO_ARRAY_NEW_PROJECT_SELECTED: (projectName: string) => `Your "${projectName}" project has been successfully created.`,
    IMPORT_IDATS_SUCCESS: (numSamples: number) =>
      `${numSamples} samples have been selected from the idat files.` +
      ' To choose these samples again, select “Beadchips” as the sample source and locate the imported samples in the beadchips table.',
    IMPORT_IDATS_SUCCESS_TITLE: 'IDAT files import complete',
    IMPORT_IDATS_FAILED:
      'File import failed due to an unknown error. Please try again later or contact Illumina Support if the issue persists.',
    IMPORT_IDATS_FAILED_TITLE: 'IDAT files import failure',
    SAMPLE_READY_FOR_ANALYSIS_FAILED:
      'Sample ready for analysis check failed due to an unknown error. Please try again later or contact Illumina Support if the issue persists.',
    SAMPLE_READY_FOR_ANALYSIS_FAILED_TITLE: 'Sample ready for analysis failure',
    DATA_DELETION_FAILED: 'Unable to delete run data. Please try again later or contact Illumina Support if the issue persists.'
  };

    static readonly TRASH_ITEM_RESTORE = {
        SUCCESS: (itemName: string, resourceName: string) => `The ${itemName} "${resourceName}" has been restored.`

    }

    static readonly MERGE_SAMPLES_SUCCESS = (sampleNames: string[]) => {
        return ToastrMessages.splitText(`Successfully combined Samples ${sampleNames.join(' and ')}`, 200);
    }

    static splitText(fullText: string, shortTextLengthLimit: number): string {
        return fullText.length <= shortTextLengthLimit
        ? fullText
        : fullText.substring(0, shortTextLengthLimit) + '...';
    }
}
