import FileSaver from 'file-saver';
import JSZip from "jszip";
import { Observable, forkJoin, of, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import _ from 'lodash';

// interface
export interface IDownloadUtils {
    triggerZipSaveFile(text: string, fileName: string, presignedUrls: string[], presignedUrlFileNames: string[],
        zipFileName: string): Observable<null>
    triggerSaveFile(text: string, filename: string, localFiles: File[], referenceFiles: Promise<[string, File]>[], zipFileName: string): void

}

@Injectable({
    providedIn: 'root'
})
export class DownloadUtils implements IDownloadUtils {
    constructor(private http: HttpClient) {
    }

    /**
     * Method to download the zip file (sample sheet + gds file downloaded)
     * @param {string} text csv string content
     * @param {string} filename csv file name
     * @param {string[]} presignedUrls gds file content url string array
     * @param {string[]} presignedUrlFileNames gds file names string array
     * @param {string=} zipFileName zip file name
     * @param {File[]} referenceFiles files to be zipped
     * */
    triggerZipSaveFile(text: string, fileName: string, presignedUrls: string[], presignedUrlFileNames: string[],
        zipFileName: string = "archive.zip", referenceFiles: File[] = []): Observable<any> {

        const downloadFileTasks = _.map(presignedUrls, (url) => {
            return this.http.get(url, { responseType: 'arraybuffer' });
        });
        return forkJoin(downloadFileTasks).pipe(
            switchMap(responses => {
                // Wait all files download to finish
                let zip = new JSZip();
                // Add the sample sheet to zip file
                zip.file(fileName, text);
                // Add the gds files to zip file, one by one
                for (let i: number = 0; i < presignedUrls.length; i++) {
                    zip.file(presignedUrlFileNames[i],
                        responses[i], { binary: true });
                }
                referenceFiles.forEach( file => zip.file(file.name, file));

                // Generate the zip file asynchronously
                return from(zip.generateAsync({ type: "blob" }));
            }),
            switchMap(content => {
                return of(FileSaver.saveAs(content, zipFileName + ".zip"));
            })
        );
    }


    /**
     * Method to download the csv file (sample sheet) or zip file (sample sheet + local file selected)
     * @param {string} text csv string content
     * @param {string} filename csv file name
     * @param {File=} localFiles local file
     * @param {File=} referenceFiles reference files
     * @param {string=} zipFileName zip file name
     */
    async triggerSaveFile(text: string, filename: string, localFiles: File[] = [],
        referenceFiles: Promise<[string, File]>[] = [], zipFileName: string = "archive.zip") {
        if (_.isEmpty(localFiles) && _.isEmpty(referenceFiles)) {
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(new Blob([text], { type: 'text/csv;charset=utf-8' }), filename);
            } else {
                if (!text.match(/^data:text\/csv/i)) {
                    text = 'data:text/csv;charset=utf-8,' + text;
                }
                const data: string = encodeURI(text).replace(/\//g, "%2F").replace(/\#/g, "%23");
                let link: HTMLAnchorElement = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            // Download a zip file with sample sheet and reference file
            let zip: JSZip = new JSZip();
            // Add the sample sheet to zip file
            zip.file(filename, text);

            localFiles.forEach(file => {
                zip.file(file.name, file);
            });
        
            const files = await Promise.all(referenceFiles);
            files.forEach(([folderName, file]) => {
                zip.folder(folderName).file(file.name, file);
            });
            if (!zipFileName.endsWith('.zip')) {
                zipFileName += '.zip';
            }
            zip.generateAsync({ type: "blob" })
                .then((content) => {
                    // Force down of the Zip file
                    FileSaver.saveAs(content, zipFileName);
                }
            );
        }
    }
}
