<form class="mb-5" [formGroup]="myFormGroup" spellcheck="false" novalidate>
  <ng-container *ngFor="let field of formFields">

    <div *ngIf="field.type === 'section' && !field.hidden" class="form-group">
      <h2>{{ field.label }}</h2>
      <!-- Tooltip -->
      <lib-popover
        style="vertical-align:'text-bottom'"
        *ngIf="field.helpText"
        popoverType="info"
        popoverSize="medium"
        popoverPlacement="right">
        <app-description-text
          [description]="field.helpText"
          [parseAsMarkdown]="showHyperlinkInDescription"
          [checkFromIllumina]="true"
          [lengthLimit]="200"
          [avoidPopover]="true">
        </app-description-text>
      </lib-popover>
      <hr>
    </div>

    <div *ngIf="field.type === 'text' && !field.hidden" class="form-group row">
      <div class="col"><h4 [class.mandatory-field]="field.required">{{field.label}}</h4>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover></div>
    </div>

    <!-- Textbox -->
    <div *ngIf="field.type === 'textbox' && !field.hidden && (!hideOverrideCyclesField || field.id !== 'OverrideCycles')" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <lib-form-field [label]=""
                        [attr.for]="field.id"
                        [hint]=""
                        [isValidateOnTouch]="true"
                        [errorMessage]="getErrorMessage(field)">
          <input lib-input
                 [id]="field.id"
                 [formControlName]="field.id"
                 type="text"
                 data-cy="dynamic-textbox">
        </lib-form-field>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

    <!-- Number/Integer -->
    <div *ngIf="((field.type === 'integer' || field.type === 'number') && !field.hidden)" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <lib-form-field [label]=""
                        [attr.for]="field.id"
                        [hint]=""
                        [isValidateOnTouch]="true"
                        [errorMessage]="getErrorMessage(field)">
          <input lib-input
                 [id]="field.id"
                 [formControlName]="field.id"
                 type="number"
                 data-cy="dynamic-number-textbox">
        </lib-form-field>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

    <!-- Radio -->
    <div *ngIf="(field.type === 'radio' && !field.hidden)" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <ng-container>
          <lib-radio-checkbox-group type="radio"
                                   [required]="field.required"
                                   [name]="field.id"
                                   [formControlName]="field.id"
                                   [options]="field.choices"
                                   valueProperty="value"
                                   labelProperty="text"
                                   data-cy="dynamic-radio">
          </lib-radio-checkbox-group>
        </ng-container>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

    <!-- Checkbox -->
    <div *ngIf="(field.type === 'checkbox' && !field.hidden)" class="form-group row align-items-center">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label  [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6 pb-3">
        <!-- For checkbox, only one option will be given, and boolean value should be taken -->
        <lib-radio-checkbox
          [disabled]="myFormGroup.controls[field.id].disabled"
          [checked]="myFormGroup.controls[field.id].value"
          (change)="onCheckBoxChange($event, field.id)"
          required="true"
          data-cy="dynamic-checkbox">
        </lib-radio-checkbox>
        <!-- Validation error -->
        <span class="error-message">{{getErrorMessage(field)}}</span>
      </div>
    </div>

    <!-- Select -->
    <div *ngIf="field.type === 'select' && !field.hidden" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <combobox placeholder="Select" [formControlName]="field.id" [label]="field.label" [enableDeleteBtn]="true"
                  data-cy="dynamic-select" #selectComboBox>
          <combobox-option *ngFor="let options of filterChoices(field.choices)" [value]="options.value"
                           [label]="options.text"></combobox-option>
        </combobox>
        <!-- Validation error -->
        <span *ngIf="myFormGroup.controls[field.id].touched && !myFormGroup.controls[field.id].valid && !myFormGroup.controls[field.id].errors.required" class="error-message">
          {{getErrorMessage(field)}}
        </span>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

    <!-- File Select (Reference File) -->
    <div *ngIf="field.type === 'fileSelect' && !field.hidden" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <app-file-select-dropdown
          [parentFormGroup]="myFormGroup"
          [formField]="field"
          [supportLocalUpload]="supportLocalUpload"
          [supportUnselect]="!field.required"
          [analysisVersionDefinitionId]="analysisVersionDefinitionId"
          data-cy="dynamic-app-file-select-dropdown">
        </app-file-select-dropdown>
        <!-- Validation error -->
        <span *ngIf="myFormGroup.controls[field.id].touched && !myFormGroup.controls[field.id].valid && !myFormGroup.controls[field.id].errors.required" class="error-message">
          {{getErrorMessage(field)}}
        </span>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

    <!-- Genome (Reference Genome) -->
    <div *ngIf="field.type === 'genome' && !field.hidden" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <app-genome-dropdown
          [genomeFormControlName]="field.id"
          [fieldLabel]="field.label"
          [showHyperlinkInDescription]="showHyperlinkInDescription"
          [analysisVersionDefinitionId]="analysisVersionDefinitionId"
          [filterBySampleSheetFormat]="filterBySampleSheetFormat"
          [settings]="field.settings"
          data-cy="dynamic-app-genome-dropdown">
        </app-genome-dropdown>
        <!-- Validation error -->
        <span *ngIf="myFormGroup.controls[field.id].touched && !myFormGroup.controls[field.id].valid && !myFormGroup.controls[field.id].errors.required" class="error-message">
          {{getErrorMessage(field)}}
        </span>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

    <!-- Reference File -->
    <div *ngIf="field.type === 'referenceFile' && !field.hidden" class="form-group row">
      <div class="col-3 col-lg-2 col-md-4 col-sm-8 word-break">
        <label [class.mandatory-field]="field.required">{{field.label}}</label>
        <!-- Tooltip -->
        <lib-popover
          style="vertical-align:'text-bottom'"
          *ngIf="field.helpText"
          popoverType="info"
          popoverSize="medium"
          popoverPlacement="right">
          <app-description-text
            [description]="field.helpText"
            [parseAsMarkdown]="showHyperlinkInDescription"
            [checkFromIllumina]="true"
            [lengthLimit]="200"
            [avoidPopover]="true">
          </app-description-text>
        </lib-popover>
      </div>
      <div class="col-6">
        <app-reference-file-dropdown
          [referenceFileFormControlName]="field.id"
          [referenceFileGenomeFilterFieldId]="(field.settings && field.settings.referenceFileGenomeFilterFieldId) || null"
          [genomeFilterGenomeId]="field.genomeFilterGenomeId"
          [referenceFileTypeFilter]="(field.settings && field.settings.referenceFileTypeFilter) || null"
          [genomeHashTableVersion]="field.settings.referenceFileGenomeHashTableVersion"
          [analysisVersionDefinitionId]="analysisVersionDefinitionId"
          [isRequired]="field.required"
          [disableReferenceFileUpload]="disableReferenceFileUpload"
          (newReferenceFileAdded)="onNewReferenceFileAdded($event)"
          [newReferenceFileName$]="newlyAddedReferenceFile$"
        >
        </app-reference-file-dropdown>
        <!-- Validation error -->
        <span *ngIf="myFormGroup.controls[field.id].touched && !myFormGroup.controls[field.id].valid && !myFormGroup.controls[field.id].errors.required" class="error-message">
          {{getErrorMessage(field)}}
        </span>
        <span class="error-message">{{getRenderError(field)}}</span>
      </div>
    </div>

  </ng-container><!-- End single form field-->
</form>
