import {GroupedOption} from '@app/run-planning/model/option';

export enum FormFieldType {
  SECTION = 'section',
  TEXT = 'text',
  TEXTBOX = 'textbox',
  SELECT = 'select',
  FILESELECT = 'fileSelect',
  NUMBER = 'number',
  RADIO = 'radio',
  INTEGER = 'integer',
  CHECKBOX = 'checkbox',
  GENOME = 'genome',
  REFERENCE_FILE = 'referenceFile',
}

/**
 * TODO: consider splitting this up to separate interfaces for each field type,
 * then referencing type of `settings` property in `IFormField` using union types
 */
export interface IFormFieldSettings {
  referenceFileGenomeFilterFieldId?: string;
  referenceFileGenomeHashTableVersion?: string;
  referenceFileTypeFilter?: string;
  dependantFieldIds?: string[];
  hashTableVersion?: string; // For genomes
}

export interface IFormFieldChoice {
  text: string;
  value: string;
  selected: boolean;
  availableWhen?: IFormFieldAvailableWhen[];
  disabled?: boolean;
  valid?: boolean;
}

export interface IFormFieldAvailableWhen {
  id: string;
  hasValue: string[];
}

// TODO value (for default value) is missing?
export interface IFormField {
  type: FormFieldType;
  id: string;
  label: string;
  mapping?: string;
  required?: boolean;
  hidden?: boolean;
  helpText?: string;
  choices?: IFormFieldChoice[];
  regex?: string;
  regexErrorMessage?: string;
  min?: number;
  max?: number;
  hasCustomOption?: boolean;
  groupedChoices?: GroupedOption[];
  disabled?: boolean;
  maxLength?: any;
  minLength?: any;
  updateRenderOnChange: boolean;
  settings: IFormFieldSettings;
  review?: string;
  genomeFilterGenomeId?: string; // Only for reference file fields with genome dependency
}
