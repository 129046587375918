import * as gss from '@stratus/gss-ng-sdk';
import {ConfigSetupState} from '@app/run-planning/config-setup/config-setup.state';
import {clone, each, flatten, get, isEmpty, keyBy} from 'lodash';
import {LaneContent} from '@stratus/gss-ng-sdk/api/models/lane-content';
import {LaneLibrary} from '@stratus/gss-ng-sdk/api/models/lane-library';
import {ISampleDataRow} from '@app/run-planning/interface';
import {IndexAdapterKit} from '@app/run-planning/model/index-adapter-kit';
import {OverrideCycles} from './override-cycles';
import {SubSamplesConfiguration} from '@stratus/gss-ng-sdk/api/models/sub-samples-configuration';
import {ColumnName} from '@app/run-planning/services/sample-table/sample-table-service';
import { EMPTY_STRING } from '../constants';
import { RunPlanningState } from '../run-planning.state';
import { AnalysisVersionDefinition } from './analysis-version-definition';

export class UpdateSequencingRunContentsRequest implements gss.UpdateSequencingRunContentsRequest {
  laneContents?: Array<LaneContent>;
  useFlatRunContent?: boolean;
  laneLibraries?: Array<LaneLibrary>;

  /**
   * Transform UI store states to run content portion of gss request model, consolidates all sample data rows across config
   * into a single runContent request (N:1, N config-samples to run-content)
   */
  constructor(configs: ConfigSetupState[], isMultiAnalysisMode: boolean) {
    // track for each sample, which of its fields (columns) should be mapped back to run content settings (i.e. laneLibrary.settings)
    // note that this is storing the field keys, not the values (the values are stored directly in sampleDataRows)
    // e.g.
    // {
    //   's1': ['RGID', 'Sample_Project'],
    //   's2': ['RGID']
    // }
    const sampleRunContentSettingsKeysMapping: {[key: string]: string[]} = {};
    
    // extract samples across all configs (all configs should have at least 1 sample at this point)
    const sampleDataRows = flatten(configs.map(x => {
      let adapterRead1ToAssign: string;
      let adapterRead2ToAssign: string;

      if (isMultiAnalysisMode) {
        if (!x.rawIndexAdapterKit || x.rawIndexAdapterKit.isUnspecified) {
          /**
           * When user intentionally enters '' for a field,
           * replace it with a placeholder '__EMPTY__STRING__',
           * for `removeEmpty` function to differentiate this case
           * from the case when field not being set/not present
           */
          adapterRead1ToAssign = x.adapterRead1 === '' ? EMPTY_STRING : (x.adapterRead1 || '');
          adapterRead2ToAssign = x.adapterRead2 === '' ? EMPTY_STRING : (x.adapterRead2 || '');
        } else {
          adapterRead1ToAssign = x.adapterRead1 || get(x, 'rawIndexAdapterKit.adapterSequenceRead1');
          adapterRead2ToAssign = x.adapterRead2 || get(x, 'rawIndexAdapterKit.adapterSequenceRead2');
        }
      }
      const sampleData = x.rawAnalysisVersionDefinition && x.rawAnalysisVersionDefinition.hasSubSamples
        ? this.expandSubSamples(x.sampleData,
          x.rawAnalysisVersionDefinition.settings.subSamplesConfiguration,
          x.rawAnalysisVersionDefinition.analysisSampleSettings)
        : x.sampleData;
      
      // Should remove lane column if specified to repeat samples across all lanes
      if(x.enableRepeatSamplesAcrossLanes) {
        sampleData.forEach(x => delete x.laneNumber)
      }

      const uiShouldManageOverrideCycles = ConfigSetupState.wrap(x).uiShouldManageOverrideCycles(isMultiAnalysisMode);

      if (x.rawAnalysisVersionDefinition.runContentSettings && x.rawAnalysisVersionDefinition.runContentSettings.fields) {
        const runContentSettingFieldKeys = x.rawAnalysisVersionDefinition.runContentSettings.fields.map(field => field.id);
        for (const sampleDataRow of sampleData) {
          // a unique sample name can only be used in one config.
          // thus every sampleDataRow that has the same sample name will have the same AVD
          // and same set of runContentSettings field keys (but values can be different for each row).
          sampleRunContentSettingsKeysMapping[sampleDataRow.sampleName] = runContentSettingFieldKeys;
        }
      }
      
      return this.prepareSampleDataRow(sampleData, x.indexAdapterKitId, x.libraryPrepKitId, x.rawAnalysisVersionDefinition,
        x.overrideCycles, adapterRead1ToAssign, adapterRead2ToAssign, uiShouldManageOverrideCycles);
    }));
    const expandedSampleDataRows = this.expandLaneNumbers(sampleDataRows);
    const laneLibraries = expandedSampleDataRows.map(row => this.convertSampleDataRowToLaneLibrary(row, sampleRunContentSettingsKeysMapping));

    this.useFlatRunContent = true;
    this.laneLibraries = laneLibraries;
  }

  /**
   * Assigns config level fields (IAK, LPK Urn, adapterReads) to every sample data row within the same config
   */
  private prepareSampleDataRow(
    sampleData: ISampleDataRow[], iakId: string, lpkId: string, avd: AnalysisVersionDefinition, overrideCycles?: OverrideCycles,
    adapterSequenceRead1?: string, adapterSequenceRead2?: string, cleanDataBasedOnOverrideCycles?: boolean)
    : ISampleDataRow[] {
    each(sampleData, sampleRow => {
      if (iakId === IndexAdapterKit.UNSPECIFIED.id) {
        sampleRow.libraryPrepKitUrn = EMPTY_STRING;
        sampleRow.indexAdapterKitUrn = EMPTY_STRING;
      } else {
        sampleRow.libraryPrepKitUrn = lpkId;
        sampleRow.indexAdapterKitUrn = iakId;
      }

      if (overrideCycles) {
        sampleRow.overrideCycles = overrideCycles.toString();
      }

      if (cleanDataBasedOnOverrideCycles && overrideCycles && overrideCycles.haveValue()) {
        if (overrideCycles.shouldRemoveIndex1RelatedFields()) {
          delete sampleRow.index1Name;
          delete sampleRow.index1Sequence;
          delete sampleRow.barcodeMismatchRead1;
        } else if (overrideCycles.shouldRemoveIndex2RelatedFields()) {
          delete sampleRow.index2Name;
          delete sampleRow.index2Sequence;
          delete sampleRow.barcodeMismatchRead2;
        }
        if (overrideCycles.haveNoIndex()) {
          delete sampleRow.indexContainerPosition;
        }
        if (!overrideCycles.isRead2Present()) {
          sampleRow.adapterSequenceRead2 = EMPTY_STRING;
        }
      } else {
        // Generic clean up/guard
        if (isEmpty(sampleRow.index1Sequence)) {
          delete sampleRow.index1Name;
          delete sampleRow.barcodeMismatchRead1;
        }
        if (isEmpty(sampleRow.index2Sequence)) {
          delete sampleRow.index2Name;
          delete sampleRow.barcodeMismatchRead2;
        }
      }

      // add adapterSequenceReads
      if (adapterSequenceRead1 !== undefined) {
        sampleRow.adapterSequenceRead1 = adapterSequenceRead1;
      }
      const read2IsMaskedByOverrideCycles = cleanDataBasedOnOverrideCycles && overrideCycles && !overrideCycles.isRead2Present();
      if (adapterSequenceRead2 !== undefined && !read2IsMaskedByOverrideCycles) {
        sampleRow.adapterSequenceRead2 = adapterSequenceRead2;
      }
    });
    return sampleData;
  }

  /**
   * Maps ISampleDataRow object (which extends LaneLibrary) to LaneLibrary object
   */
  private convertSampleDataRowToLaneLibrary(sampleDataRow: ISampleDataRow, sampleRunContentSettingsKeysMapping: {[key: string]: string[]})
  : gss.LaneLibrary {
    const {
      laneNumber, sampleName, projectName, adapterSequenceRead1, adapterSequenceRead2,
      index1Sequence, index2Sequence, indexContainerPosition, index1Name, index2Name,
      libraryPrepKitUrn, indexAdapterKitUrn, overrideCycles, barcodeMismatchRead1, barcodeMismatchRead2
    } = sampleDataRow;
    const laneLibrary: gss.LaneLibrary = {
      sampleName,
      ...(laneNumber != null && laneNumber !== 0) && {laneNumber},
      ...projectName && {projectName},
      ...adapterSequenceRead1 && {adapterSequenceRead1},
      ...adapterSequenceRead2 && {adapterSequenceRead2},
      ...index1Sequence && {index1Sequence},
      ...index2Sequence && {index2Sequence},
      ...indexContainerPosition && {indexContainerPosition},
      ...index1Name && {index1Name},
      ...index2Name && {index2Name},
      ...libraryPrepKitUrn && {libraryPrepKitUrn},
      ...indexAdapterKitUrn && {indexAdapterKitUrn},
      ...overrideCycles && {overrideCycles},
      ...(barcodeMismatchRead1 != null) && {barcodeMismatchRead1},
      ...(barcodeMismatchRead2 != null) && {barcodeMismatchRead2}
    };

    const runContentSettingsFieldKeys = sampleRunContentSettingsKeysMapping[sampleDataRow.sampleName];
    if (runContentSettingsFieldKeys && runContentSettingsFieldKeys.length > 0) {
      laneLibrary.settings = {}
      for (const fieldKey of runContentSettingsFieldKeys) {
        laneLibrary.settings[fieldKey] = sampleDataRow[fieldKey];
      }
    }

    const customFieldIds = Object.keys(sampleDataRow).filter(x => x.startsWith('Custom_'));
    if (customFieldIds.length > 0) {
      laneLibrary.customSettings = {};
      for (const id of customFieldIds) {
        laneLibrary.customSettings[id] = sampleDataRow[id];
      }
    }

    return laneLibrary;
  }

  /**
   * Split rows with multiple lane-numbers into multiple rows with single lane-number
   */
  private expandLaneNumbers(sampleDataRows: ISampleDataRow[]): ISampleDataRow[] {
    if (isEmpty(sampleDataRows)) {
      return [];
    }

    const result: ISampleDataRow[] = [];
    for (const row of sampleDataRows) {
      if (isEmpty(row.laneNumbers)) {
        result.push(row);
      } else {
        const laneNumbers = row.laneNumbers;
        const templateRow = clone(row) as ISampleDataRow;
        templateRow.laneNumbers = [];
        for (const laneNumber of laneNumbers) {
          const newRow = clone(templateRow);
          newRow.laneNumber = laneNumber;
          result.push(newRow);
        }
      }
    }
    return result;
  }

  /**
   * Expand sample-data based on sub-sample config, if any
   */
  private expandSubSamples(sampleDataRows: any[], subSamplesConfig: SubSamplesConfiguration, analysisSampleSettings: any): ISampleDataRow[] {
    const subSampleColumnNameRegex = /([^_]+)_(\w+)/;
    const result = [];

    const assocFieldId = subSamplesConfig.associationFieldId;
    const subSampleDefMap = keyBy(subSamplesConfig.subSampleDefinitions, 'id');
    const sampleSettingsFieldMap = keyBy(analysisSampleSettings.fields, 'id');

    for (const row of sampleDataRows) {
      const samples = { };
      for (const subSampleDefId of Object.keys(subSampleDefMap)) {
        const indexIdFieldName = `${subSampleDefId}_${ColumnName.INDEX_CONTAINER_POSITION}`;
        const hasEmptyIndexId = isEmpty(row[indexIdFieldName]);

        if (!hasEmptyIndexId) {
          const sampleName = `${row[assocFieldId]}_${subSampleDefId}`;
          samples[subSampleDefId] = {sampleName};
        }
      }

      for (const columnName of Object.keys(row)) {
        const isNonSubSampleSpecificSettingField = columnName in sampleSettingsFieldMap;
        if (isNonSubSampleSpecificSettingField) {
          continue;
        }

        const arr = subSampleColumnNameRegex.exec(columnName);
        if (isEmpty(arr)) {
          Object.keys(samples).forEach(x => samples[x][columnName] = row[columnName]);
          continue;
        }

        const subSampleDefId = arr[1];
        const actualFieldName = arr[2];

        const columnIsASampleSettingField = (subSampleDefId in subSampleDefMap) && (actualFieldName in sampleSettingsFieldMap);
        const hasEmptyIndexId = !(subSampleDefId in samples);

        if (!columnIsASampleSettingField && !hasEmptyIndexId) {
          samples[subSampleDefId][actualFieldName] = row[columnName];
        }
      }

      for (const subSampleDefId of Object.keys(samples)) {
        result.push(samples[subSampleDefId]);
      }
    }

    return result;
  }
}
