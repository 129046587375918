import { Injectable } from '@angular/core';
import {
  ReferenceFile,
  ReferenceFileCompact
} from '@stratus/gss-ng-sdk';
import {IReferenceFile} from '@models/settings/reference-file';
import {ReferenceFileCompactReferenceFileSortFieldsPagedItems} from '@stratus/gss-ng-sdk';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReferenceFileMapperService {

  constructor(
  ) { }

  mapApiItemsToDataSourceModel(item: ReferenceFileCompact | ReferenceFile): IReferenceFile {
    return {
      fileName: item.name,
      displayName: item.displayName || item.name,
      type: item.isIllumina ? 'Standard' : 'Custom',
      fileType: item.type,
      description: item.description,
      dataLocationUri: item.dataLocationUri,
      supportedGenomesCount: item.supportedGenomesCount,
      referenceFileId: item.id,
      supportedGenomeIds: get(item, 'supportedGenomes') ?
        (item as ReferenceFile).supportedGenomes.map(genome => genome.id) : [],
      timeModified: item.timeModified,
    };
  }

  mapApiResponseListToDataSourceList(response: ReferenceFileCompactReferenceFileSortFieldsPagedItems)
    : IReferenceFile[] {
    if (!response.items) {
      return [];
    }
    return response.items
      .map(item => this.mapApiItemsToDataSourceModel(item));
  }
}
