import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsApiEndPoints } from '@root/src/app/core/services/bs-api/endpoints';
import { map } from 'rxjs/operators';
import { IAppSessionCompact } from '@bssh/ng-sdk';
import { IAppSession } from '@root/src/app/core/model/appSessions/appSession';
import { Constants } from '../utilities/constants';
import { IApiHttpError } from '../model/v2-api/http-error';

@Injectable({
  providedIn: 'root'
})
export class AppResultRedirectGuard implements CanActivate {

  constructor(
    private http: HttpClient, 
    private route: ActivatedRoute,
    private router: Router
  ) {}
  // This was implemented as part of BASE-85215. We want to redirect the user from an app result to the linked app session inputs tab if
  // the app result is valid. However, we want to display the http error component if the app result isn't valid (eg doesn't exist) but we also want to retain the app result URL
  // the user entered in to the browser search box. The happy path case works as expected. The unhappy path does NOT work perfectly. The user will get the http error component
  // displayed to them but the route updates to "/". This seems to be a result of using angular's internal router navigation (eg this.router.navigateByUrl) in a guard or
  // elsewhere (eg interceptor) while the guard is still executing. We don't understand why this interaction manifests this result but this seems to be the best
  // compromise for now.
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const appResultId = next.paramMap.get('appResultId');
    
    return this.http.get(`${BsApiEndPoints.V1PRE3_APPRESULTS}/${appResultId}`).pipe(
      map((response: { Response: { Id: string; AppSession: IAppSession } }) => {
        const { AppSession } = response.Response;
        if(AppSession) {
          return this.router.parseUrl(`/analyses/${AppSession.Id}/files`);
        } else {
          const apiError: IApiHttpError = {
            httpErrorStatus: 404,
            data: {}
          };
          this.router.navigateByUrl(
            Constants.HTTP_ERROR_ROUTE, 
            {
                skipLocationChange: true,
                state: {
                    ApiHttpError: apiError
                }
            }
          );
        }
      })
    );
  }
  
}
