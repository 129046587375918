<ng-container [formGroup]="parentFormGroup">
  <combobox placeholder="Select" #fileSelectCombobox
            [enableDeleteBtn]="true"
            [formControlName]="formField.id"
            [label]="formField.label">
    <combobox-option *ngIf="(hasCustomOption$ | async) && supportLocalUpload"
                     [isCustomLink]="true"
                     class="custom-link"
                     label="+ Upload Custom File (Local)"
                     (customLinkSelect)="localFileInput.click()">
    </combobox-option>
    <combobox-option *ngIf="hasCustomOption$ | async"
                     [isCustomLink]="true"
                     class="custom-link"
                     label="+ Upload Custom File (BaseSpace)"
                     (customLinkSelect)="gdsFileInput.click()">
    </combobox-option>
    <ng-container *ngFor="let type of (groupedChoices$ | async)">
      <combobox-option [isHeader]="true" [label]="type.groupName"></combobox-option>
      <combobox-option *ngFor="let options of type.options"
                       [value]="options.value"
                       [label]="options.text">
      </combobox-option>
    </ng-container>
    <!-- Hidden file inputs -->
    <input #localFileInput
           type="file" hidden
           (change)="handleLocalFile($event, formField.id)"
           data-cy="local-custom-file-upload-input"/>
    <input #gdsFileInput
           type="file" hidden
           (change)="handleGdsFile($event, formField.id)"
           data-cy="gds-file-upload-input"/>
  </combobox>
</ng-container>
