import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RadioCheckboxModule } from "@bssh/comp-lib";
import { RadioCheckboxGroupComponent } from "./radio-checkbox-group.component";

@NgModule({
  declarations: [RadioCheckboxGroupComponent],
  imports: [CommonModule,
            RadioCheckboxModule],
  exports: [RadioCheckboxGroupComponent]
})
export class RadioCheckboxGroupModule {}
