import * as gss from '@stratus/gss-ng-sdk';
import {RunSettingsState} from '@app/run-planning/run-settings/run-settings.state';

export class CreateSequencingRunConfigurationRequest implements gss.CreateSequencingRunConfigurationRequest {
  description: string;
  inputContainerIdentifier: string;
  instrumentPlatform: string;
  instrumentType: string;
  numCyclesIndex1: number;
  numCyclesIndex2: number;
  numCyclesRead1: number;
  numCyclesRead2: number;
  readType: gss.ReadType;
  regulatoryMode: 'RUO' | 'IVD' | 'IUO';
  runName: string;
  useCustomPrimerForIndex1: boolean;
  useCustomPrimerForIndex2: boolean;
  useCustomPrimerForRead1: boolean;
  useCustomPrimerForRead2: boolean;

  constructor(runSettings: RunSettingsState) {
    let numCyclesRead2;
    let readType;

    if (runSettings.readType) {
      numCyclesRead2 = runSettings.readType === gss.ReadType.SINGLE ? 0 : +runSettings.numCyclesRead2;
      readType = runSettings.readType as gss.ReadType;
    } else {
      numCyclesRead2 = +runSettings.numCyclesRead2 || runSettings.read2LengthMin;
      readType = +runSettings.numCyclesRead2 === 0 ? 'Single' : 'Paired';
    }

    this.runName = runSettings.runName.trim();
    this.description = runSettings.runDescription;
    this.instrumentPlatform = runSettings.instrumentPlatform;

    // for single-analysis mode; readLength is null on runSettings and filled later in config; init with read1/2LengthMin
    this.numCyclesRead1 = +runSettings.numCyclesRead1 || runSettings.read1LengthMin;
    this.numCyclesRead2 = numCyclesRead2;
    this.numCyclesIndex1 = +runSettings.numCyclesIndex1;
    this.numCyclesIndex2 = +runSettings.numCyclesIndex2;
    this.readType = readType;
    this.inputContainerIdentifier = runSettings.libraryTubeId;
  }
}
