import {Injectable} from '@angular/core';
import {
  AnalysisLocation,
  FormValueObject,
  ISampleDataRow,
  ISampleTableColumnVisibility,
  RepeatSamplesAcrossLanesState
} from '@app/run-planning/interface';
import _, {cloneDeep, get, groupBy, intersection, isEmpty, map, range, union, uniq} from 'lodash';
import {SequencingRunIndexInfo} from '@stratus/gss-ng-sdk/api/models/sequencing-run-index-info';
import {IndexStrategy} from '@stratus/gss-ng-sdk';
import {InstrumentPlatformInfo} from '@root/node_modules/@stratus/gss-ng-sdk/api/models/instrument-platform-info';
import {ColumnHeaderTooltip, InteractiveTableColDef} from '@app/cloud-run-prep/run-setup/interactive-table/InteractiveTableColDef.interface';
import {FREE_TEXT_MAX_LENGTH, InstrumentAgentType, REGEX_VALIDATIONS, SUPPORTED_HIDDEN_UI_FIELDS} from '@app/run-planning/constants';
import {IndexAdapterKit} from '@app/run-planning/model/index-adapter-kit';
import { RowNode } from 'ag-grid-community';
import {AnalysisVersionDefinition} from '@app/run-planning/model/analysis-version-definition';
import {SubSampleDefinition} from '@stratus/gss-ng-sdk/api/models/sub-sample-definition';
import { SubSamplesConfiguration } from '@stratus/gss-ng-sdk/api/models/sub-samples-configuration';

enum ErrorText {
  SAMPLE_NAME_REQUIRED = 'Sample ID is required and can contain alphanumeric characters, hyphens, underscores with maximum length of 100.',
  INDEX_SEQUENCE_REQUIRED = 'Required. Only the characters A, C, G, and T are allowed.',
  DUPLICATE_INDEX = 'Index is already used in one or more lanes. Please make another selection.',
  FIELD_PATTERN = 'Field can only contain alphanumeric characters, hyphens, and underscores.',
}

// Sample data table
export enum ColumnName {
  // For multi-lane
  LANE_NUMBERS = 'laneNumbers',
  SAMPLE_NAME = 'sampleName',
  INDEX_1_SEQUENCE = 'index1Sequence',
  INDEX_2_SEQUENCE = 'index2Sequence',
  INDEX_1_NAME = 'index1Name',
  INDEX_2_NAME = 'index2Name',
  INDEX_CONTAINER_POSITION = 'indexContainerPosition',
  BARCODE_MISMATCH_READ_1 = 'barcodeMismatchRead1',
  BARCODE_MISMATCH_READ_2 = 'barcodeMismatchRead2',
  PROJECT = 'projectName'
}

export enum DownloadTemplateOption {
  EXTERNAL_STORAGE = 'ExternalStorage',
  LOCAL_STORAGE = 'LocalStorage'
}

export enum ImportSamplesOption {
  CSV = 'Csv',
  SAMPLE_SHEET = 'SampleSheet',
  EXTERNAL_STORAGE = 'ExternalStorage',
  LOCAL_STORAGE = 'LocalStorage',
  BSSH = 'Bssh'
}

const HAS_ANY_SPACE_PATTERN = /\s/g;
const HAS_LEADING_OR_TRAILING_SPACES_PATTERN = /^\s|\s$/;

enum CellTransformType {
  NONE = 0,
  TRIM_SPACES = 1,
  REMOVE_ALL_SPACES = 2
}

const BARCODE_MISMATCH_READ_HEADER_TOOLTIP: ColumnHeaderTooltip = {
  content: 'Why is allowing mismatches when demultiplexing desirable?',
  href: 'https://support.illumina.com/bulletins/2021/08/why-is-allowing-mismatches-when-demultiplexing-desirable-.html',
  // ColumnHeaderTooltip.show will be determined by showHyperlinkInTooltip
}

@Injectable({
  providedIn: 'root'
})
export class SampleTableService {

  public getColumnVisibility(
    isMultiAnalysis: boolean, hasAct: boolean, indexInfo?: SequencingRunIndexInfo,
    indexStrategy?: IndexStrategy, enableRepeatSamplesAcrossLanes: RepeatSamplesAcrossLanesState = RepeatSamplesAcrossLanesState.HIDDEN,
    supportMultiLane?: boolean, currentVisibility?: ISampleTableColumnVisibility, hiddenUiFields?: string[]): ISampleTableColumnVisibility {

    let showIndex1Column = currentVisibility ? currentVisibility.showIndex1Column : true;
    let showIndex2Column = currentVisibility ? currentVisibility.showIndex2Column : true;

    if (indexInfo) {
      showIndex1Column = indexInfo.hasIndex1;
      showIndex2Column = indexInfo.hasIndex2;
    } else if (indexStrategy) {
      if (indexStrategy === IndexStrategy.NO_INDEX) {
        showIndex1Column = false;
        showIndex2Column = false;
      } else if (indexStrategy === IndexStrategy.SINGLE) {
        showIndex1Column = true;
        showIndex2Column = false;
      } else {
        showIndex1Column = true;
        showIndex2Column = true;
      }
    }
    let showBarcodeIndex1Column, showBarcodeIndex2Column;

    if (!isMultiAnalysis) {
      showBarcodeIndex1Column = showBarcodeIndex2Column = false;
    } else {
      // barcode mismatch column is dependent on index column visibility
      showBarcodeIndex1Column = showIndex1Column;
      showBarcodeIndex2Column = showIndex2Column;

      if (hiddenUiFields) {
        // Check if specified in hidden ui fields
        if (showBarcodeIndex1Column && hiddenUiFields.includes(SUPPORTED_HIDDEN_UI_FIELDS.barcodeMismatchRead1)) {
          showBarcodeIndex1Column = false;
        }
        if (showBarcodeIndex2Column && hiddenUiFields.includes(SUPPORTED_HIDDEN_UI_FIELDS.barcodeMismatchRead2)) {
          showBarcodeIndex2Column = false;
        }
      }
    }

    return {
      ...currentVisibility,
      showIndex1Column,
      showIndex2Column,
      showBarcodeIndex1Column,
      showBarcodeIndex2Column,
      disableLanes: hasAct || false,
      disableSampleName: hasAct || false,
      disableWellPosition: hasAct || false,
      disableIndex1Column: hasAct || false,
      disableIndex2Column: hasAct || false,
      hideLanes: this.shouldLaneColumnBeHidden(enableRepeatSamplesAcrossLanes, supportMultiLane, currentVisibility),
    };
  }

  public getColumnDefinitions(
    indexAdapterKit: IndexAdapterKit, columnVisibility: ISampleTableColumnVisibility,
    instrumentPlatformInfo: InstrumentPlatformInfo, avd: AnalysisVersionDefinition,
    isStandAlone: boolean, customSettingsFields: string[]) {

    let colDefs: InteractiveTableColDef[];
    if (indexAdapterKit && !indexAdapterKit.isUnspecified) {
      colDefs = this.getDefaultColDefs(indexAdapterKit, columnVisibility, instrumentPlatformInfo.maxNumberOfLanes,
        isStandAlone, get(avd, 'settings.hiddenUiFields', []));
    } else {
      colDefs = this.getUnspecifiedColDefs(columnVisibility, instrumentPlatformInfo.maxNumberOfLanes,
        isStandAlone, get(avd, 'settings.hiddenUiFields', []));
    }
    if (avd && avd.hasSubSamples) {
      const subSamplesConfig = avd.settings.subSamplesConfiguration;

      const assocField = avd.analysisSampleSettings.fields.find(x => x.id === subSamplesConfig.associationFieldId);
      if (!assocField) {
        throw new Error(`Association field ${subSamplesConfig.associationFieldId} was not found in avd ${avd.id}`);
      }

      const colDefsFromSampleTable = this.expandColumnDefinitions(colDefs, subSamplesConfig.subSampleDefinitions);
      const colDefsFromSampleSettings = this.createColumnDefinitionsFromSampleSettings(avd);
      colDefs = this.combineColDefs(colDefsFromSampleTable, colDefsFromSampleSettings, subSamplesConfig);
    }

    colDefs = this.updateColumnDefinitionsWithRunContentSettings(colDefs, avd);
    colDefs = this.updateColumnDefinitionsWithCustomSettings(colDefs, customSettingsFields);

    return colDefs;
  }

  private defaultColDefForCellTypes: {[key: string]: InteractiveTableColDef} = {
    'textbox': {
      minWidth: 140,
      width: 140,
      cellParams: {
        type: 'text',
        placeholder: '',
        required: false,
        maxLength: FREE_TEXT_MAX_LENGTH
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderSort: true
    },
    'select': {
      width: 140,
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: false
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderSort: false
    }
  };
  
  private updateColumnDefinitionsWithRunContentSettings(colDefs: InteractiveTableColDef[], avd: AnalysisVersionDefinition): InteractiveTableColDef[] {
    if (!(avd && avd.sampleTableRunContentSettingsFields)) {
      return colDefs;
    }
    
    for (const field of avd.sampleTableRunContentSettingsFields) {
      // get default column def for this field type
      const colDef = (field.type && this.defaultColDefForCellTypes[field.type]) ? cloneDeep(this.defaultColDefForCellTypes[field.type]) : null;

      if (colDef) {
        // complete the column def
        colDef.field = field.id;
        colDef.headerName = field.label ? field.label : field.id;
        colDef.cellParams.required = field.required;

        // insert the colDef before project column (or at the end if project column is absent)
        const projectColIndex = colDefs.findIndex(colDef => colDef.field === ColumnName.PROJECT);
        if (projectColIndex !== -1) {
          colDefs.splice(projectColIndex, 0, colDef);
        } else {
          colDefs.splice(colDefs.length, 0, colDef);
        }
      } else {
        console.error(`Cannot find default column definition for runContentSettingsField of type '${field.type}'.`)
      }
    }

    return colDefs;
  }

  private updateColumnDefinitionsWithCustomSettings(colDefs: InteractiveTableColDef[], customSettingsFields: string[]): InteractiveTableColDef[] {
    // remove existing custom columns
    const result = colDefs.filter(colDef => !colDef.field.startsWith('Custom_'));
    
    if (customSettingsFields) {
      for (const field of customSettingsFields) {
        const colDef = cloneDeep(this.defaultColDefForCellTypes.textbox);
        colDef.field = colDef.headerName = field;
  
        // insert the colDef before project column (or at the end if project column is absent)
        const projectColIndex = result.findIndex(colDef => colDef.field === ColumnName.PROJECT);
        if (projectColIndex !== -1) {
          result.splice(projectColIndex, 0, colDef);
        } else {
          result.splice(colDefs.length, 0, colDef);
        }
      }
    }
    
    return result;
  }

  /**
   * Combine column definitions from sample table and sample-settings table for TSO, and sort them in this order:
   *   1. "Association field"
   *   2. Sub-sample-specific fields, i.e.  DNA xxxx, RNA xxx
   *   3. Non sub-sample-specific fields
   */
  // TODO investigate the implementation below to make it clearer and/or more efficient
  private combineColDefs(colDefsFromSampleTable: InteractiveTableColDef[], colDefsFromSampleSettings: InteractiveTableColDef[],
    subSamplesConfig: SubSamplesConfiguration): InteractiveTableColDef[] {
      const FIRST_GROUP = '!'; // can use any character outside of the domain of sub-sample
      const LAST_GROUP = '~';

      let colDefs = colDefsFromSampleTable.concat(colDefsFromSampleSettings);
      const groupedColDefs = groupBy(colDefs, c => {
        if (c.field === subSamplesConfig.associationFieldId) {
          return FIRST_GROUP;
        } else {
          const subSample = subSamplesConfig.subSampleDefinitions.find(s => c.field.startsWith(`${s.id}_`));
          return subSample ? subSample.id : LAST_GROUP;
        }
      });

      const result = groupedColDefs[FIRST_GROUP];
      for (const def of subSamplesConfig.subSampleDefinitions) {
        if (!isEmpty(groupedColDefs[def.id])) {
          result.push(...groupedColDefs[def.id]);
        }
      }
      if (!isEmpty(groupedColDefs[LAST_GROUP])) {
        result.push(...groupedColDefs[LAST_GROUP]);
      }
      return result;
  }

  static defaultFillDown(params, val) {
    const results = [];
    let prefix = '';
    let counter = 1;
    for (let i = val.length - 1; i >= 0; i--) {
      if (isNaN(parseInt(val[i], 10))) {
        break;
      } else {
        counter = parseInt(val.substr(i), 10);
        prefix = val.substr(0, i);
      }
    }
    params.api.forEachNode(node => {
      const data = node.data;
      data[params.column.getColId()] = `${prefix}${counter}`;
      results.push(data);
      counter++;
    });
    return results;
  }

  static barcodeMismatchFillDown(params, val) {
    const results = [];
    params.api.forEachNode(node => {
      const data = node.data;
      // for barcode mismatch index column, copy first value when filling down
      if (val) {
        // if barcode mismatch and value is empty, do not change data
        data[params.column.getColId()] = val;
        results.push(data);
      }
    });
    return results;
  }

  private getUnspecifiedColDefs(columnVisibility: ISampleTableColumnVisibility,
    maxNumberOfLanes: number, isStandAlone: boolean, hiddenUiFields?: string[]): InteractiveTableColDef[] {

    const lanesColDef: InteractiveTableColDef = {
      headerName: 'Lanes',
      field: ColumnName.LANE_NUMBERS,
      minWidth: 100,
      width: 100,
      cellParams: {
        type: 'multiselect',
        values: [], // to be updated with range of lanes in getDefaultColDefs
        required: true
      },
      errorText: {
        required: 'Required.',
        duplicate: ErrorText.DUPLICATE_INDEX
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown
    };

    const sampleNameColDef: InteractiveTableColDef = {
      headerName: 'Sample ID',
      field: ColumnName.SAMPLE_NAME,
      width: 310,
      cellParams: {
        type: 'text',
        regex: REGEX_VALIDATIONS.restrictedNameRegex,
        placeholder: 'Enter Sample ID...',
        required: true
      },
      errorText: {
        required: ErrorText.SAMPLE_NAME_REQUIRED,
        pattern: ErrorText.SAMPLE_NAME_REQUIRED
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderSort: true,
      actAsId: true
    };

    const i7SeqColDef: InteractiveTableColDef = {
      headerName: 'Index 1',
      field: ColumnName.INDEX_1_SEQUENCE,
      width: 300,
      hide: false,
      cellParams: {
        type: 'text',
        required: true,
        regex: REGEX_VALIDATIONS.sequenceRegex,
        length: null
      },
      errorText: {
        required: ErrorText.INDEX_SEQUENCE_REQUIRED,
        pattern: ErrorText.INDEX_SEQUENCE_REQUIRED,
        length: 'Index 1 is ${0} characters long. It must be ${1} characters long.',
        duplicate: 'This lane and index combination is already used. Make another selection'
      }
    };

    const i5SeqColDef: InteractiveTableColDef = {
      headerName: 'Index 2',
      field: ColumnName.INDEX_2_SEQUENCE,
      width: 300,
      hide: false,
      cellParams: {
        type: 'text',
        regex: REGEX_VALIDATIONS.sequenceRegex,
        required: true,
        length: null
      },
      errorText: {
        required: ErrorText.INDEX_SEQUENCE_REQUIRED,
        pattern: ErrorText.INDEX_SEQUENCE_REQUIRED,
        length: 'Index 2 is ${0} characters long. It must be ${1} characters long.',
        duplicate: 'This lane and index combination is already used. Make another selection'
      }
    };

    const barcodeIndex1ColDef: InteractiveTableColDef = {
      headerName: `Barcode Mismatches Index 1`,
      field: ColumnName.BARCODE_MISMATCH_READ_1,
      width: 200,
      errorText: {
        required: 'Required.'
      },
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: true,
        values: [0, 1, 2]
      },
      headerClass: 'break-word-header',
      defaultValue: 1,
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.barcodeMismatchFillDown,
      customHeaderTooltip: BARCODE_MISMATCH_READ_HEADER_TOOLTIP
    };

    const barcodeIndex2ColDef: InteractiveTableColDef = {
      headerName: `Barcode Mismatches Index 2`,
      field: ColumnName.BARCODE_MISMATCH_READ_2,
      width: 200,
      errorText: {
        required: 'Required.'
      },
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: true,
        values: [0, 1, 2]
      },
      headerClass: 'break-word-header',
      defaultValue: 1,
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.barcodeMismatchFillDown,
      customHeaderTooltip: BARCODE_MISMATCH_READ_HEADER_TOOLTIP
    };

    const projectColDef: InteractiveTableColDef = {
      headerName: 'Project',
      field: ColumnName.PROJECT,
      width: 140,
      cellParams: {
        type: 'text',
        placeholder: '',
        required: false,
        maxLength: FREE_TEXT_MAX_LENGTH
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderSort: true
    };
    const defs = [
      lanesColDef,
      sampleNameColDef,
      i7SeqColDef,
      i5SeqColDef,
      barcodeIndex1ColDef,
      barcodeIndex2ColDef
    ];
    if (!isStandAlone && !hiddenUiFields.includes(SUPPORTED_HIDDEN_UI_FIELDS.project)) {
      defs.push(projectColDef);
    }

    this.showHideLaneColumn(defs, columnVisibility, maxNumberOfLanes);

    i7SeqColDef.hide = !columnVisibility.showIndex1Column;
    i5SeqColDef.hide = !columnVisibility.showIndex2Column;
    barcodeIndex1ColDef.hide = !columnVisibility.showBarcodeIndex1Column;
    barcodeIndex2ColDef.hide = !columnVisibility.showBarcodeIndex2Column;

    // Disable columns
    sampleNameColDef.editable = !columnVisibility.disableSampleName && sampleNameColDef.editable;
    i7SeqColDef.editable = !columnVisibility.disableIndex1Column && i7SeqColDef.editable;
    i5SeqColDef.editable = !columnVisibility.disableIndex2Column && i5SeqColDef.editable;

    i7SeqColDef.computed = i5SeqColDef.computed = false;
    i7SeqColDef.lengthSetter = i5SeqColDef.lengthSetter = this.maxColumnValueLength;

    return defs;
  }

  // TODO To investigate refactoring this logic to make it clearer and not duplicated
  private createColumnDefinitionsFromSampleSettings(avd: AnalysisVersionDefinition): InteractiveTableColDef[] {
    const result: InteractiveTableColDef[] = [];
    const subSamplesDefs = avd.settings.subSamplesConfiguration.subSampleDefinitions;

    for (const field of avd.analysisSampleSettings.fields) {
      const settings = field.settings;

      const supportedSubSampleIds = get(settings, 'supportedSubSampleIds', []);
      const supportedSubSampleDefs = subSamplesDefs.filter(x => supportedSubSampleIds.includes(x.id));

      if (!isEmpty(supportedSubSampleDefs)) {
        for (const subSamplesDef of supportedSubSampleDefs) {
          const def = {} as InteractiveTableColDef;
          def.headerName = `${subSamplesDef.labelPrefix}${field.label}`;
          def.field = `${subSamplesDef.id}_${field.id}`;

          const defaultValue = avd.getSampleSettingFieldDefaultValue(field, subSamplesDef.id);
          if (!isEmpty(defaultValue)) {
            def.defaultValue = defaultValue;
          }

          def.hide = field.hidden;
          def.width = 200;
          def.cellParams = {
            type: this.mapGssFieldTypeToAgGrid(field.type),
            required: field.required
          };

          if ((field.type === 'select') && field.choices) {
            def.cellParams.values = field.choices.map(x => x.value);
          }

          def.errorText = {
            required: def.cellParams.required ? 'Field is required' : undefined,
            pattern: def.cellParams.regex ? 'Invalid value' : undefined
          };

          if (field.helpText) {
            def.customHeaderTooltip = {
              content: field.helpText,
              show: true
            }
          }

          result.push(def);
        }
      } else {
        const def = {} as InteractiveTableColDef;
        def.headerName = field.label;
        def.field = field.id;

        const defaultValue =  avd.getSampleSettingFieldDefaultValue(field);
        if (!isEmpty(defaultValue)) {
          def.defaultValue = defaultValue;
        }

        def.hide = field.hidden;
        def.width = 200;
        def.cellParams = {
          type: this.mapGssFieldTypeToAgGrid(field.type),
          required: field.required
        };

        if ((field.type === 'select') && field.choices) {
          def.cellParams.values = field.choices.map(x => x.value);
        }

        def.errorText = {
          required: def.cellParams.required ? 'Field is required' : undefined,
          pattern: def.cellParams.regex ? 'Invalid value' : undefined
        };

        if (field.helpText) {
          def.customHeaderTooltip = {
            content: field.helpText,
            show: true
          }
        }

        result.push(def);
      }
    }

    // assocField will be used to generate actual sample names, so the validation should follow Sample Name validation.
    const assocFieldColDef = result.find(x => x.field === avd.settings.subSamplesConfiguration.associationFieldId);
    if (assocFieldColDef) {
      assocFieldColDef.cellParams.regex = REGEX_VALIDATIONS.restrictedNameRegex;
      assocFieldColDef.errorText.pattern = ErrorText.FIELD_PATTERN;
      assocFieldColDef.errorText.duplicate = assocFieldColDef.headerName + ' is used in multiple rows.';
      assocFieldColDef.actAsId = true;
    }

    return result;
  }

  /**
   * Creating a new set of column-definitions based on existing column-definition and sub-sample configuration.
   */
  private expandColumnDefinitions(colDefs: InteractiveTableColDef[], subSamplesDefs: Array<SubSampleDefinition>): InteractiveTableColDef[] {
    const result: InteractiveTableColDef[] = [];

    // all others are assumed as sub-sample-specific
    const otherColumns = colDefs.filter(x => (x.field !== ColumnName.SAMPLE_NAME && x.field !== ColumnName.PROJECT));
    for (const subSamplesDef of subSamplesDefs) {
      for (const col of otherColumns) {
        const newColDef = cloneDeep(col);
        newColDef.headerName = `${subSamplesDef.labelPrefix}${col.headerName}`;
        newColDef.field = `${subSamplesDef.id}_${col.field}`;
        delete newColDef.minWidth; // allow unrestricted resize for TSO mode

        newColDef.cellParams.required = false;
        if (col.cellParams.required && (col.field !== ColumnName.INDEX_CONTAINER_POSITION)) {
          newColDef.cellParams.requiredIf = `${subSamplesDef.id}_${ColumnName.INDEX_CONTAINER_POSITION}`;
        }

        if ((col.field === ColumnName.INDEX_1_NAME) || (col.field === ColumnName.INDEX_2_NAME)) {
          this.adjustIndexNameLookup(newColDef, subSamplesDef);
          newColDef.hide = true;
        } else if (col.field === ColumnName.INDEX_1_SEQUENCE) {
          this.adjustI7SeqLookup(newColDef, subSamplesDef);
          newColDef.hide = true;
        } else if (col.field === ColumnName.INDEX_2_SEQUENCE) {
          this.adjustI5SeqLookup(newColDef, subSamplesDef);
          newColDef.hide = true;
        }

        result.push(newColDef);
      }
    }

    // add project column back to colDef (if present)
    const projectColDef = colDefs.find(x => x.field === ColumnName.PROJECT);
    if (projectColDef) {
      result.push(projectColDef);
    }
    
    return result;
  }

  public getDefaultColDefs(indexAdapterKit?: IndexAdapterKit, columnVisibility?: ISampleTableColumnVisibility,
    maxNumberOfLanes?: number, isStandAlone?: boolean, hiddenUiFields: string[] = []) {

    const lanesColDef: InteractiveTableColDef = {
      headerName: 'Lanes',
      field: ColumnName.LANE_NUMBERS,
      minWidth: 100,
      width: 100,
      cellParams: {
        type: 'multiselect',
        values: [], // to be updated with range of lanes in getDefaultColDefs
        required: true
      },
      errorText: {
        required: 'Required.',
        duplicate: ErrorText.DUPLICATE_INDEX
      },
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderFillDown: true
    };

    const sampleNameColDef: InteractiveTableColDef = {
      headerName: 'Sample ID',
      field: ColumnName.SAMPLE_NAME,
      width: 200,
      cellParams: {
        type: 'text',
        regex: REGEX_VALIDATIONS.restrictedNameRegex,
        required: true
      },
      errorText: {
        required: ErrorText.SAMPLE_NAME_REQUIRED,
        pattern: ErrorText.SAMPLE_NAME_REQUIRED
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderSort: true,
      actAsId: true
    };

    const wellPosColDef: InteractiveTableColDef = {
      headerName: 'Well Position',
      field: ColumnName.INDEX_CONTAINER_POSITION,
      width: 145,
      hide: true,
      errorText: {
        required: 'Required.',
        duplicate: 'This well position is already used. Make another selection'
      },
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        values: [],
        required: true
      }
    };

    const i7NameColDef: InteractiveTableColDef = {
      headerName: 'I7 index',
      field: ColumnName.INDEX_1_NAME,
      width: 145,
      errorText: {
        required: 'Required.',
        duplicate: 'This index is already used. Make another selection.',
      },
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: true,
        values: []
      }
    };

    const i5NameColDef: InteractiveTableColDef = {
      headerName: 'I5 index',
      field: ColumnName.INDEX_2_NAME,
      width: 145,
      errorText: {
        required: 'Required.',
        duplicate: 'This index is already used. Make another selection.'
      },
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: true,
        values: []
      }
    };

    const i7SeqColDef: InteractiveTableColDef = {
      headerName: 'Index 1',
      field: ColumnName.INDEX_1_SEQUENCE,
      width: 145,
      editable: false,
      cellParams: {
        type: 'text',
        required: true,
        regex: REGEX_VALIDATIONS.sequenceRegex,
        values: []
      },
      errorText: {
        required: ErrorText.INDEX_SEQUENCE_REQUIRED,
        pattern: ErrorText.INDEX_SEQUENCE_REQUIRED
      }
    };

    const i5SeqColDef: InteractiveTableColDef = {
      headerName: 'Index 2',
      field: ColumnName.INDEX_2_SEQUENCE,
      width: 145,
      suppressAutoSize: true,
      editable: false,
      errorText: {
        required: ErrorText.INDEX_SEQUENCE_REQUIRED,
        pattern: ErrorText.INDEX_SEQUENCE_REQUIRED
      },
      cellParams: {
        type: 'text',
        regex: REGEX_VALIDATIONS.sequenceRegex,
        required: true
      }
    };

    const projectColDef: InteractiveTableColDef = {
      // 'Group' corresponds to 'projectName' in GSS, an optional field
      headerName: 'Project',
      field: ColumnName.PROJECT,
      minWidth: 140,
      width: 140,
      cellParams: {
        type: 'text',
        placeholder: '',
        required: false,
        maxLength: FREE_TEXT_MAX_LENGTH
      },
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.defaultFillDown,
      enableHeaderSort: true
    };

    const barcodeIndex1ColDef: InteractiveTableColDef = {
      headerName: `Barcode Mismatches Index 1`,
      field: ColumnName.BARCODE_MISMATCH_READ_1,
      errorText: {
        required: 'Required.'
      },
      width: 200,
      minWidth: 90,
      suppressAutoSize: true,
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: true,
        values: [0, 1, 2]
      },
      headerClass: 'break-word-header',
      defaultValue: 1,
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.barcodeMismatchFillDown,
      customHeaderTooltip: BARCODE_MISMATCH_READ_HEADER_TOOLTIP
    };

    const barcodeIndex2ColDef: InteractiveTableColDef = {
      headerName: `Barcode Mismatches Index 2`,
      field: ColumnName.BARCODE_MISMATCH_READ_2,
      errorText: {
        required: 'Required.'
      },
      width: 200,
      minWidth: 90,
      suppressAutoSize: true,
      cellParams: {
        type: 'dropdown',
        placeholder: 'Select',
        required: true,
        values: [0, 1, 2]
      },
      headerClass: 'break-word-header',
      defaultValue: 1,
      enableHeaderFillDown: true,
      fillDownFn: SampleTableService.barcodeMismatchFillDown,
      customHeaderTooltip: BARCODE_MISMATCH_READ_HEADER_TOOLTIP
    };

    const defs: InteractiveTableColDef[] = [
      lanesColDef,
      sampleNameColDef,
      wellPosColDef,
      i7NameColDef,
      i7SeqColDef,
      i5NameColDef,
      i5SeqColDef,
      barcodeIndex1ColDef,
      barcodeIndex2ColDef
    ];
    if (!isStandAlone && !hiddenUiFields.includes(SUPPORTED_HIDDEN_UI_FIELDS.project)) {
      defs.push(projectColDef);
    }

    if (maxNumberOfLanes) {
      this.showHideLaneColumn(defs, columnVisibility, maxNumberOfLanes);
    }

    if (columnVisibility) {
      i7NameColDef.hide = i7SeqColDef.hide = !columnVisibility.showIndex1Column;
      i5NameColDef.hide = i5SeqColDef.hide = !columnVisibility.showIndex2Column;
      barcodeIndex1ColDef.hide = !columnVisibility.showBarcodeIndex1Column;
      barcodeIndex2ColDef.hide = !columnVisibility.showBarcodeIndex2Column;
    }

    if (indexAdapterKit) {
      const wellPosOptions = this.getWellPosOptions(indexAdapterKit);
      wellPosColDef.cellParams.values = wellPosOptions;
      wellPosColDef.hide = wellPosOptions.length <= 0;
      i7NameColDef.editable = i5NameColDef.editable = wellPosOptions.length <= 0;

      i7NameColDef.cellParams.values = this.getI7NameOptions(indexAdapterKit);
      i5NameColDef.cellParams.values = this.getI5NameOptions(indexAdapterKit);
    }

    if (columnVisibility) {
      wellPosColDef.hide = wellPosColDef.hide || (!columnVisibility.showIndex1Column && !columnVisibility.showIndex2Column);
    }

    // Disable columns
    if (lanesColDef && columnVisibility) {
      lanesColDef.editable = !columnVisibility.disableLanes && lanesColDef.editable;
    }

    if (columnVisibility) {
      sampleNameColDef.editable = !columnVisibility.disableSampleName && sampleNameColDef.editable;
      wellPosColDef.editable = !columnVisibility.disableWellPosition && wellPosColDef.editable;
      i7NameColDef.editable = !columnVisibility.disableIndex1Column && i7NameColDef.editable;
      i5NameColDef.editable = !columnVisibility.disableIndex2Column && i5NameColDef.editable;
    }

    // check whether need to rename header from "well position" to "index ID"
    if (indexAdapterKit && indexAdapterKit.settings && indexAdapterKit.settings.fixedLayoutPositionKeyByIndexId) {
      wellPosColDef.headerName = 'Index ID';
      wellPosColDef.errorText = {
        required: 'Required.',
        duplicate: 'This index ID is already used. Make another selection'
      };

    } else {
      wellPosColDef.headerName = 'Well Position';
      wellPosColDef.errorText = {
        required: 'Required.',
        duplicate: 'This well position is already used. Make another selection'
      };
    }

    if (i7NameColDef) {
      i7NameColDef.computed = wellPosColDef && !wellPosColDef.hide;
    }

    if (i5NameColDef) {
      i5NameColDef.computed = wellPosColDef && !wellPosColDef.hide;
    }

    i7SeqColDef.computed = i7NameColDef && !i7NameColDef.hide;
    i5SeqColDef.computed = i5NameColDef && !i5NameColDef.hide;

    if (i7NameColDef.computed && indexAdapterKit) {
      this.setupI7NameLookup(i7NameColDef, indexAdapterKit);
    } else {
      this.clearLookup(i7NameColDef);
    }

    if (i5NameColDef.computed && indexAdapterKit) {
      this.setupI5NameLookup(i5NameColDef, indexAdapterKit);
    } else {
      this.clearLookup(i5NameColDef);
    }

    if (i7SeqColDef.computed && indexAdapterKit) {
      this.setupI7SeqLookup(i7SeqColDef, indexAdapterKit);
    } else {
      this.clearLookup(i7SeqColDef);
    }

    if (i5SeqColDef.computed && indexAdapterKit) {
      this.setupI5SeqLookup(i5SeqColDef, indexAdapterKit);
    } else {
      this.clearLookup(i5SeqColDef);
    }

    return defs;
  }

  public maxColumnValueLength(colDef: InteractiveTableColDef, data: ISampleDataRow[]): number {
      if (!colDef || !data) {
        return undefined;
      }

      const length = _.chain(data)
          .filter(x => x[colDef.field])
          .map(x => x[colDef.field].length)
          .max()
          .value() || 0;

      return length;
  }

  public transformCellValue(cellValue: any, column: string, node?: RowNode) {
    let cellTransformType;
    switch (column) {
      case ColumnName.INDEX_1_SEQUENCE:
      case ColumnName.INDEX_2_SEQUENCE:
        cellTransformType = HAS_ANY_SPACE_PATTERN.test(cellValue as string) ? CellTransformType.REMOVE_ALL_SPACES : CellTransformType.NONE;
        break;
      case ColumnName.SAMPLE_NAME:
        cellTransformType = HAS_LEADING_OR_TRAILING_SPACES_PATTERN.test(cellValue as string)
          ? CellTransformType.TRIM_SPACES : CellTransformType.NONE;
        break;
      default:
        cellTransformType = CellTransformType.NONE;
    }

    let newCellValue;
    switch (cellTransformType) {
      case CellTransformType.REMOVE_ALL_SPACES:
        newCellValue = cellValue.replace(HAS_ANY_SPACE_PATTERN, '');
        break;
      case CellTransformType.TRIM_SPACES:
        newCellValue = cellValue.trim();
        break;
      default:
        return cellValue;
    }

    if (node) {
      node.setDataValue(column, newCellValue);
    }
    return newCellValue;
  }

  private shouldLaneColumnBeHidden(enableRepeatSamplesAcrossLanes: RepeatSamplesAcrossLanesState,
      supportMultiLane: boolean, columnVisibility: ISampleTableColumnVisibility): boolean {
    if (enableRepeatSamplesAcrossLanes !== RepeatSamplesAcrossLanesState.HIDDEN) {
      switch (enableRepeatSamplesAcrossLanes) {
        case RepeatSamplesAcrossLanesState.CHECKED:
          return true;
        case RepeatSamplesAcrossLanesState.UNCHECKED:
          return false;
        default:
          break;
      }
    }
    if (supportMultiLane != null)  {
      return !supportMultiLane;
    }
    return get(columnVisibility, 'hideLanes', false);
  }

  private showHideLaneColumn(defs: InteractiveTableColDef[], columnVisibility: ISampleTableColumnVisibility, maxNumberOfLanes: number) {
    const laneColDef = defs.find(x => x.field === ColumnName.LANE_NUMBERS);
    laneColDef.hide = columnVisibility.hideLanes;
    if (!laneColDef.hide) {
      const laneValues = range(1, maxNumberOfLanes + 1);
      laneColDef.cellParams.values = map(laneValues, laneNum => laneNum.toString());  // cast to string to prevent invalid paste
    }
  }

  private getI7NameOptions(indexAdapterKit: IndexAdapterKit): string[] {
    if (!indexAdapterKit || !indexAdapterKit.indexSequences) {
      return [];
    }

    return indexAdapterKit.indexSequences
      .filter((obj) => obj.readNumber === 1)
      .map((v) => v.name);
  }

  private getI5NameOptions(indexAdapterKit: IndexAdapterKit): string[] {
    if (!indexAdapterKit || !indexAdapterKit.indexSequences) {
      return [];
    }

    return indexAdapterKit.indexSequences
      .filter((obj) => obj.readNumber === 2)
      .map((v) => v.name);
  }

  private getWellPosOptions(indexAdapterKit: IndexAdapterKit): string[] {
    const fixedIndexPositions = get(indexAdapterKit, 'settings.fixedIndexPositions', null);
    if (!fixedIndexPositions) {
      return [];
    }
    return map(fixedIndexPositions, (x: string) => x.split('/')[0]);
  }

  private clearLookup(colDef: InteractiveTableColDef) {
    colDef.valueGetter = undefined;
    if (colDef.data) {
      colDef.data.lookup = undefined;
    }
  }

  private setupI7NameLookup(colDef: InteractiveTableColDef, indexAdapterKit: IndexAdapterKit) {
    const i7Lookup = {};
    for (const item of indexAdapterKit.settings.fixedIndexPositions) {
      const re = /([^\/]+)\/(\w+)-(\w+)/g;
      const match = re.exec(item);
      i7Lookup[match[1]] = match[2];
    }
    colDef.data = colDef.data || {};
    colDef.data.lookup = i7Lookup;

    colDef.valueGetter = params =>
      (params.colDef as InteractiveTableColDef).data.lookup[params.data[ColumnName.INDEX_CONTAINER_POSITION]];
  }

  private setupI5NameLookup(colDef: InteractiveTableColDef, indexAdapterKit: IndexAdapterKit) {
    const i5Lookup = {};
    for (const item of indexAdapterKit.settings.fixedIndexPositions) {
      const re = /([^\/]+)\/(\w+)-(\w+)/g;
      const match = re.exec(item);
      i5Lookup[match[1]] = match[3];
    }
    colDef.data = colDef.data || {};
    colDef.data.lookup = i5Lookup;

    colDef.valueGetter = params =>
      (params.colDef as InteractiveTableColDef).data.lookup[params.data[ColumnName.INDEX_CONTAINER_POSITION]];
  }

  private adjustIndexNameLookup(colDef: InteractiveTableColDef, subSampleDef: SubSampleDefinition) {
    colDef.valueGetter = params => {
      const key = `${subSampleDef.id}_${ColumnName.INDEX_CONTAINER_POSITION}`;
      const lookupData = get(params.colDef, 'data.lookup', {});
      return lookupData[params.data[key]];
    };
  }

  private setupI7SeqLookup(colDef: InteractiveTableColDef, indexAdapterKit: IndexAdapterKit) {
    const i7Lookup = {};
    for (const item of indexAdapterKit.indexSequences) {
      i7Lookup[`${item.name}|${item.readNumber}`] = item.sequence;
    }
    colDef.data = colDef.data || {};
    colDef.data.lookup = i7Lookup;

    colDef.valueGetter = params => {
      const i7Name = params.getValue(ColumnName.INDEX_1_NAME);
      const iColDef = params.colDef as InteractiveTableColDef;
      return iColDef.data.lookup[`${i7Name}|1`];
    };
  }

  private adjustI7SeqLookup(colDef: InteractiveTableColDef, subSampleDef: SubSampleDefinition) {
    colDef.valueGetter = params => {
      const i7Name = params.getValue(`${subSampleDef.id}_${ColumnName.INDEX_1_NAME}`);
      const iColDef = params.colDef as InteractiveTableColDef;
      return iColDef.data.lookup[`${i7Name}|1`];
    };
  }

  private setupI5SeqLookup(colDef: InteractiveTableColDef, indexAdapterKit: IndexAdapterKit) {
    const i5Lookup = {};
    for (const item of indexAdapterKit.indexSequences) {
      i5Lookup[`${item.name}|${item.readNumber}`] = item.sequence;
    }
    colDef.data = colDef.data || {};
    colDef.data.lookup = i5Lookup;

    colDef.valueGetter = params => {
      const i5Name = params.getValue(ColumnName.INDEX_2_NAME);
      const iColDef = params.colDef as InteractiveTableColDef;
      return iColDef.data.lookup[`${i5Name}|2`];
    };
  }

  private adjustI5SeqLookup(colDef: InteractiveTableColDef, subSampleDef: SubSampleDefinition) {
    colDef.valueGetter = params => {
      const i5Name = params.getValue(`${subSampleDef.id}_${ColumnName.INDEX_2_NAME}`);
      const iColDef = params.colDef as InteractiveTableColDef;
      return iColDef.data.lookup[`${i5Name}|2`];
    };
  }

  private mapGssFieldTypeToAgGrid(type: string): 'text' | 'dropdown' | 'checkbox' | 'multiselect' {
    if (type === 'select') {
      return 'dropdown';
    } else {
      return 'text';
    }
  }

  /**
   * Checks if the sample data table has user data
   * @param rows sample data table rows
   */
  public isSampleDataTableEmpty(rows: ISampleDataRow[]): boolean {
    return isEmpty(rows)
      || !rows.find(row => Object.keys(row).some(field => (field !== 'index') && !isEmpty(row[field])));
  }

  /**
   * For context (BASE-84415), EdgeOS can run on instrument or from a computer.
   * When EdgeOS is loaded on some instruments with no local file system (eg MiSeqi100Series), run planning should only
   *    allow downloading file to External Storage via Custom File Browser (CFB).
   * When EdgeOS is loaded on the computer for such instruments (i.e. MiSeqi100Series), run planning should give the option to
   *    allow downloading file to either External Storage or local file system.
   */
  public getDownloadTemplateOptions(isStandAlone: boolean, useInstrumentSpecificFileSystem: boolean, instrumentAgent: InstrumentAgentType)
    : DownloadTemplateOption[] {
    if (isStandAlone) { // currently only MiSeqi100Series and NovaSeqXSeries
      if (useInstrumentSpecificFileSystem) { // MiSeqi100Series
        switch (instrumentAgent) {
          case InstrumentAgentType.COMPUTER:
            return [DownloadTemplateOption.EXTERNAL_STORAGE, DownloadTemplateOption.LOCAL_STORAGE];
          case InstrumentAgentType.INSTRUMENT:
            return [DownloadTemplateOption.EXTERNAL_STORAGE];
          default:
            return [DownloadTemplateOption.EXTERNAL_STORAGE, DownloadTemplateOption.LOCAL_STORAGE];
        }
      } else { // NovaSeqXSeries
        return [DownloadTemplateOption.LOCAL_STORAGE];
      }
    } else {
      return [DownloadTemplateOption.LOCAL_STORAGE];
    }
  }

  /**
   * For standalone mode, importSamplesOptions are determined the same way as getDownloadTemplateOptions.
   * For non-standalone mode:
   *   - Single-analysis instruments allow import of either CSV or sample sheet file.
   *   - Multi-analysis instruments only allow CSV file for now. However, we allow the CSV file to come from either local storage
   *     or from BSSH storage (e.g. for Descartes project)
   */
  public getImportSamplesOptions(isStandAlone: boolean, useInstrumentSpecificFileSystem: boolean, instrumentAgent: InstrumentAgentType,
    isMultiAnalysis: boolean) : ImportSamplesOption[] {
    if (isStandAlone) { // currently only MiSeqi100Series and NovaSeqXSeries
      if (useInstrumentSpecificFileSystem) { // MiSeqi100Series
        switch (instrumentAgent) {
          case InstrumentAgentType.COMPUTER:
            return [ImportSamplesOption.EXTERNAL_STORAGE, ImportSamplesOption.LOCAL_STORAGE];
          case InstrumentAgentType.INSTRUMENT:
            return [ImportSamplesOption.EXTERNAL_STORAGE];
          default:
            return [ImportSamplesOption.EXTERNAL_STORAGE, ImportSamplesOption.LOCAL_STORAGE];
        }
      } else { // NovaSeqXSeries
        return [ImportSamplesOption.LOCAL_STORAGE];
      }
    } else {
      return [ImportSamplesOption.LOCAL_STORAGE, ImportSamplesOption.BSSH];
    }
  }

  /**
   * Get all subsample fields from the combination of colDefFields and nonColDefFields
   * colDefFields = Fields that are to be displayed in sample data table after collapsing the subsamples
   * nonColDefFields = Fields that are NOT to displayed, but already exist in sampleData (when loading a run or sample sheet)
   *   -> Example: project field in standalone mode (project column is not displayed, but GSS will auto-generate project for every run) 
   */
  public getAllSubSampleFields(columnDefs: InteractiveTableColDef[], sampleDataRows: ISampleDataRow[], subSamplesConfig: SubSamplesConfiguration) {
    const allSampleDataFields = uniq(union(...sampleDataRows.map(row => Object.keys(row))));
    const subSampleDefIds = subSamplesConfig.subSampleDefinitions.map(x => x.id);
    const colDefFields = columnDefs.map(x => x.field);
    const nonColDefFields = []

    for (const field of allSampleDataFields) {
      const fieldsToCheck = [field, ...subSampleDefIds.map(id => `${id}_${field}`)];
      if (intersection(colDefFields, fieldsToCheck).length === 0) {
        nonColDefFields.push(field);
      }
    }

    // DO NOT include sampleName, otherwise it will break the collapse/expand logic
    // for all other nonColDefFields, it should be ok to just map
    nonColDefFields.splice(nonColDefFields.findIndex(field => field === 'sampleName'), 1);
    
    return [...colDefFields, ...nonColDefFields];
  }

  /**
   * Map sampleDataRows from GSS payload to combined subSamples rows
   *
   * `columnDefinitions` (computed in previous rule) used as reference of column keys for subSample table
   * `sampleSettingsSnapshot` to identify assocFieldId(eg. Pair_ID) value from sampleName
   * `sampleData` original data from GSS
   * `sampleIdToSettingsMap` to add more data to `sampleData` from `sampleSettingsSnapshot`
   *
   * Refer to https://confluence.illumina.com/display/BS/Subsamples+in+Sample+Table for disambiguation of terms
   */
  public collapseSubSamples(
    // Data sources:
    sampleData: ISampleDataRow[],
    // Data definitions:
    subSamplesConfig: SubSamplesConfiguration,
    allSubSampleFields: string[],
    sampleSettingsFieldMap: { [key: string]: FormValueObject },
  ): ISampleDataRow[] {
    const assocFieldId = subSamplesConfig.associationFieldId; // Eg: 'Pair_ID'
    const sampleNameRegex = /(.+)_(.+)/;

    const assocIdToSampleDataMap: { [key: string]: ISampleDataRow } = {};
    for (const row of sampleData) {
      /**
       * EXAMPLE assocId = 'T001'
       * EXAMPLE subSampleId = 'dna'
       * EXAMPLE sampleName = 'T001_dna'
       */
      const arr = sampleNameRegex.exec(row.sampleName);
      const assocId = arr[1]; // Eg: "T001"
      const subSampleDefId = arr[2]; // Eg: 'dna', 'rna', ...
      if (!assocIdToSampleDataMap[assocId]) {
        assocIdToSampleDataMap[assocId] = {
          [assocFieldId]: assocId
        }
      };

      const parsedData = allSubSampleFields
        .reduce((parsedRowData, field) => {
          if (field.startsWith(`${subSampleDefId}_`)) {
            /**
             * EXAMPLE field = 'rna_index1Sequence'
             * EXAMPLE subSampleId = 'rna'
             * EXAMPLE originalFieldName = 'index1Sequence'
             */
            const originalFieldName = field.replace(`${subSampleDefId}_`, '');
            if (row.hasOwnProperty(originalFieldName)) {
              // add prefix to maintain field's relation to its subsample
              parsedRowData[field] = row[originalFieldName];
            }
          } else if (Object.keys(sampleSettingsFieldMap).includes(field)) {
            const supportedSubSampleIds = get(sampleSettingsFieldMap[field], 'settings.supportedSubSampleIds', []);
            // if field is subsample-specific, add prefix to maintain field's relation to its subsample.
            // else, just map using original field name
            const destField = isEmpty(supportedSubSampleIds) ? field : `${subSampleDefId}_${field}`;
            parsedRowData[destField] = row[field];
          } else if (row[field] !== undefined) {
            // add prefix to maintain field's relation to its subsample
            const destField = `${subSampleDefId}_${field}`;
            parsedRowData[destField] = row[field];
          }
          return parsedRowData;
        }, {});

        assocIdToSampleDataMap[assocId] = { ...assocIdToSampleDataMap[assocId], ...parsedData };
    }
    /**
     * Current use case: only one of the two arrays should have value
     * because only called on load planned run, import run or requeue, not at import samplesheet to append to existing table
     */
    return Object.values(assocIdToSampleDataMap);
  }
}
