import { Injectable } from '@angular/core';
import { GdsFileService, RESOURCE_TYPE_FOLDER_NAME } from '@app/cloud-run-prep/analysis-setup/services/gds-file.service';
import { CUSTOM_FILE_INDEX, STANDARD_FILE_INDEX } from '@app/cloud-run-prep/constants';
import { genericRetryWhen } from '@app/core/rxjsutils/rxjs-utilities';
import { LOCAL_FILE_NAME_PREFIX } from '@app/run-planning/constants';
import { SharedSpinnerService } from '@app/run-planning/services/shared-spinner/shared-spinner.service';
import { isEmpty, sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, finalize, flatMap, map, retryWhen } from 'rxjs/operators';
import { MapperService } from '@app/run-planning/services/mapper.service';
import {IFormField} from '@app/run-planning/model/form-field';
import {CustomOption, GroupedOption} from '@app/run-planning/model/option';

@Injectable({
  providedIn: 'root'
})
export class FileOptionsCacheService {
  // Key is combination of avd id and file id
  private fileOptionsCache: { [analysisVersionDefinitionIdFileId: string]: GroupedOption[] } = {};
  // Store local file in separate cache, to avoid getting cleared during render api
  private localFileOptionsCache: { [analysisVersionDefinitionIdFileId: string]: CustomOption[] } = {};

  constructor(
    private gdsFileService: GdsFileService,
    private mapper: MapperService,
    private sharedSpinnerService: SharedSpinnerService) { }

  /**
   * Read file options from cache, if not read from GDS and cache the option list
   */
  public getOptions$(analysisVersionDefinitionId: string, formField: IFormField): Observable<GroupedOption[]> {
    const fileOptionkey = this.generateFileOptionKey(analysisVersionDefinitionId, formField);
    if (!(fileOptionkey in this.fileOptionsCache)) {
      return this.loadAndCacheOptions$(analysisVersionDefinitionId, formField, fileOptionkey);
    }
    const groupedOptions = this.fileOptionsCache[fileOptionkey];    
    // Standard files are from field itself
    const standardChoices: CustomOption[] = this.mapper.mapFileOptions(formField.choices.filter(x => x.value !== 'custom'));
    // Replace standard file options in grouped option
    groupedOptions[STANDARD_FILE_INDEX].options = standardChoices;
    return of(groupedOptions);
  }

  /**
   * Store local file into cache service as original data
   */
  public uploadLocalFileAndUpdateCache(analysisVersionDefinitionId: string, fieldId: string, file: any): GroupedOption[] {
    const newOptionText = `${file.name} - (Local)`;
    const newOptionValue = this.getLocalFileValue(file.name);
    const newOption: CustomOption = { id: newOptionValue, value: newOptionValue, text: newOptionText, originalData: file };
    const fileOptionkey = this.generateFileOptionKey(analysisVersionDefinitionId, fieldId);
    const groupedChoices = this.fileOptionsCache[fileOptionkey];

    if (!groupedChoices[CUSTOM_FILE_INDEX].options) {
      groupedChoices[CUSTOM_FILE_INDEX].options = [];
    }

    // Clear previous uploaded local file if any
    const customFileIndex = groupedChoices[CUSTOM_FILE_INDEX].options.findIndex(x => x.value.includes(LOCAL_FILE_NAME_PREFIX));
    if (customFileIndex > -1) {
      groupedChoices[CUSTOM_FILE_INDEX].options.splice(customFileIndex, 1);
    }

    // Add new local file, and re-sort
    groupedChoices[CUSTOM_FILE_INDEX].options.push(newOption);
    groupedChoices[CUSTOM_FILE_INDEX].options = sortBy(groupedChoices[CUSTOM_FILE_INDEX].options, f => f.text);

    this.fileOptionsCache[fileOptionkey] = groupedChoices;

    // Update cached local file
    this.localFileOptionsCache[fileOptionkey] = [newOption];

    return groupedChoices;
  }

  public getLocalFileValue(fileName: string) {
    return `${LOCAL_FILE_NAME_PREFIX}${fileName}`;
  }

  /**
   * Upload cloud file and re-read the file list from gds to get the urn value and refresh cache
   */
  public uploadGdsFileAndUpdateCache$(analysisVersionDefinitionId: string, fieldId: string, file: any): Observable<GroupedOption[]> {
    this.sharedSpinnerService.startSpinner(true);
    const uploadFolderPath = `${analysisVersionDefinitionId}/${fieldId}`;
    const loadFolderPath = `/${RESOURCE_TYPE_FOLDER_NAME}/${analysisVersionDefinitionId}/${fieldId}`;
    const fileOptionkey = this.generateFileOptionKey(analysisVersionDefinitionId, fieldId);
    return this.gdsFileService.uploadCustomFile(uploadFolderPath, file).pipe(
      flatMap(() => this.gdsFileService.getFileListFromGds(loadFolderPath)),
      map(fileListResponse => {
        if (isEmpty(fileListResponse) || isEmpty(fileListResponse.items)) {
          throw new Error('Empty response');
        } else {
          const newlyUploadedFile = fileListResponse.items.find(item => item.name === file.name);
          if (!newlyUploadedFile) {
            throw new Error(`${file.name} was not found in the response`);
          } else {
            // Update cached file option list
            this.fileOptionsCache[fileOptionkey][CUSTOM_FILE_INDEX].options = this.mapper.mapFileOptions(fileListResponse.items);
            return this.fileOptionsCache[fileOptionkey];
          }
        }
      }),
      retryWhen(genericRetryWhen({ maxRetryAttempts: 5, retry500ErrorsOnly: false })),
      catchError(() => of(this.fileOptionsCache[fileOptionkey])),
      finalize(() => this.sharedSpinnerService.stopSpinner(true))
    );
  }

  /**
   * Return local File object
   */
  public getLocalSelectedFiles(analysisVersionDefinitionId: string, fieldId: string, fileNameValue: string) {
    if (!fileNameValue || !fileNameValue.startsWith(LOCAL_FILE_NAME_PREFIX)) {
      return null;
    }
    const fileOptionkey = this.generateFileOptionKey(analysisVersionDefinitionId, fieldId);
    if (fileOptionkey in this.fileOptionsCache && this.fileOptionsCache[fileOptionkey][CUSTOM_FILE_INDEX].options) {
      const selectedOption = this.fileOptionsCache[fileOptionkey][CUSTOM_FILE_INDEX].options.find(x => x.value === fileNameValue);
      if (selectedOption) {
        return selectedOption.originalData;
      }
    }
    return null;
  }

  // @ts-ignore
  private loadAndCacheOptions$(analysisVersionDefinitionId: string, formField: IFormField, fileOptionkey: string): Observable<GroupedOption[]> {
    // Standard files are from AVD
    const standardChoices: CustomOption[] = this.mapper.mapFileOptions(formField.choices);
    const customOptionIndex = standardChoices.findIndex(x => x.value === 'custom');
    const hasCustomOption = customOptionIndex > -1;

    if (hasCustomOption) {
      // Remove the custom option
      standardChoices.splice(customOptionIndex, 1);
      const folderPath = `/${RESOURCE_TYPE_FOLDER_NAME}/${analysisVersionDefinitionId}/${formField.id}`;
      // Set grouped files options
      const groupedOptions: GroupedOption[] = [{ groupName: 'Custom Files' }, { groupName: 'Standard Files', options: standardChoices }];
      return this.gdsFileService.getFileListFromGds(folderPath)
        .pipe(
          map(resp => {
            groupedOptions[CUSTOM_FILE_INDEX].options = this.mapper.mapFileOptions(resp.items);
            // Append local file options
            this.fileOptionsCache[fileOptionkey] = this.addLocalFileOptions(fileOptionkey, groupedOptions);;
            return this.fileOptionsCache[fileOptionkey];
          })
        );

    } else {
      // Only display standard options
      const groupedOption: GroupedOption = { groupName: 'Standard Files', options: standardChoices };
      this.fileOptionsCache[fileOptionkey] = [groupedOption];
      return of([groupedOption]);
    }
  }

  /**
   * Use combination of analysis version definition id and field id as key of file list cache
   */
  private generateFileOptionKey(analysisVersionDefinitionId: string, formField: IFormField | string): string {
    if (typeof formField === 'string') {
      return `${analysisVersionDefinitionId}_${formField}`;
    }
    return `${analysisVersionDefinitionId}_${formField.id}`;
  }

  /**
   * Clear local cache for the specified analysisVersionDefinitionId
   */
  public clearLocalFileOptions(analysisVersionDefinitionId: string) {
    for (const fileOptionsCacheKey in this.localFileOptionsCache) {
      if (fileOptionsCacheKey.startsWith(`${analysisVersionDefinitionId}_`)) {
        delete this.localFileOptionsCache[fileOptionsCacheKey];
      }
    }
  }

  /**
   * Clear cloud cache for the specified analysisVersionDefinitionId
   */
  public clearCloudFileOptions(analysisVersionDefinitionId: string) {
    for (const fileOptionsCacheKey in this.fileOptionsCache) {
      if (fileOptionsCacheKey.startsWith(`${analysisVersionDefinitionId}_`)) {
        delete this.fileOptionsCache[fileOptionsCacheKey];
      }
    }
  }

  /**
   * Append local file options from cache
   */
  private addLocalFileOptions(fileOptionsCacheKey, groupedFileOptions: GroupedOption[]): GroupedOption[] {
    const cachedLocalFiles = this.localFileOptionsCache[fileOptionsCacheKey];
    if (cachedLocalFiles && !isEmpty(cachedLocalFiles)) {
      // Add new local file, and re-sort
      cachedLocalFiles.forEach(fileOption => {
        groupedFileOptions[CUSTOM_FILE_INDEX].options.push(fileOption);
      });
      groupedFileOptions[CUSTOM_FILE_INDEX].options = sortBy(groupedFileOptions[CUSTOM_FILE_INDEX].options, f => f.text);
    }
    return groupedFileOptions;
  }
}
