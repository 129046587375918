import * as gss from '@stratus/gss-ng-sdk';
import {CreateSequencingRunConfigurationRequest} from '@app/run-planning/model/create-sequencing-run-configuration-request';
import {
  CreateSequencingRunAnalysisConfigurationRequest
} from '@app/run-planning/model/create-sequencing-run-analysis-configuration-request';
import {UpdateSequencingRunContentsRequest} from './update-sequencing-run-contents-request';
import {RunPlanningState} from '@app/run-planning/run-planning.state';
import {ConfigSetupState} from '@app/run-planning/config-setup/config-setup.state';
import {get, isEmpty, isNil, merge} from 'lodash';
import { removeEmpty } from '../constants';

export class RenderAnalysisVersionDefinitionRequest implements gss.RenderAnalysisVersionDefinitionRequest {
  context: gss.RenderContext;
  loadDefaultSettingValuesFlag: boolean;
  currentAnalysisSampleSettings: {};
  currentAnalysisSettings: {};
  fieldId: string;
  sampleFieldId: string;
  runAnalysisConfiguration: CreateSequencingRunAnalysisConfigurationRequest;
  runConfiguration: CreateSequencingRunConfigurationRequest;
  runContents: UpdateSequencingRunContentsRequest;

  constructor(
    runPrepState: RunPlanningState, configSetupState: ConfigSetupState,
    changedFieldId?: string, analysisSetupFormValues?: any, physicalAndLogicalConfigValues?: any) {

    const onRenderRequireRunContents = configSetupState.rawAnalysisVersionDefinition.onRenderRequireRunContents || false;
    const isAnalysisConfigurationChanged = !isEmpty(changedFieldId);

    this.fieldId = changedFieldId ? changedFieldId : undefined;
    this.runConfiguration = new CreateSequencingRunConfigurationRequest(runPrepState.runSettings);
    
    // Config states in the run prep state may not be latest due to changes may not have been propergated
    const latestConfigs = [...runPrepState.configSetups];
    latestConfigs[configSetupState.configIndex] = configSetupState;

    this.runContents = onRenderRequireRunContents
      ? new UpdateSequencingRunContentsRequest(latestConfigs, runPrepState.runSettings.isMultiAnalysis)
      : null;
    const runAnalysisConfiguration = CreateSequencingRunAnalysisConfigurationRequest.fromConfigSetup(configSetupState);

    if (analysisSetupFormValues) {
      merge(runAnalysisConfiguration.settings, analysisSetupFormValues);
    }

    // if user has not entered any value yet (saved or not saved), make it initial context
    if (isEmpty(configSetupState.rawRenderAnalysisVersionDefinitionResponse)
        && isEmpty(configSetupState.rawRenderResponseAnalysisSetting)) {
      runAnalysisConfiguration.sampleSettings = (configSetupState.perSampleSettingsData || [])
        .map(x => ({ sampleId: x.sampleId, settings: x.perSampleSettings }));
    }

    if (!physicalAndLogicalConfigValues) {
      physicalAndLogicalConfigValues = merge({}, configSetupState.physicalConfigValues, configSetupState.logicalConfigValues);
    }

    for (const sampleSetting of runAnalysisConfiguration.sampleSettings) {
      merge(sampleSetting.settings, physicalAndLogicalConfigValues);
    }

    // if there is no sample information entered yet (for physical & logical fields' updateRenderOnChange), remove sampleId
    if ((runAnalysisConfiguration.sampleSettings.length === 1)
      && isEmpty(runAnalysisConfiguration.sampleSettings[0].sampleId)) {
      delete runAnalysisConfiguration.sampleSettings[0].sampleId;
    } else if ((runAnalysisConfiguration.sampleSettings.length === 0)
      && !isEmpty(physicalAndLogicalConfigValues)) {
      // @ts-ignore
      runAnalysisConfiguration.sampleSettings = [ { settings: physicalAndLogicalConfigValues } ];
    }

    const emptyRunAnalysisSettings = isEmpty(runAnalysisConfiguration.settings);
    const emptyRunAnalysisSampleSettings = isEmpty(runAnalysisConfiguration.sampleSettings);

    const isCurrentAnalysisConfigurationEmpty = emptyRunAnalysisSettings && emptyRunAnalysisSampleSettings;

    this.context = isAnalysisConfigurationChanged
      ? gss.RenderContext.FIELD_CHANGED
      : isCurrentAnalysisConfigurationEmpty
        ? gss.RenderContext.INITIAL
        : gss.RenderContext.EDITED;
    
    if (this.context === gss.RenderContext.EDITED && (emptyRunAnalysisSettings ? !emptyRunAnalysisSampleSettings : emptyRunAnalysisSampleSettings)) {
      this.loadDefaultSettingValuesFlag = true;
    } else {
      this.loadDefaultSettingValuesFlag = false;
    }

    // // Return analysisSettings from previous call to render avd api
    this.currentAnalysisSettings =
      get(configSetupState, 'rawRenderAnalysisVersionDefinitionResponse.analysisVersionDefinition.analysisSettings')
      || get(configSetupState, 'rawRenderResponseAnalysisSetting');

    this.currentAnalysisSampleSettings =
      get(configSetupState, 'rawRenderAnalysisVersionDefinitionResponse.analysisVersionDefinition.analysisSampleSettings')
      || get(configSetupState, 'rawRenderResponseAnalysisSampleSetting');

    // For changes in analysisSettingForm, use fieldId
    // For changes in physical/logical config, use sampleId
    if (!isNil(this.fieldId)) {
      const fields = get(this, 'currentAnalysisSettings.fields', []);
      const isAnalysisSettingsFieldChanged = fields.some(f => f.id === this.fieldId);
      if (!isAnalysisSettingsFieldChanged) {
        this.sampleFieldId = this.fieldId;
        this.fieldId = undefined;
      }
    }

    this.runAnalysisConfiguration = runAnalysisConfiguration;
    removeEmpty(this);
  }
}
