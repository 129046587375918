import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import {
  tap,
  first,
  pairwise,
  map,
  distinct,
  finalize,
  concatMap,
} from 'rxjs/operators';
import { GroupedOption } from '@app/run-planning/model/option';
import { ComboBoxComponent } from '@app/cloud-run-prep/directives/combobox/combobox.component';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { IDynamicLoadingComponent } from '@app/run-planning/interface';
import { NUM_DROPDOWN_OPTIONS_LIMIT } from '@app/run-planning/constants';
import { HelperService } from '@app/run-planning/services/helper.service';
import { ModalService } from '@app/shared/modals/services/modal.service';
import {
  ReferenceFileModalComponent
} from '@app/shared/modals/custom-kits/reference-file-modal/reference-file-modal.component';
import {
  IAlertModalInput,
  IAlertModalOutput,
  IFileUploadModalInput,
  IFileUploadProgressModalOutput,
  IReferenceFileModalInput
} from '@app/shared/modals/model/action-modal';
import { ToastrService } from '@bssh/comp-lib';
import {
  FileToReplace,
  ReferenceFileUploadService
} from '@app/resources/reference-file/service/reference-file-upload.service';
import { AlertModalType } from '@app/shared/modals/alert-modal/alert-modal-texts';
import { AlertModalComponent } from '@app/shared/modals/alert-modal/alert-modal.component';
import { IReferenceFile } from '@models/settings/reference-file';
import { ReferenceFileMapperService } from '@app/core/services/mapper/reference-file-mapper.service';
import {get, union} from 'lodash';
import {
  FileUploadProgressModalComponent
} from '@app/shared/modals/file-upload-progress-modal/file-upload-progress-modal.component';
import { SharedSpinnerService } from '@app/run-planning/services/shared-spinner/shared-spinner.service';
import { ReferenceFilesService } from '@stratus/gss-ng-sdk';
import { DirectUploadInfo } from '@app/shared/ica-file-upload-service';

@Component({
  selector: 'app-reference-file-dropdown',
  templateUrl: './reference-file-dropdown.component.html',
  styleUrls: ['./reference-file-dropdown.component.scss']
})
export class ReferenceFileDropdownComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, IDynamicLoadingComponent {
  /* Reference File form control Id in the formGroup */
  @Input() referenceFileFormControlName: string;
  /* Analysis form field settings field used as filter parameter to fetch the options */
  @Input() referenceFileGenomeFilterFieldId: string;
  @Input() genomeFilterGenomeId: string;
  /* Analysis form field settings field used as filter parameter to fetch the options */
  @Input() referenceFileTypeFilter: string;
  /* Hash table version of genomes allowed for the selected application, defined in AVD return from render call */
  @Input() genomeHashTableVersion: string;
  @Input() analysisVersionDefinitionId: string;
  /* Analysis form field required field, if optional, options will include clear field */
  @Input() isRequired = false;
  @Input() disableReferenceFileUpload: boolean;
  referenceFileOptions: GroupedOption[] = [];

  @Input() newReferenceFileName$: Observable<string>;
  @Output() newReferenceFileAdded = new EventEmitter<{
    formControlName: string,
    fileName: string,
    referenceFileId: string,
  }>();

  private loadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.loadingSubject.asObservable();

  @ViewChild('referenceFileSelectCombobox', { static: true })
  referenceFileSelectCombobox: ComboBoxComponent;

  private depedentGenomeIdSubject = new BehaviorSubject<string>('');
  private dependentGenomeId$: Observable<string> = this.depedentGenomeIdSubject.asObservable();

  get parentFormGroup(): FormGroup { return this.controlContainer.control as FormGroup; }
  get refFileFormControl() { return this.parentFormGroup.get(this.referenceFileFormControlName); }

  private subs = new SubSink();
  private cachedValue;

  constructor(
    private controlContainer: ControlContainer,
    private helper: HelperService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private referenceFileUploadService: ReferenceFileUploadService,
    private fileMapper: ReferenceFileMapperService,
    private sharedSpinnerService: SharedSpinnerService,
    private referenceFilesService: ReferenceFilesService,
  ) { }

  ngOnInit() {
    // Initialize dropdown options
    this.referenceFileOptions = this.getEmptyOptions();
    this.subscribeToOptionsDependencies();

    this.subs.sink = this.isLoading$.pipe(pairwise()).subscribe(([preIsLoading, currentIsLoading]) => {
      if (preIsLoading != currentIsLoading) {
        // Only cache/refetch the value when loading status changed
        if (currentIsLoading) {
          // To cache the value while it begins to load
          this.cachedValue = this.refFileFormControl.value;
          this.clearSelection();
        } else if (this.cachedValue) {
          // If have any cached value, patch back and clear the cache
          this.refFileFormControl.patchValue(this.cachedValue);
          this.cachedValue = undefined;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('genomeFilterGenomeId')) {
      this.depedentGenomeIdSubject.next(changes.genomeFilterGenomeId.currentValue);
    }
  }

  /**
   * Fetch the options list whenever the specified genomeFilterField value changes
   * and/or parent dynamic-settings-form component request for re-update due to new reference file addded
   */
  private subscribeToOptionsDependencies() {
    /**
     * Notes on the 2 observables to combine:
     *
     * `this.dependentGenomeId$`: When a different genome value is selected on the dependent field
     * `this.newReferenceFileName$`: When a new reference file is added via popup modal,
     *    emits the filename of the added file to all reference file dropdowns.
     * `this.referenceFileGenomeFilterFieldId` is null if this field has no dependency on a genome.
     *    --> If null, then just getOptions$ without genome id
     *    --> If not null, but genomeId returned is null, then return empty options (as per previous implementation)
     */
    this.subs.sink = combineLatest(this.dependentGenomeId$, this.newReferenceFileName$)
      .pipe(
        distinct(),
        tap(() => this.loadingSubject.next(true)),
        concatMap(([genomeId, _]) => {
          if (!this.referenceFileGenomeFilterFieldId) {
            // This field has no genome dependency
            return this.getOptions$(null, this.referenceFileTypeFilter);
          } else {
            // Only getOptions when user has selected the dependent genome
            return genomeId ? this.getOptions$(genomeId, this.referenceFileTypeFilter) : of(this.getEmptyOptions());
          }
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe({
        next: groupedOptions => {
          this.referenceFileOptions = groupedOptions;
          this.loadingSubject.next(false);
        },
        error: error => console.error(error),
      });
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  clearSelection() {
    this.controlContainer.control.get(this.referenceFileFormControlName).patchValue(null);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getOptions$(genomeId: string, fileType: string) {
    const requestParams = {
      genomeId,
      type: fileType,
      sort: 'displayName',
      pageSize: NUM_DROPDOWN_OPTIONS_LIMIT
    };
    return this.referenceFilesService.listReferenceFiles(requestParams)
      .pipe(
        map(pagedItems => pagedItems.items.map(x => this.helper.mapToCustomOption(x))),
        first(),
        map(allCustomOptions => this.helper.groupByCustomAndStandardOptions(allCustomOptions, 'Reference Files'))
      );
  }

  private getEmptyOptions() {
    return this.helper.groupByCustomAndStandardOptions([], 'Reference Files');
  }

  /**
   * The page will be blocked by
   * - loading spinner during
   *    1. filename validation
   *    2. gettting existing file with the same name
   * - progress modal during file upload
   */
  async handleAddNewReferenceFile(event) {
    this.sharedSpinnerService.startSpinner();
    const importedFile = (event.target as HTMLInputElement).files[0];
    if (!this.isFileNameValid(importedFile.name)) {
      event.target.value = '';
      this.toastr.error('Invalid name for imported reference file:'
        + ' Only alphanumerics, period, dash, and underscore characters are allowed.'
        + ' The name must start with alphanumeric, dash, or underscore.');
      this.sharedSpinnerService.stopSpinner();
      return;
    }

    const existingFileDetail = await this.getExistingFileDetail(importedFile.name);
    this.sharedSpinnerService.stopSpinner();
    if (existingFileDetail) {
      const willProceed = await this.showAlertModal('ADD_DUPLICATE_REFERENCE_FILE').toPromise();
      if (!willProceed) {
        event.target.value = '';
        return;
      }
    }
    try {
      this.sharedSpinnerService.startSpinner();
      const directUploadInfo = await this.referenceFileUploadService.getDirectUploadInfo();
      this.sharedSpinnerService.stopSpinner();
      this.openUploadProgressModal(importedFile);
      /**
       * Note that there is potential for race condition (not observed yet) where progress modal is opened
       * but this.runningUploads in S3DireectUploadService is not set yet.
       *
       * Maintaining this implementation to follow the existing design of the progress bar modal in ACT page
       */
      let { dataLocationUri, fileName, displayName, referenceFileId, supportedGenomeIds } = await this.getReferenceFile(importedFile, existingFileDetail, directUploadInfo);
      this.modalService.closeModal();
      if (this.genomeFilterGenomeId) {
        supportedGenomeIds = union(supportedGenomeIds, [this.genomeFilterGenomeId]);
      }
      this.openMetadataModal(dataLocationUri, fileName, displayName, referenceFileId, supportedGenomeIds, this.analysisVersionDefinitionId);
    } catch (err) {
      if (err.name === 'RequestAbortedError') {
        this.toastr.info('Upload cancelled.');
      } else {
        if (err.name === 'TimeoutError') {
          this.toastr.error('File failed to be imported due to timeout.');
        } else {
          const errorMessage = get(err, 'error.message', '');
          const errorDetails = get(err, 'error.details', []);

          if (errorMessage && errorDetails.length > 0
            && Object.values(errorDetails[0]).length > 0) {
            this.toastr.error(`${errorMessage}: ${Object.values(errorDetails[0])[0]}`);
          } else {
            this.toastr.error(`Failed to import file. ${errorMessage}`);
          }
        }
        this.modalService.closeModal();
        throw err;
      }
    } finally {
      event.target.value = '';
    }
  }

  private isFileNameValid(name: string): boolean {
    const matches = name.match(/^[\w\-]+[\w\-\.]*/);
    return matches && matches[0] === name;
  }

  private async getExistingFileDetail(filename: string): Promise<FileToReplace> {
    const existingFilesPage = await this.referenceFileUploadService.getReferenceFiles(filename);
    let existingFileDetail;
    if (existingFilesPage.items && existingFilesPage.items.length > 0) {
      const existingFile = existingFilesPage.items[0];
      existingFileDetail = { name: existingFile.name, fileId: existingFile.id };
    }
    return existingFileDetail;
  }

  private showAlertModal(type: AlertModalType): Observable<boolean> {
    const modalInput: IAlertModalInput = {type};
    return this.modalService.openModal(modalInput, AlertModalComponent).confirm.pipe(
      map((modalOutput: IAlertModalOutput) => modalOutput.isProceed)
    );
  }

  private async getReferenceFile(importedFile: File, existingFileDetail: FileToReplace, directUploadInfo: DirectUploadInfo): Promise<IReferenceFile> {
    const uploadedFile = await this.referenceFileUploadService
      .uploadOrReplaceReferenceFile(importedFile, directUploadInfo, existingFileDetail);
    const referenceFile = await this.getReferenceFileFullResponse(uploadedFile.id);
    return this.fileMapper.mapApiItemsToDataSourceModel(referenceFile);
  }

  private openMetadataModal(dataLocationUri, fileName, displayName, referenceFileId, supportedGenomeIds, analysisVersionDefinitionId) {
    const data: IReferenceFileModalInput = {
      fileType: this.referenceFileTypeFilter,
      dataLocationUri,
      fileName,
      displayName,
      referenceFileId,
      supportedGenomeIds,
      analysisVersionDefinitionId,
    };
    if (this.genomeHashTableVersion) {
      data.genomeHashTableVersion = this.genomeHashTableVersion;
    }
    this.modalService.openModal(data, ReferenceFileModalComponent).confirm.subscribe({
      next: confirmed => {
        if (confirmed) {
          this.newReferenceFileAdded.emit({
            formControlName: this.referenceFileFormControlName,
            fileName,
            referenceFileId,
          });
        }
      },
    });
  }

  private openUploadProgressModal(file: File) {
    const modalInput: IFileUploadModalInput = {
      fileName: file.name,
      totalSizeInBytes: file.size,
      uploadedSizeInBytes: 0,
    };

    this.subs.sink = this.modalService.openModal(modalInput, FileUploadProgressModalComponent).confirm.pipe(
      map((modalOutput: IFileUploadProgressModalOutput) => modalOutput.isCancel),
    ).subscribe((isCancel) => {
      if (isCancel) {
        this.referenceFileUploadService.cancelUpload(file);
      }
    });

    this.subs.sink = this.referenceFileUploadService.bytesLoaded$.subscribe(uploadedSize => {
      modalInput.uploadedSizeInBytes = uploadedSize;
      this.modalService.setData(modalInput);
    });
  }

  private async getReferenceFileFullResponse(id: string) {
    return this.referenceFilesService.getReferenceFile({ referenceFileId: id }).toPromise();
  }
}
