import {Injectable, SimpleChanges} from '@angular/core';
import {GroupedOption} from '@app/core/store/cloud-run-prep/run-setup.state';
import {FormGroup, FormArray} from '@angular/forms';
import { FileOptionsCacheService } from '@app/run-planning/services/file-options-cache/file-options-cache.service';
import {Observable} from 'rxjs';
import {filter, map, pairwise, startWith} from 'rxjs/operators';
import {isEmpty, get, find, isObject, isEqual, pickBy, difference} from 'lodash';
import {ErrorResponse, LaneContentResponse} from '@stratus/gss-ng-sdk';
import {RunSetupHelperService} from '@app/cloud-run-prep/run-setup/helper/run-setup-helper.service';
import {IFormField} from '@app/run-planning/model/form-field';
import { GssApiService } from './gss-api-service';
import { NUM_DROPDOWN_OPTIONS_LIMIT } from '../constants';
import { ISampleDataRow, RepeatSamplesAcrossLanesState } from '../interface';
import { ToastrService } from '@bssh/comp-lib';

@Injectable({
  providedIn: 'root'
})

export class HelperService extends RunSetupHelperService {
  constructor(
    private gssApiService: GssApiService,
    private fileOptionsCacheService: FileOptionsCacheService,
    private toastrService: ToastrService
  ) {
    super();
  }

  public getOptionDisplayName(value: string, options: GroupedOption[]) {
    const joinedOptions = [].concat.apply([], options.map(x => x.options));
    const selectedOption = joinedOptions.find(x => x.value === value);
    return selectedOption ? selectedOption.text : '';
  }

  public getDisplayNameForField(field: IFormField, value, analysisVersionDefinitionId: string = '',
      hashTableVersion: string = null): string {
    if (!value) {
      // When no value, display name should be empty as well
      return '';
    }

    let displayText = value;
    if (field.type === 'genome' && analysisVersionDefinitionId) {
      const requestParams = {
        analysisVersionDefinitionId,
        pageSize: NUM_DROPDOWN_OPTIONS_LIMIT,
        hashTableVersion
      };
      this.gssApiService.listGenomes(requestParams).subscribe(options => {
        const item = options.items.find(x => x.id === value);
        displayText = item ? item.displayName : '';
      });
      return displayText;
    } else if (field.type === 'fileSelect' && analysisVersionDefinitionId) {
      this.fileOptionsCacheService.getOptions$(analysisVersionDefinitionId, field).subscribe(options => {
        displayText = this.getOptionDisplayName(value, options);
      });
      return displayText;
    } else {
      if (field.type === 'select' || field.type === 'radio') {
        const selectedChoice = (field.choices || []).find(x => x && x.value === value);
        if (selectedChoice) {
          displayText = selectedChoice.text;
        }
      }
      return displayText;
    }
  }

  /***
   * Based on 'fields', set missing properties in 'values' with empty string
   */
  public setMissingValuesToEmpty(values: any, fields: any[]) {
    if (isEmpty(fields)) {
      return;
    }

    values = values || {};
    for (const field of fields) {
      if (!(field.id in values)) {
        values[field.id] = '';
      }
    }
  }

  /**
   * Re-calculates the value and validation status of the entire controls tree.
   */
  public updateTreeValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.updateTreeValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });
    group.updateValueAndValidity({onlySelf: true, emitEvent: false});
  }

  // Check if kit or genome is from illumina
  public isIlluminaKit(kit: any): boolean {
    return kit.isIllumina || (kit.organization || '').toUpperCase() === 'ILLUMINA' || kit.id === 'Unspecified';
  }

  public isRunningWithinIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public notifySharedBclChange(bclChanges: SimpleChanges, isReplaceArchivedBcl = false) {
    if (!bclChanges) {
      return;
    }
    const bclVersionChange = bclChanges['version'];
    
    // only show notification if implicit BCL was already present and the version has changed
    if (bclVersionChange.previousValue && bclVersionChange.previousValue !== bclVersionChange.currentValue) {
      let message = '';
      message = `The shared BCL Convert version has been updated from ${bclVersionChange.previousValue} to ${bclVersionChange.currentValue} `;
      if (isReplaceArchivedBcl) {
        message += 'as the previous version has been archived.';
      } else {
        message += 'due to the change in the configurations.'
      }

      const bclSettingsChange = bclChanges['settings'];
      const bclSettingsDifferenceIds = difference(bclSettingsChange.previousValue.map(field => field.id),
                                                  bclSettingsChange.currentValue.map(field => field.id));
      if (!isEmpty(bclSettingsDifferenceIds)) {
        const bclSettingsDifferenceFields = bclSettingsDifferenceIds.map(id => bclSettingsChange.previousValue.find(field => field.id === id));
        const bclSettingsDifferenceLabels = bclSettingsDifferenceFields.map(field => field.label ? `"${field.label}"` : `"${field.id}"`);
        message += `<br /><br />The following settings are not supported by the current version:
          ${bclSettingsDifferenceLabels.join(', ')}`
      }

      this.toastrService.info(message, null, { disableTimeOut: true, closeButton: true });
    }
  }
}

/**
 * Extracts the state changes by comparing the current state to the previous state
 * @param initialState used to compare the changes made on the very first change
 */
export const onlyChangedValues = (initialState?: {}) => (source$: Observable<any>) => {
  initialState = initialState || {};
  return source$.pipe(
    startWith(initialState),
    pairwise(),
    map(([oldState, newState]) => getDifference(oldState, newState)),
    filter(changes => !isEmpty(changes))
  );
};

/*
  Find the difference between a and b.
  Calculated as: b-a.
  Undefined values in b is considered as "does not exist"
 */
export const getDifference = (a, b) => {
  // Deep comparison for every field is expensive
  // Only do deep comparison for certain field(s)
  const PROPERTIES_NEED_DEEP_COMPARISON  = ['overrideCycles', 'physicalConfigValues', 'logicalConfigValues'];
  return Object.entries(b).filter(([key, val]) =>
    // Avoid override cycles being marked as different
    !(a[key] === val || (PROPERTIES_NEED_DEEP_COMPARISON.includes(key) && isEqual(a[key], val))) && (val !== undefined))
    .reduce((a, [key, v]) => ({ ...a, [key]: v }), {});
}

/**
 * Extracts the 'RunContentError' GSS error detail type and formats it into an array of user friendly error message strings
 */
export function mapGSSApiRunContentErrorDetails(error: ErrorResponse): string[] {
  const details = error.details;
  if (!details) { return []; }
  // tslint:disable:no-string-literal
  const runContentErrorDetails = details.filter(x => get(x, 'Type') === 'RunContentError')
    .map(x => {
      const detailedErrorMessage = x['ErrorMessage'];
      if (x['AppliesToAllSamples']) {
        return detailedErrorMessage;
      }
      return `Lane ${x['LaneNumber']}, Sample '${x['SampleName']}': ${detailedErrorMessage}`;
    });
  return runContentErrorDetails;
  // tslint:enable:no-string-literal
}

export function getErrorMessage(error: ErrorResponse, excludeRunContentError: boolean = true): string[] {
  const details = error.details;
  if (!details) { return [error.message]; }
  // tslint:disable:no-string-literal
  const errorDetailsToExtractErrorMessage = excludeRunContentError ? details.filter(x => get(x, 'Type') !== 'RunContentError') : details;
  const errMsg = extractErrMessage(errorDetailsToExtractErrorMessage);
  return !isEmpty(errorDetailsToExtractErrorMessage) && isEmpty(errMsg) ? [error.message] : errMsg;
}

/**
 * Get in-deep error message
 */
export function extractErrMessage(error) {
  if (isEmpty(error)) { return []; }
  return Object.entries(error).reduce((message, [key, value]) =>
    (key === 'ErrorMessage')
    ? message.concat(value)
    : (typeof value === 'object') ? message.concat(extractErrMessage(value)) : message, []);
}

/**
 * Default merge function will combine object, instead of replacing
 * e.g.
 * var object = { 'physicalFormFields': {'field1': 1} };
 * var change = { 'physicalFormFields': {'field2': 2} };
 * merge(object, change);
 * result will be => { 'physicalFormFields':  {'field1': 1, 'field2': 2} }
 * However what we want is { 'physicalFormFields': {'field2': 2} }
 */
export function customizedMerge(objValue, srcValue) {
  if (isObject(srcValue)) {
    return srcValue;
  }
}

export function getDefaultValues(fields: any[]): any {
  if (isEmpty(fields)) {
    return {};
  }
  const defaultValues = {};
  for (const field of fields) {
    const defaultValue = getDefaultValue(field);
    if (defaultValue !== '') {
      defaultValues[field.id] = defaultValue;
    }
  }
  return defaultValues;
}

export function getDefaultValue(field) {
  let defaultValue: any = '';
  if (field.type === 'radio' || field.type === 'checkbox' || field.type === 'select') {
    // if field is optional, ignore default value returned by GSS
    if (field.required) {
      const selectedChoice = find(field.choices, 'selected');
      defaultValue = get(selectedChoice, 'value') || '';
    }
  } else if (field.value) {
    defaultValue = field.value;
  }

  // set value for required checkbox, if it has no value
  if ((field.type === 'checkbox') && field.required && (defaultValue === '')) {
    defaultValue = false;
  }
  return defaultValue;
}

// Return only the changes form need to update
export function formValuesToUpdate(currentFormGroupValues, newValues) {
  return pickBy(newValues, (value, key) => {
    return (key in currentFormGroupValues) && !isEqual(value, currentFormGroupValues[key]);
  })
}

// Set all controls within form group as pristine
export function markFormAsPristine(formGroup) {
  for(const control in formGroup.controls) {
    formGroup.controls[control].markAsPristine();
  }
}

/**
 * Update the checked/unchecked status of the lane usage checkbox based on the run content loaded or imported
 * If lane info is absent (or equal to 0) for all samples, auto-check the checkbox (which will hide the lane column)
 * Should only update when the checkbox is NOT hidden
 */
export function getRepeatSamplesAcrossLanesStateFromContent(laneContent: Array<ISampleDataRow|LaneContentResponse>, currentState: RepeatSamplesAcrossLanesState) {
  if (currentState !== RepeatSamplesAcrossLanesState.HIDDEN) {
    return laneContent.every(lane => !lane.laneNumber) ? RepeatSamplesAcrossLanesState.CHECKED : RepeatSamplesAcrossLanesState.UNCHECKED;
  }
  return RepeatSamplesAcrossLanesState.HIDDEN;
}

/**
 * Returns true (is empty) if:
 * - null
 * - undefined
 * - empty string
 * - empty array
 * - empty object
 */
export function isValueEmpty(value: any) {
  if (value === undefined || value === null || value === '') {
    return true;
  }
  if (Array.isArray(value) || typeof value === 'object') {
    return isEmpty(value);
  }
}
