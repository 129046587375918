import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {S3DirectUploadService} from '@app/cloud-run-prep/analysis-setup/services/s3-direct-upload.service';
import {catchAndThrowGSSApiError, genericRetryWhen} from '@app/core/rxjsutils/rxjs-utilities';
import {CreateReferenceFileRequest, ReferenceFile, ReferenceFilesService, UpdateReferenceFileRequest} from '@stratus/gss-ng-sdk';
import {from} from 'rxjs';
import {flatMap, retryWhen} from 'rxjs/operators';
import {DirectUploadInfo, IcaFileUploadService, IcaFolderType} from '@app/shared/ica-file-upload-service';
import {DataLocationUri} from '../data-location-uri';
import {
  ReferenceFileCompactReferenceFileSortFieldsPagedItems
} from '@stratus/gss-ng-sdk/api/models/reference-file-compact-reference-file-sort-fields-paged-items';

export interface FileToReplace {
  fileId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceFileUploadService extends IcaFileUploadService {
  static readonly REFERENCE_FILE_CUSTOM_FOLDER = 'custom';

  protected constructor(
    httpClient: HttpClient,
    s3FileUploadService: S3DirectUploadService,
    private referenceFilesService: ReferenceFilesService) {
    super(IcaFolderType.ReferenceFiles, httpClient, s3FileUploadService);
  }

  /**
   * Returns the reference files with the given file name.
   */
  public async getReferenceFiles(fileName?: string): Promise<ReferenceFileCompactReferenceFileSortFieldsPagedItems> {
    return this.referenceFilesService.listReferenceFiles({name: fileName}).toPromise();
  }

  /**
   * get direct upload info, including temporary upload credentials
   */
  public async getDirectUploadInfo(): Promise<DirectUploadInfo> {
    return super.getDirectUploadInfo(ReferenceFileUploadService.REFERENCE_FILE_CUSTOM_FOLDER);
  }

  /**
   * Creates a new reference file and add metadata to GSS.
   */
  public async uploadOrReplaceReferenceFile(file: File, info: DirectUploadInfo, fileToReplace?: FileToReplace): Promise<ReferenceFile> {
    const eTag = await this.uploadFile(info.tempUploadCredential, file);
    return this.onUploadCompleted(info, file, eTag, fileToReplace);
  }

  /**
   * On upload completed, need to close upload session and get the file urn from ICA
   * File metadata on GSS is only created for new file
   * @returns file as in IReferenceFile format
   */
  private async onUploadCompleted(info: DirectUploadInfo, file: File, tag: string, fileToReplace?: FileToReplace): Promise<ReferenceFile> {
    const uploadedFileNames = [file.name];
    const uploadedFiles = await super.completeUploadSession(info.uploadSessionId,
      ReferenceFileUploadService.REFERENCE_FILE_CUSTOM_FOLDER, uploadedFileNames);
    const uploadedFile = uploadedFiles[0];

    // Post request to create gss reference file, using urn instead of dataLocationUri
    if (fileToReplace) {
      // Returns file ID to replace if a file with the same name already exists
      const referenceFileId = fileToReplace.fileId;
      // Update eTag and file size in bytes in GSS
      return this.updateReferenceFileMetaDataOnGss(referenceFileId, tag, file.size);
    } else { // Create new metadata on GSS
      const fileNameWithoutSpace = file.name.replace(/ /g, '_');

      let urnOrUri = null;
      if (uploadedFile.data.urn) {
        urnOrUri = uploadedFile.data.urn;
      } else {
        urnOrUri = new DataLocationUri(info.projectId, uploadedFile.data.id, fileNameWithoutSpace).toString();
      }

      if (!urnOrUri) {
        throw new Error('Unable to retrieve DataLocationUrn/Uri for the reference file.');
      }

      return this.createReferenceFileMetaDataOnGss(fileNameWithoutSpace, urnOrUri, tag, file.size)
        .catch (err => {
          // try to delete the uploaded file (deletion failure is not handled)
          this.deleteData(uploadedFile.data.id)
            .catch(deleteErr => {
              console.error(deleteErr);
            });

          // throw original error to the caller
          throw err;
        });
    }
  }

  /**
   * Construct request and post to GSS to create reference file metadata
   */
  private async createReferenceFileMetaDataOnGss(fileName: string, dataLocationUri: string, tag: string, fileSizeInBytes: number)
    : Promise<ReferenceFile> {
    const request: CreateReferenceFileRequest = {
      name: fileName, dataLocationUri,
      checksum: tag, fileSizeInBytes
    };
    return this.referenceFilesService.createReferenceFile(request).toPromise();
  }

  /**
   * Construct request and patch to GSS to update reference file etag and file size
   */
  private async updateReferenceFileMetaDataOnGss(referenceFileId: string, tag: string, fileSizeInBytes: number)
    : Promise<ReferenceFile> {
    const updateReferenceFileRequest: UpdateReferenceFileRequest = {
      checksum: tag, fileSizeInBytes
    };
    return this.referenceFilesService.updateReferenceFile(
      {referenceFileId, body: updateReferenceFileRequest})
      .toPromise();
  }

  /**
   * Delete reference-file's metadata (in GSS) and content (in ICA)
   */
  public async deleteReferenceFile(referenceFileId: string, dataId: string): Promise<any> {
    return this.referenceFilesService.deleteReferenceFile({referenceFileId}).pipe(
      retryWhen(genericRetryWhen()),
      flatMap(() => from(super.deleteData(dataId))),
      catchAndThrowGSSApiError
    ).toPromise();
  }
}
