import * as gss from '@stratus/gss-ng-sdk';
import {LaneContentResponse} from '@root/node_modules/@stratus/gss-ng-sdk/api/models/lane-content-response';
import {LaneLibraryResponse} from '@root/node_modules/@stratus/gss-ng-sdk/api/models/lane-library-response';
import {assign, chain, get, isEmpty} from 'lodash';
import {ISampleDataRow} from '@app/run-planning/interface';
import {LibraryPrepKit} from '@app/run-planning/model/library-prep-kit';
import {IndexAdapterKit} from '@app/run-planning/model/index-adapter-kit';
import { RunPlanningState } from '../run-planning.state';

export class SequencingRunContentsResponse implements gss.SequencingRunContentsResponse {

  // TODO this is probably not the right place for this constant
  public static readonly MULTI_LANE_GROUPING_KEYS: Array<string> = [
    'sampleName', 'index1Sequence', 'index2Sequence', 'barcodeMismatchRead1', 'barcodeMismatchRead2'
  ];

  laneContentsResponse?: Array<LaneContentResponse>;
  laneLibraries?: Array<LaneLibraryResponse>;

  constructor(obj?: gss.SequencingRunContentsResponse) {
    if (!obj) {
      return;
    }

    assign(this, obj);
  }

  /**
   * Change an object's type to SequencingRunContentsResponse.
   * This makes the methods defined in SequencingRunContentsResponse available for that object.
   */
  public static wrap(obj: gss.SequencingRunContentsResponse): SequencingRunContentsResponse {
    return obj ? Object.setPrototypeOf(obj, SequencingRunContentsResponse.prototype) : null;
  }

  public getSampleDataRowsAndSettings(clearAdapterReads = false): { allSampleDataRows: ISampleDataRow[], customSettingsFields: string[] } {
    if (isEmpty(this.laneLibraries)) {
      return {
        allSampleDataRows: [],
        customSettingsFields: []
      };
    }

    const grouping_keys = SequencingRunContentsResponse.MULTI_LANE_GROUPING_KEYS;
    const customSettingsFieldsSet = new Set<string>();

    const rows = this.laneLibraries.map(laneLibrary => {
      const sampleDataRow: ISampleDataRow = {
        ...(laneLibrary.laneNumber != null) && {laneNumber: laneLibrary.laneNumber},
        sampleName: laneLibrary.sampleName,
        indexContainerPosition: laneLibrary.indexContainerPosition,
        index1Name: laneLibrary.index1Name,
        index2Name: laneLibrary.index2Name,
        index1Sequence: laneLibrary.index1Sequence,
        index2Sequence: laneLibrary.index2Sequence,
        projectName: laneLibrary.projectName,
        overrideCycles: laneLibrary.overrideCycles,
        /**
         * In GSS model these two values are number, and allow 0 as value
         * ECMA defines 0 as false, and causes problems when checking (!value) - e.g. required field validation
         * Convert to string to make it easier
         */
        barcodeMismatchRead1: laneLibrary.barcodeMismatchRead1,
        barcodeMismatchRead2: laneLibrary.barcodeMismatchRead2,

        libraryPrepKitId: get(laneLibrary, 'library.libraryPrepKit.id') || this.getLibraryPrepKitIdFromUrn(laneLibrary) || LibraryPrepKit.UNSPECIFIED.id,
        indexAdapterKitId: get(laneLibrary, 'library.indexAdapterKit.id') || this.getIndexAdapterKitIdFromUrn(laneLibrary) || IndexAdapterKit.UNSPECIFIED.id,
        adapterSequenceRead1: clearAdapterReads ? null : laneLibrary.adapterSequenceRead1,
        adapterSequenceRead2: clearAdapterReads ? null : laneLibrary.adapterSequenceRead2,

        isMappedToConfig: false
      };
      if (laneLibrary.settings) {
        for (const fieldId in laneLibrary.settings) {
          sampleDataRow[fieldId] = laneLibrary.settings[fieldId];
          if (!grouping_keys.includes(fieldId)) {
            grouping_keys.push(fieldId);
          }
        }
      }
      if (laneLibrary.customSettings) {
        for (const fieldId in laneLibrary.customSettings) {
          sampleDataRow[fieldId] = laneLibrary.customSettings[fieldId];
          if (!grouping_keys.includes(fieldId)) {
            grouping_keys.push(fieldId);
          }
          customSettingsFieldsSet.add(fieldId);
        }
      }
      return sampleDataRow;
    });

    const groupedRows = chain(rows)
      .groupBy(row => this.computeAggregateValue(grouping_keys, row))
      .map(groups => Object.keys(groups).map(e => groups[e]))
      .value()
      .map(x => {
        const row = x[0] as ISampleDataRow;
        const laneNumbers = x.filter(y => y.laneNumber > 0).map(y => y.laneNumber);
        if (!isEmpty(laneNumbers)) {
          row.laneNumbers = laneNumbers;
        } else {
          delete row.laneNumbers;
          delete row.laneNumber;
        }
        return row;
      });
    
    return {
      allSampleDataRows: groupedRows,
      customSettingsFields: Array.from(customSettingsFieldsSet)
    };
  }

  private computeAggregateValue(constraint: string[], data: object): string {
    return chain(constraint)
      .map(c => data[c] || '')
      .reduce((acc, val) => `${acc}|${val}`, '')
      .value();
  }

  private getIndexAdapterKitIdFromUrn(laneLibrary: gss.LaneLibraryResponse) {
    const splittedUrn = get(laneLibrary, 'indexAdapterKitUrn', '').split(':indexadapterkit:');
    return splittedUrn[splittedUrn.length - 1];
  }

  private getLibraryPrepKitIdFromUrn(laneLibrary: gss.LaneLibraryResponse) {
    const splittedUrn = get(laneLibrary, 'libraryPrepKitUrn', '').split(':libraryprepkit:');
    return splittedUrn[splittedUrn.length - 1];
  }
}
