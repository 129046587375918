import { NgModule } from "@angular/core";
import { DynamicSettingsFormComponent } from "./dynamic-settings-form/dynamic-settings-form.component";
import { ComboBoxModule } from '@app/cloud-run-prep/directives/combobox/combobox.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RadioCheckboxGroupModule } from '@app/run-planning/shared-components/radio-checkbox-group/radio-checkbox-group.module';
import { CommonModule } from "@angular/common";
import { FormFieldsModule, InputModule, LibPopoverModule, NgxSmartModalModule, RadioCheckboxModule, SkeletonModule } from "@bssh/comp-lib";
import { DescriptionTextComponent } from "./description-text/description-text.component";
import { GenomeDropdownComponent } from "./genome-dropdown/genome-dropdown.component";
import { ReferenceFileDropdownComponent } from "./reference-file-dropdown/reference-file-dropdown.component";
import { FileSelectDropdownComponent } from "./file-select-dropdown/file-select-dropdown.component";

@NgModule({
  declarations: [
    DynamicSettingsFormComponent,
    DescriptionTextComponent,
    GenomeDropdownComponent,
    ReferenceFileDropdownComponent,
    FileSelectDropdownComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    RadioCheckboxModule,
    FormFieldsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    ComboBoxModule,
    LibPopoverModule,
    RadioCheckboxGroupModule,
    SkeletonModule
  ],
  exports: [
    DynamicSettingsFormComponent,
    DescriptionTextComponent,
    GenomeDropdownComponent,
    ReferenceFileDropdownComponent,
    FileSelectDropdownComponent
  ],
  providers: []
})
export class SharedFormsModule { }
