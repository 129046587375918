import {Injectable} from '@angular/core';
import {
  AnalysisDefinitionCompactAnalysisDefinitionSortFieldsPagedItems,
  AnalysisVersionDefinition,
  AnalysisVersionDefinitionCompactItemList,
  GenomeCompact,
  IndexAdapterKitCompact,
  IndexStrategy,
  LibraryPrepKit,
  LibraryPrepKitCompactLibraryPrepKitSortFieldsPagedItems,
  ReadType,
  IndexAdapterKit
} from '@stratus/gss-ng-sdk';
import {GSSKitsSortUtilities} from '@app/core/utilities/gss-kits-sort-utilities';
import {sortBy} from 'lodash';
import {AnalysisDefinitionGroupedOption, CustomOption, SimpleOption} from '@app/core/store/cloud-run-prep/run-setup.state';
import {InstrumentPlatformInfo} from '@root/node_modules/@stratus/gss-ng-sdk';
import { UserSubscriptionService } from '../user/user-subscription.service';

@Injectable({
  providedIn: 'root'
})
export class CloudRunMapperService {

  constructor(protected userSubscriptionService: UserSubscriptionService) {
  }

  mapInstrumentPlatformInfos(items: InstrumentPlatformInfo[]): CustomOption[] {
    return items
      .filter(p => p.runPlanningSupported == true)
      .map(p => {
        return {
          id: p.instrumentPlatform,
          text: p.displayName,
          value: p.instrumentPlatform,
          originalData: p
        };
      });
  }

  mapAdVersions(typeId: string, adVersions: AnalysisVersionDefinitionCompactItemList): CustomOption[] {
    return (adVersions || {}).items.map(version => {
      return {
        id: version.id,
        text: version.version,
        value: `${typeId}_${version.version}_${version.id}`
      };
    });
  }

  mapAnalysisDefinitions(input: AnalysisDefinitionCompactAnalysisDefinitionSortFieldsPagedItems): AnalysisDefinitionGroupedOption[] {
    if (!input || !input.items) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(input.items
      .filter(t => t.analysisVersions && (t.analysisVersions || {}).items.length > 0))
      .map(type => {
        return {
          groupName: type.displayName || type.name,
          name: type.name,
          options: this.mapAdVersions(type.id, type.analysisVersions)
        };
      });
  }

  mapLibraryPrepKit(input: LibraryPrepKit): CustomOption {
    return {
      id: input.id,
      text: input.displayName ? input.displayName : input.name,
      value: input.id,
      originalData: input
    };
  }

  mapLibraryPrepKits(input: LibraryPrepKitCompactLibraryPrepKitSortFieldsPagedItems): CustomOption[] {
    if (!input) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(input.items
      .filter(t => t.id))
      .map(type => this.mapLibraryPrepKit(type));
  }

  mapLibraryPrepKitsFromAnalysisDefinition(input: AnalysisVersionDefinition): CustomOption[] {
    if (!input || !input.compatibleLibraryPrepKits) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(input.compatibleLibraryPrepKits
      .filter(t => t.id))
      .map(type => this.mapLibraryPrepKit(type));
  }

  mapIndexAdapterKit(input: LibraryPrepKit): CustomOption[] {
    if (!input || !input.libraryPrepKitCompatibleIndexAdapterKits) {
      return [];
    }
    return GSSKitsSortUtilities.sortIAKs(input.libraryPrepKitCompatibleIndexAdapterKits
      .filter(t => t.id))
      .map(type => this.mapIndexAdapterKitToCustomOption(type));
  }

  mapIndexAdapterKitToCustomOption(indexAdapterKit: IndexAdapterKit): CustomOption {
    return {
      id: indexAdapterKit.id,
      text: indexAdapterKit.displayName || indexAdapterKit.name,
      value: indexAdapterKit.id,
      originalData: indexAdapterKit
    };
  }

  mapIndexAdapterKitListToCustomOptions(input: IndexAdapterKitCompact[]): CustomOption[] {
    return input.map(indexAdapterKit => this.mapIndexAdapterKitToCustomOption(indexAdapterKit));
  }

  mapIndexReads(input: string[]): SimpleOption[] {
    if (!input) {
      return [];
    }
    return input
      .filter(t => t)
      .map(type => {
        return {
          text: this.getIndexReadDisplayName(type),
          value: type
        };
      });
  }

  mapReadTypes(input: string[]): SimpleOption[] {
    if (!input) {
      return [];
    }
    return input
      .filter(t => t)
      .map(type => {
        return {
          text: this.getReadTypeDisplayName(type),
          value: type
        };
      });
  }

  mapRunSetUpMode(input: string[]): SimpleOption[] {
    if (!input) {
      return [];
    }
    return input.map(method => {
      return {
        text: method,
        value: method
      };
    });
  }

  mapGenomeOptions(genomes: GenomeCompact[]): CustomOption[] {
    if (!genomes) {
      return [];
    }
    return genomes.map(
      (item: GenomeCompact) => {
        return {
          id: item.id,
          // BASE-77380 use id as value instead of urn
          value: item.id,
          text: item.displayName ? item.displayName : item.name,
          version: item.dragenVersion,
          originalData: item
        };
      });
  }

  mapIndexStrategyFromNumCyclesIndex(numCyclesIndex1: number, numCyclesIndex2: number): IndexStrategy {
    let indexRead;
    if (numCyclesIndex1 > 0 && numCyclesIndex2 > 0) {
      indexRead = IndexStrategy.DUAL;
    } else if (numCyclesIndex1 > 0 && numCyclesIndex2 === 0) {
      indexRead = IndexStrategy.SINGLE;
    } else {
      indexRead = IndexStrategy.NO_INDEX;
    }
    return indexRead;
  }

  mapFileOptions(files: any[]): CustomOption[] {
    if (!files) {
      return [];
    }
    return sortBy(files, f => (f.text || f.name).toUpperCase()).map(
      (item: any) => {
        return {
          id: item.value || item.id,
          value: item.value || item.urn,
          text: item.text || item.name,
          originalData: item
        };
      });
  }

  private getIndexReadDisplayName(indexRead: string): string {
    switch (indexRead) {
      case IndexStrategy.NO_INDEX:
        return 'No Index';
      case IndexStrategy.SINGLE:
        return '1 Index';
      case IndexStrategy.DUAL:
        return '2 Indexes';
      default:
        return indexRead;

    }
  }

  private getReadTypeDisplayName = (readType: string): string => {
    switch (readType) {
      case ReadType.SINGLE:
        return 'Single Read';
      case ReadType.PAIRED:
        return 'Paired End';
      default:
        return readType;

    }
  }
}
