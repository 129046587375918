import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  ValidationErrors,
  Validators,
  FormBuilder,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  AbstractControlOptions
} from '@angular/forms';
import { get, isEmpty, isEqual, isInteger } from 'lodash';
import { VALIDATION_RULE } from '@app/cloud-run-prep/constants';
import { SubSink } from 'subsink';
import { CONFIG_LIMIT_EXCEED_KEY } from '@app/run-planning/constants';
import { FormFieldType, IFormField, IFormFieldAvailableWhen, IFormFieldChoice } from '@app/run-planning/model/form-field';
import { getDifference } from '@app/run-planning/services/helper.service';
import { map, pairwise, startWith } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FormValueObject } from '@app/run-planning/interface';

@Component({
  selector: 'app-dynamic-settings-form',
  templateUrl: './dynamic-settings-form.component.html',
  styleUrls: ['./dynamic-settings-form.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DynamicSettingsFormComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: DynamicSettingsFormComponent, multi: true, },
  ]
})
export class DynamicSettingsFormComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() analysisLocation: string;
  @Input() analysisVersionDefinitionId = '';
  @Input() formFields: IFormField[] = [];
  @Input() allSettingsFormValues: FormValueObject;
  @Input() isFormValuesReadOnly = false;
  @Input() renderErrors?: any[] = [];
  @Input() disableLocalFileUpload = false;
  @Input() disableReferenceFileUpload = false;
  @Input() filterBySampleSheetFormat: string = null;
  /* Show hyper link in description */
  @Input() showHyperlinkInDescription: boolean = true;
  @Input() hideOverrideCyclesField: boolean = false;

  private newlyAddedReferenceFileSubject = new BehaviorSubject<string>(null);
  newlyAddedReferenceFile$ = this.newlyAddedReferenceFileSubject.asObservable();

  private subs = new SubSink();
  private _value: any;
  myFormGroup: FormGroup;
  get supportLocalUpload() {
    return this.analysisLocation === 'Local' && !this.disableLocalFileUpload;
  }

  private _sortedSelectFields: IFormField[];
  private disablePropagatingChangesToParent = false;

  getFormValue(formControlName: string) {
    return this.myFormGroup ? this.myFormGroup.get(formControlName).value : null;
  }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  onChange: any = () => { };

  writeValue(obj: any): void {
    if (this.myFormGroup && obj && !isEqual(this.myFormGroup.value, obj)) {
      this._value = obj;
      // Change is from parent level, hence change does not need to propagate
      this.disablePropagatingChangesToParent = true;
      this.myFormGroup.patchValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(): ValidationErrors | null {
    if (!this.myFormGroup || !this.myFormGroup.invalid) {
      return null;
    } else {
      const invalidControls = this.findInvalidControls();
      if(invalidControls.every(x => this.formFields.find(c => c.id == x).hidden)) {
        // If all invalid controls are hidden
        return null;
      }
      return { invalid: true };
    }
  }

  onValidationChange: any = () => {};

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formFields && !isEqual(changes.formFields.currentValue, changes.formFields.previousValue)) {
      this._sortedSelectFields = this.sortSelectFields(this.formFields);

      // Repopulate the form group
      this.myFormGroup = this.buildFormGroup(this.formFields);
      this.onChange(this._value);
    }

    // Disable/enable the form group
    if (this.isFormValuesReadOnly && this.myFormGroup.enabled) {
      this.myFormGroup.disable();
    } else if (!this.isFormValuesReadOnly && !this.myFormGroup.enabled) {
      this.myFormGroup.enable();
    }

    if (changes.allSettingsFormValues) {
      for (const field of this.formFields) {
        if (field.type === FormFieldType.REFERENCE_FILE && field.settings && field.settings.referenceFileGenomeFilterFieldId) {
          field.genomeFilterGenomeId = get(this.allSettingsFormValues, field.settings.referenceFileGenomeFilterFieldId);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setErrorToForm(errors: ValidationErrors) {
    setTimeout(() => {
      this.myFormGroup.markAllAsTouched();
      Object.keys(this.myFormGroup.controls).forEach(control => {
        this.myFormGroup.controls[control].markAllAsTouched();
        this.myFormGroup.controls[control].setErrors(errors);
      });
    }, 0);
  }

  clearFormErrors(errorKey: string) {
    if (errorKey) {
      Object.keys(this.myFormGroup.controls).forEach(controlKey => {
        const control = this.myFormGroup.controls[controlKey];
        if (control.errors) {
          delete this.myFormGroup.controls[controlKey].errors[errorKey];
        }
      });
    }
  }

  getErrorMessage(field: IFormField) {
    if (!field.id || !this.myFormGroup || !this.myFormGroup.touched || !this.myFormGroup.controls[field.id]
      || !this.myFormGroup.controls[field.id].touched) {
      return null;
    }
    const fieldControl = this.myFormGroup.controls[field.id];
    if (!fieldControl.errors) {
      return null;
    }
    if (fieldControl.errors.required) {
      return field.label + ' is required';
    }
    if (fieldControl.errors.pattern && field.regex && field.type !== 'integer') {
      return field.regexErrorMessage || ('Value must match this pattern: ' + field.regex);
    }

    if(field.type === 'integer'){
      var errorMessageToReturn = `Value must be an ${field.type} `;
      // If field.min is not defined we assume it to be 0
      if (isInteger(field.max)) {
        errorMessageToReturn += `between ${field.min || 0} and ${field.max}`;
      }
      else {
        errorMessageToReturn +=  `greater than or equal to ${field.min || 0}`;
      }
      return errorMessageToReturn;
    }

    if(field.type === 'number'){
      var errorMessageToReturn = `Value must be a ${field.type} `;
      if(fieldControl.errors.min || fieldControl.errors.max ){
        if (isInteger(field.min) && isInteger(field.max)) {
          errorMessageToReturn += `between ${field.min} and ${field.max}`;
        }else if(fieldControl.errors.min){
          errorMessageToReturn +=  `greater than or equal to ${field.min}`;
        }else if(fieldControl.errors.max){
          errorMessageToReturn +=  `lesser than or equal to ${field.max}`;
        }
      } 
      return errorMessageToReturn;
    }

    if (fieldControl.errors[CONFIG_LIMIT_EXCEED_KEY]) {
      return 'This configuration setting exceeds max allowed value, please review your input.';
    }
    return null;
  }


  getRenderError(field: IFormField) {
    if (isEmpty(this.renderErrors)) {
      return null;
    }

    const renderError = this.renderErrors.find(x => x.fieldId === field.id);
    if (isEmpty(renderError)) {
      return null;
    }

    return renderError.message;
  }

  onCheckBoxChange($event, fieldId: string) {
    const checkBoxControl = this.myFormGroup.controls[fieldId];
    checkBoxControl.markAsTouched();
    checkBoxControl.patchValue(!!$event.target.checked);
  }

  private buildFormGroup(formFields: IFormField[]): FormGroup {
    const formGroup = this.formBuilder.group({});
    if (!formFields) {
      return formGroup;
    }

    const formValues = this._value || {};

    for (const field of formFields) {
      const validationRules = field.required ? [Validators.required] : [];

      if (isInteger(field.max)) {
        validationRules.push(Validators.max(field.max));
      }
      if (isInteger(field.min)) {
        validationRules.push(Validators.min(field.min));
      }
      if (field.regex) {
        validationRules.push(Validators.pattern(field.regex));
      }
      if (field.type === 'number') {
        validationRules.push(Validators.pattern(VALIDATION_RULE.number.pattern));
      }
      if (field.type === 'integer') {
        validationRules.push(Validators.pattern(VALIDATION_RULE.integer.pattern));
      }

      let value = formValues[field.id];

      // Set value for required checkbox, if it has no value
      if ((field.type === 'checkbox') && field.required && !value) {
        value = false;
      }

      const options: AbstractControlOptions = {
        validators: validationRules
      };

      if ((field.type === 'text') || (field.type === 'textbox')
        || (field.type === 'number') || (field.type === 'integer')) {
        options.updateOn = 'blur';
      }

      // Setting default value this way does not work for 'select'
      formGroup.addControl(field.id, this.formBuilder.control(
        { value, disabled: field.disabled }, options));

      if (field.type === 'select') {
        // Set default value for 'select'
        formGroup.get(field.id).setValue(value, { emitEvent: false, onlySelf: true});

        // Filter the disabled field in select dropdown options
        // But don't exclude options that are excluded due to availableWhen
        field.choices = field.choices.filter(choice => !get(choice, 'disabled'));
      }

      if (field.type === FormFieldType.REFERENCE_FILE && field.settings && field.settings.referenceFileGenomeFilterFieldId) {
        field.genomeFilterGenomeId = get(this.allSettingsFormValues, field.settings.referenceFileGenomeFilterFieldId);
      }
    }
    this.propagateValueChanges(formGroup);
    return formGroup;
  }

  private propagateValueChanges(formGroup: FormGroup) {
    this.subs.sink = formGroup.valueChanges
      .pipe(
        startWith(this._value || {}),
        pairwise(),
        map(([oldState, newState]) => getDifference(oldState, newState)),)
      .subscribe(changes => {
        // Propagate changes only when there's a change
        if(!isEmpty(changes)) {
          this.updateDependantFields(changes);
          this._value = this.myFormGroup.getRawValue();
          if(!this.disablePropagatingChangesToParent) {
            this.onChange(this._value);
          }
        }
        // Reset flag
        this.disablePropagatingChangesToParent = false;
      });
  }

  /**
   * This function is NOT in use as it will cause problem for render AVD
   * e.g. the field status changed from disabled to enabled
   * since the avdId+fieldId is not changed, UI won't repopulate the field and caused the field kept at disabled status
   *
   * Returns an id to be used by ngFor to optimize rendering of field list.
   * Different AVD may have the same field-id, hence need to add avd-id to this generated id
   */
  trackById(index: number, item: IFormField) {
    return `${this.analysisVersionDefinitionId}-${item.id}`;
  }

  filterChoices(choices: IFormFieldChoice[]): IFormFieldChoice[] {
    return choices.filter(choice => get(choice, 'valid', true));
  }

  updateDependantFields(changes) {
    const patchedValues = {};
    for (const field of this.getFieldsToUpdate(changes)) {
      for (const choice of field.choices) {
        const conditions: IFormFieldAvailableWhen[] = get(choice, 'availableWhen', []);
        const newValidity = conditions.every(condition => {
          const currentValue = patchedValues[field.id] === '' ? '' : this.getFormValue(condition.id);
          return condition.hasValue.includes(currentValue);
        });
        if (!newValidity && this.getFormValue(field.id) === choice.value) {
          patchedValues[field.id] = '';
          this.myFormGroup.patchValue({ [field.id]: '' }, { emitEvent: false });
        }
        choice.valid = newValidity;
      }
    }
  }

  /**
   * Returns an array of select fields,
   * whose choices' validity need to be re-evaluated as a result of `changes`.
   * Evaluation should be in the order of the return array of form fields,
   * which is topologically sorted based on their dependant fields.
   *
   * Example set of select form fields and their options, forming two disjoint directed acyclic graphs:
   * Full Mock AVD found in "cypress/fixtures/testData/runSettings/avdDynamicAnalysisSettings.json"
   *
   *  "analysisSettings": {
        "fields": [
            {
                "id": "ReferenceGenomeDir",
                "type": "genome",
                "label": "Reference Genome",
                "required": true,
                "requiredMessage": "This field is required"
            },
            {
                "id": "MapAlignOutFormat",
                "type": "radio",
                "label": "Map/Align Output Format",
                "choices": [
                    {
                        "text": "BAM",
                        "value": "bam",
                        "selected": false
                    },
                    {
                        "text": "CRAM",
                        "value": "cram",
                        "selected": false
                    }
                ],
                "required": true,
                "customType": "",
                "requiredMessage": "This field is required"
            },
            {
                "id": "fieldA",
                "type": "select",
                "label": "Field A",
                "settings": {
                    "dependantFieldIds": [
                        "fieldB",
                        "fieldC",
                        "fieldD"
                    ]
                },
                "choices": [
                    {
                        "text": "Choice A1",
                        "value": "A1",
                        "selected": false
                    },
                    {
                        "text": "Choice A2",
                        "value": "A2",
                        "selected": false
                    },
                    {
                        "text": "Choice A3",
                        "value": "A3",
                        "selected": false
                    },
                    {
                        "text": "Choice A4",
                        "value": "A4",
                        "selected": false
                    }
                ]
            },
            {
                "id": "fieldB",
                "type": "select",
                "label": "Field B",
                "settings": {
                    "dependantFieldIds": [
                        "fieldC"
                    ]
                },
                "choices": [
                    {
                        "text": "Choice B1",
                        "value": "B1",
                        "selected": false
                    },
                    {
                        "text": "Choice B2",
                        "value": "B2",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldA",
                                "hasValue": [
                                    "A2",
                                    "A3"
                                ]
                            }
                        ]
                    },
                    {
                        "text": "Choice B3",
                        "value": "B3",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldA",
                                "hasValue": [
                                    "A2",
                                    "A3"
                                ]
                            }
                        ]
                    },
                    {
                        "text": "Choice B4",
                        "value": "B4",
                        "selected": false
                    }
                ]
            },
            {
                "id": "fieldC",
                "type": "select",
                "label": "Field C",
                "choices": [
                    {
                        "text": "Choice C1",
                        "value": "C1",
                        "selected": false
                    },
                    {
                        "text": "Choice C2",
                        "value": "C2",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldB",
                                "hasValue": [
                                    "B2",
                                    "B3"
                                ]
                            },
                            {
                                "id": "fieldD",
                                "hasValue": [
                                    "D2",
                                    "D3"
                                ]
                            }
                        ]
                    },
                    {
                        "text": "Choice C3",
                        "value": "C3",
                        "selected": false
                    },
                    {
                        "text": "Choice C4",
                        "value": "C4",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldA",
                                "hasValue": [
                                    "A4"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": "fieldD",
                "type": "select",
                "label": "Field D",
                "settings": {
                    "dependantFieldIds": [
                        "fieldC"
                    ]
                },
                "choices": [
                    {
                        "text": "Choice D1",
                        "value": "D1",
                        "selected": false
                    },
                    {
                        "text": "Choice D2",
                        "value": "D2",
                        "selected": false
                    },
                    {
                        "text": "Choice D3",
                        "value": "D3",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldA",
                                "hasValue": [
                                    "A3",
                                    "A4"
                                ]
                            }
                        ]
                    },
                    {
                        "text": "Choice D4",
                        "value": "D4",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldA",
                                "hasValue": [
                                    "A3",
                                    "A4"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": "fieldE",
                "type": "select",
                "label": "Field E",
                "settings": {
                    "dependantFieldIds": [
                        "fieldF"
                    ]
                },
                "choices": [
                    {
                        "text": "Choice E1",
                        "value": "E1",
                        "selected": false
                    },
                    {
                        "text": "Choice E2",
                        "value": "E2",
                        "selected": false
                    },
                    {
                        "text": "Choice E3",
                        "value": "E3",
                        "selected": false
                    },
                    {
                        "text": "Choice E4",
                        "value": "E4",
                        "selected": false
                    }
                ]
            },
            {
                "id": "fieldF",
                "type": "select",
                "label": "Field F",
                "choices": [
                    {
                        "text": "Choice F1",
                        "value": "F1",
                        "selected": false
                    },
                    {
                        "text": "Choice F2",
                        "value": "F2",
                        "selected": false,
                        "availableWhen": [
                            {
                                "id": "fieldE",
                                "hasValue": [
                                    "E2"
                                ]
                            }
                        ]
                    },
                    {
                        "text": "Choice F3",
                        "value": "F3",
                        "selected": false
                    },
                    {
                        "text": "Choice F4",
                        "value": "F4",
                        "selected": false
                    }
                ]
            }
        ]
      },
   *
   */
  private getFieldsToUpdate(changes: object): IFormField[] {
    /**
     * Future optimization: filter sortedSelectFields to include only affected fields
     * Excluding now, assuming that availableWhen is rare rather than the default behaviour.
     */
    if (isEmpty(changes)) {
      return [];
    }
    const changedSelectFields = this._sortedSelectFields
      .filter(field => Object.keys(changes).includes(field.id));
    return changedSelectFields.length > 0 ? this._sortedSelectFields: [];
  }

  /**
   * Performs a topological sort on 'select' formFields
   * where formFields are expected to form a DAG or a set of disjoint DAGs
   *
   * Data assumptions:
   * - dependantFieldIds store the relationship correctly
   * - no cycles
   */
  private sortSelectFields(formFields: IFormField[]): IFormField[] {
    // TODO: consider excluding any field that is a singleton and don't form a DAG
    const dfsExplore = (nodeIndex: number) => {
      if (visitedStatus[nodeIndex]) {
        return;
      }
      count++;  // pre-order number
      visitedStatus[nodeIndex] = true;
      const dependantFieldIds = get(selectFields[nodeIndex], 'settings.dependantFieldIds', []);
      for (const dependantFieldId of dependantFieldIds) {
        dfsExplore(selectFieldIdToIndexMap[dependantFieldId]);
      }
      postOrders[nodeIndex] = ++count;
    }

    let count = 0;
    const selectFields = formFields.filter(field => field.type === 'select');
    const selectFieldIdToIndexMap = selectFields.reduce((map, field, index) => {
      map[field.id] = index;
      return map;
    }, {});
    const visitedStatus = new Array<boolean>(selectFields.length).fill(false);
    const postOrders = new Array<number>(selectFields.length);

    for (const [index, _] of selectFields.entries()) {
      dfsExplore(index);
    }

    return selectFields.map((_, index) => index)
      .sort((i1, i2) => postOrders[i2] - postOrders[i1])
      .map(index => selectFields[index]);
  }

  public onNewReferenceFileAdded(payload) {
    this.newlyAddedReferenceFileSubject.next(payload.fileName);
    this.myFormGroup.patchValue({ [payload.formControlName]: payload.referenceFileId });
  }
  
  private findInvalidControls() {
    const invalid = [];
    const controls = this.myFormGroup.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }
}
