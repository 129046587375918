import { Injectable } from '@angular/core';
import { IRun } from '@app/core/model/runs/run';
import { IPlannedRunsState } from './runs-planned.state';
import { ArrayUtilities } from '@app/core/utilities/array-utilities';
import { SequencingRunsService } from '@stratus/gss-ng-sdk';
import { ToastrService } from '@bssh/comp-lib';
import { ModalService } from '@app/shared/modals/services/modal.service';
import { TrashPlannedRunsModalComponent } from '@app/shared/modals/trash-planned-runs-modal/trash-planned-runs-modal.component';
import { IResourceStore } from '../resource/resource.store';
import { AbstractResourceStore } from '../resource/abstract.resource.store';
import { ITrashPlannedRunsModalInput, ITrashPlannedRunsModalOutput } from '@app/shared/modals/model/action-modal';
import { ITrashResult } from '@app/core/services/toolbar/planned-runs/planned-runs-trash.service';
import { Subject } from 'rxjs';
import FileSaver from 'file-saver';
import { ResourceType } from '@app/core/model/resource-type';
import { InstrumentPlatforms } from '@app/core/model/runs/instrument';

export const SAMPLE_SHEET_FILENAME = 'SampleSheet.csv';

// Interface
export interface IPlannedRunsStore extends IResourceStore<IPlannedRunsState> {
  updateSelectedRuns(runs: IRun[]): void;
}

@Injectable({
  providedIn: 'root'
})
export class PlannedRunsStore extends AbstractResourceStore<IPlannedRunsState> implements IPlannedRunsStore {

  private runsDeletedSubject = new Subject<boolean>();
  runsDeleted$ = this.runsDeletedSubject.asObservable();

  constructor(
    private sequencingRunsService: SequencingRunsService,
    private toastrService: ToastrService,
    private modalService: ModalService
  ) {
    super(['selectedPlannedRuns']);
  }

  /**
   * Updates the store with data-table row checkbox selection.
   * @param selectedRuns all the selected rows emitted by the data-table
   */
  updateSelectedRuns(selectedRuns: IRun[]): void {
    this.setState({ selectedPlannedRuns: selectedRuns }, PlannedRunStoreActions.UpdateSelectedPlannedRuns);
  }

  downloadSampleSheet(): void {
    if (!this.isSingleRunSelected()) {
      console.warn('More than one run is selected, sample sheet download only works on single selected run.');
    }
    const selectedRun: IRun = this.getStateProperty('selectedPlannedRuns')[0];
    const generateSampleSheet$ = this.sequencingRunsService.generateSampleSheetForSequencingRun({
      runId: selectedRun.Id,
      body: {}
    });

    this.subs.sink = generateSampleSheet$.subscribe({
      next: res => {
        const sampleSheetBlob = new Blob([res.sampleSheetContent]);
        FileSaver.saveAs(sampleSheetBlob, SAMPLE_SHEET_FILENAME);
        this.toastrService.success('The sample sheet has been exported.');
      },
      error: error => {
        this.toastrService.error('The sample sheet export failed. Please try again.');
      }
    });
  }

  openTrashRunsModal(): void {
    const selectedRuns: IRun[] = this.getStateProperty('selectedPlannedRuns');
    const modalInputData: ITrashPlannedRunsModalInput = {runsToTrash: selectedRuns};

    // Open modal and subscribe to 'Confirm Delete' observable with output
    this.subs.sink = this.modalService.openModal(modalInputData, TrashPlannedRunsModalComponent).confirm.subscribe({
      next: (trashModalOutput: ITrashPlannedRunsModalOutput) => {
        const trashResult = trashModalOutput.trashResult;
        if (trashResult.isTrashSuccessful) {
          this.toastrService.success('The run(s) have been moved to the trash.');
        } else {
          this.toastrService.error(this.getTrashFailedMessage(trashResult),
            'One or more run(s) could not be trashed', {timeOut: 12000});
        }
        this.runsDeletedSubject.next(true);
      }
    });
  }

  private getTrashFailedMessage(trashResult: ITrashResult): string {
    let trashSummaryMessage: string;
    if (trashResult.numTrashSuccess === 0) {
      trashSummaryMessage = `${trashResult.numTrashFailed} run(s) could not be trashed.`;
    } else {
      trashSummaryMessage =
        `${trashResult.numTrashSuccess} run(s) trashed successfully, but ${trashResult.numTrashFailed} run(s) could not be trashed.`;
    }
    return trashSummaryMessage;
  }

  /**
   * Returns whether one row of planned run is selected. Certain actions like Edit-Run and Download sample sheet
   * should not work when more than 1 runs are selected.
   */
  isSingleRunSelected(): boolean {
    return ArrayUtilities.isArrayOfSize(this.getStateProperty('selectedPlannedRuns'), 1);
  }

  canDownloadSampleSheet(): boolean {
    return this.isSingleRunSelected() && this.getStateProperty('selectedPlannedRuns')[0].Status !== 'Draft';
  }

/**
 * Caveat for checking if run is locked is that only a single run must be selected
 * as editing of run is only allowed if only a single run is selected + isLocked flag is false
 *
 * Returns whether a selected run is locked or not
 */
  isRunLocked(): boolean{
    const selectedRun: IRun = this.getStateProperty('selectedPlannedRuns')[0];
    return selectedRun.isLocked;
  }

  canEdit(): boolean {
    return this.isSingleRunSelected() && !this.isRunLocked();
  }

  /**
   * return true when 1 or more run has been selected/checked
   */
  canTrash(): boolean {
    return ArrayUtilities.isNullOrEmpty(this.getState().selectedPlannedRuns) ? false : true;
  }

  /**
   * Returns the run-id of a selected row, where the id is used to navigate to cloud-run-prep page in edit-mode.
   */
  getSelectedRunId(): string {
    const selectedRun = this.getStateProperty('selectedPlannedRuns')[0];
    return selectedRun.Id;
  }

  getResourceType(): ResourceType {
    return ResourceType.PLANNED_RUN;
  }

  /**
   * Checks if the selected planned-run is a multi-config.
   * The current method of detecting it is not ideal, but this is only needed
   * until unified-ui is enabled
   */
  get isSelectedRunMultiConfig(): boolean {
    const MULTI_CONFIG_INSTRUMENT_PLATFORMS = [InstrumentPlatforms.NOVASEQ_X_SERIES, InstrumentPlatforms.MISEQ_I100_SERIES, InstrumentPlatforms.SEQUENCER_TERRA];
    const selectedRun: IRun = this.getStateProperty('selectedPlannedRuns')[0];
    return MULTI_CONFIG_INSTRUMENT_PLATFORMS
      .some(multiConfigPlatform => multiConfigPlatform === selectedRun.Instrument.PlatformName);
  }
}

// The action-names for this store, for logging/tracking.
export enum PlannedRunStoreActions {
  UpdateSelectedPlannedRuns = 'UPDATE_SELECTED_PLANNED_RUNS'
}
