import {Injectable} from '@angular/core';
import {FilesService, FileListResponse} from '@stratus/gds-ng-sdk';
import {get, isString} from 'lodash';
import {delay, retryWhen, map, switchMap, catchError, flatMap} from 'rxjs/operators';
import {IApiResponseWrapper} from '../../../../app/core/model/v2-api/v2-api-wrappers';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {genericRetryWhen, observableEmitDelay} from '@app/core/rxjsutils/rxjs-utilities';
import {Observable, ObservableInput, of, throwError} from 'rxjs';
import {ToastrService} from '@bssh/comp-lib';
import {BasespaceService, V1pre3FileCompact, V1pre3Project, V2Dataset} from '@bssh/ng-sdk';
import environment from '@environments/environment';
import {UploadCredential, S3DirectUploadService} from './s3-direct-upload.service';
import {BsApiEndPoints} from '@app/core/services/bs-api/endpoints';
import {ConsoleLogger} from '@app/core/utilities/consolelogger';
import { AnalysisStatus } from '@app/core/model/appSessions/analysis-status';

export const customFileUploadServiceName = 'customFileUploadService';
export const uploadFailMessage = 'File upload failed. Please try again.';

// Read partameters
const PAGE_SIZE = 1000;
const GDS_VOLUME_PROPERTY_NAME = 'BaseSpace.GdsUserVolume';

// Upload parameters
const MAX_FILE_SIZE_IN_GB = 25;
const MAX_FILE_SIZE_IN_BYTES: number = 1024 * 1024 * 1024 * MAX_FILE_SIZE_IN_GB;
const largeFileUploadFailMessage = `Max file size supported is ${MAX_FILE_SIZE_IN_GB} GB.`;
const PLACEHOLDER_DATASET_ID = 'DATASET_ID';
const PLACEHOLDER_STATUS = 'UPLOAD_STATUS';
const DIRECT_UPLOAD_INFO_PATH = `datasets/${PLACEHOLDER_DATASET_ID}/direct-upload-info`;
const UPLOAD_STATUS_PATH = `datasets/${PLACEHOLDER_DATASET_ID}?UploadStatus=${PLACEHOLDER_STATUS}`;
const REFERENCE_FILES_PROJECT_NAME = 'Reference Files';
const REFERENCE_FILES_PROJECT_DESC = 'Project for custom reference files';
const REFERENCE_FILES_DATASET_TYPE = 'illumina.gds.customreferencefiles.v1';
export const RESOURCE_TYPE_FOLDER_NAME = 'appsettings';

@Injectable({
  providedIn: 'root'
})
export class GdsFileService {
  constructor(private fileService: FilesService,
              private httpClient: HttpClient,
              private toastrService: ToastrService,
              private basespaceService: BasespaceService,
              private s3FileUploadService: S3DirectUploadService) {
  }

  loadVolumeName(): Observable<string> {
    return this.basespaceService.GetV2UsersCurrentProperties({}).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
      map((properties => {
        return this.getGdsVolumeName(properties.Items);
      })));
  }

  loadProjectId(): Observable<string> {
    const projectRequestParams: any = {Name: REFERENCE_FILES_PROJECT_NAME};
    return this.httpClient.get<IApiResponseWrapper<any>>(BsApiEndPoints.v1GetProject, {params: projectRequestParams}).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
      map(((res) => {
        const items = (res.Response || {}).Items;
        if (items && items.length > 0) {
          return items[0].Id;
        }
        return null;
      })));
  }

  /**
   * Get gds volume name from property
   * @param properties
   */
  private getGdsVolumeName(properties: any[], propertyName: string = GDS_VOLUME_PROPERTY_NAME): string {
    for (let i = 0; i < properties.length; i++) {
      const prop = properties[i];
      if (isString(prop.Name) && prop.Name.toLowerCase() === propertyName.toLowerCase()) {
        return get(prop, 'Content');
      }
    }
    return null;
  }

  /**
   * Get gds file list
   */
  public getFileListFromGds = (folderPath: string): Observable<FileListResponse> => {
    const paths: string[] = [
      `${folderPath}/*`
    ];
    return this.loadVolumeName().pipe(
      flatMap(volumeName => {
        if (volumeName) {
          return this.fileService.listFiles({pageSize: PAGE_SIZE, volumeName: [volumeName], path: paths, isUploaded: true});
        } else {
          const resp: FileListResponse = {items: [], itemCount: 0, totalItemCount: 0, totalPageCount: 0};
          return of(resp);
        }
      }));
  }


  /**
   * function to perform upload custom file
   * @param destinationPath [GSSAppId]/[fieldId] example: avd.e3d80112cdbd444a832c987ce44a8afc/RnaGeneAnnotationFile
   * @param file
   * @param success
   * @param fail
   */
  public uploadCustomFile(destinationPath: string, file: File): Observable<boolean> {
    // if exceed max supported size
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      this.toastrService.error(largeFileUploadFailMessage);
      return of(false);
    }
    let datasetId = null;

    return this.getOrCreateDataset(destinationPath, file.name).pipe(
      flatMap(dataset => {
        datasetId = dataset.Id;
        return this.updateUploadStatus(datasetId, MultipartUploadStatus.STARTED);
      }),
      flatMap(() => {
        return this.getUploadTemporaryUploadCredentials(datasetId);
      }),
      flatMap((credentials) => {
        return this.startFileUpload(credentials, file);
      }),
      flatMap((eTag: string) => {
        return this.reportUploadedSize(datasetId, file, eTag);
      }),
      flatMap(() => {
        return this.onUploadCompleted(datasetId);
      }),
      catchError(err => {
        ConsoleLogger.logError(err);
        this.toastrService.error(uploadFailMessage);
        return this.onUploadFailed(datasetId);
      })
    );
  }

  /**
   * function to perform upload custom file
   * @param destinationPath
   * @param projectId
   * @param file
   */
  public uploadCustomFileForSpecifiedProject(projectId: string, destinationPath: string = '', file: File): Observable<V1pre3FileCompact> {
    // if exceed max supported size
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      throw new Error(largeFileUploadFailMessage);
    }
    let datasetId = null;
    let appSessionId = null;

    return this.createDatasetInProject(projectId, destinationPath, file.name).pipe(
      switchMap(dataset => {
        datasetId = dataset.Id;
        appSessionId = dataset.AppSession.Id;
        this.updateUploadStatus(datasetId, MultipartUploadStatus.STARTED);
        return this.getUploadTemporaryUploadCredentials(datasetId);
      }),
      switchMap((credentials) => {
        return this.startFileUpload(credentials, file);
      }),
      switchMap((eTag: string) => {
        return this.reportUploadedSize(datasetId, file, eTag);
      }),
      switchMap(() => {
        return this.setAppSessionStatus(appSessionId, AnalysisStatus.Complete);
      }),
      switchMap(() => {
        this.onUploadCompleted(datasetId);
        return this.basespaceService.GetV2DatasetsIdFilesResponse(
          {
            id: datasetId,
            turbomode: false,
            filehrefcontentresolution: false,
            excludevcfindexfolder: false,
            excludesystemfolder: false,
            excludeemptyfiles: false,
            excludebamcoveragefolder: false
          }
        );
      }),
      switchMap(files => {
        if (!(files.body) || files.body.Items.length === 0) {
          throw new Error(uploadFailMessage);
        }
        return of(files.body.Items[0]);
      }),
      catchError(err => {
        this.onUploadFailed(datasetId);
        this.setAppSessionStatus(appSessionId, AnalysisStatus.Aborted);
        throw err;
      })
    );
  }

  /**
   * sets app session status to complete to ensure no zombie appsessions for uploading a file
   * @param appSessionId - app session id
   * @returns Observable with V2AppSession
   */
  private setAppSessionStatus(appSessionId: string, status: string) {
    return this.basespaceService.PostV2AppsessionsIdResponse({
      id: appSessionId,
      payload: { ExecutionStatus: status }
    });
  }

  /**
   * get or create dataset for custom file
   * @param path - destination path
   * @param fileName - file name
   * @returns promise with dataset id
   */
  private getOrCreateDataset(path: string, fileName: string): Observable<V2Dataset> {
    return this.getReferenceFilesProjectId().pipe(
      switchMap(projectId => {
        return this.createDatasetInProject(projectId, path, fileName);
      }),
      catchError((error) => {
        return of(null);
      }));
  }

  private getReferenceFilesProjectId(): Observable<string> {
    return this.loadProjectId().pipe(flatMap(projectId => {
      if (projectId) {
        return of(projectId);
      } else {
        return this.createReferenceProject().pipe(map(project => {
          return project.Id;
        }));
      }
    }));
  }

  /**
   * create project with name 'Reference Files'
   * @returns promise with project id
   */
  private createReferenceProject(): Observable<V1pre3Project> {
    const param: any = {
      name: REFERENCE_FILES_PROJECT_NAME,
      description: REFERENCE_FILES_PROJECT_DESC
    };
    return this.basespaceService.PostV2Projects(param);
  }

  /**
   *
   * Create dataset in project
   * @param projectId
   * @param fileName
   * @returns promise with dataset id
   */
  private createDatasetInProject(projectId: string, path: string, fileName: string): Observable<V2Dataset> {
    const param: any = {
      id: projectId,
      payload: {
        Id: projectId,
        Name: fileName,
        DatasetTypeId: REFERENCE_FILES_DATASET_TYPE,
        Attributes: {
          Gds: {
            ResourceTypeFolderName: RESOURCE_TYPE_FOLDER_NAME,
            'RootFolder.Path': path
          }
        }
      }
    };
    return this.basespaceService.PostV2ProjectsIdDatasetsResponse(param).pipe(map(data => data.body));
  }

  private updateUploadStatus(datasetId: string, status: MultipartUploadStatus): Observable<boolean> {
    const path = `${environment.apiEndpoint.endsWith('/') ? environment.apiEndpoint : environment.apiEndpoint + '/'}${UPLOAD_STATUS_PATH}`
      .replace(PLACEHOLDER_DATASET_ID, datasetId)
      .replace(PLACEHOLDER_STATUS, status);
    return this.httpClient.post<any>(path, null).pipe(map(res => {
      return true;
    }));
  }

  /**
   * get temporary direct upload credentials from backend
   * @param datasetId
   */
  private getUploadTemporaryUploadCredentials(datasetId: string): Observable<UploadCredential> {
    const path = this.getDirectUploadInfoUrl(datasetId);
    return this.httpClient.get(path, {
      headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'}),
      withCredentials: true
    }).pipe(map((res: any) => {
      return res as UploadCredential;
    }));
  }

  /**
   * user S3DirectUploadService to perform SINGLE file upload.
   * Since S3 using callback, we need to convert it to promise so that we can use promise chain
   * @param credentials
   * @param file
   * @returns promise with ETag as string
   */
  private startFileUpload(credentials: UploadCredential, file: File): Observable<string> {
    return Observable.create(observer => {
      this.s3FileUploadService.startFileUpload(credentials, file, (err, tag: string) => {
        if (err) {
          throw throwError(err);
        } else {
          observer.next(tag);
        }
        observer.complete();
      });
    });
  }

  /**
   * report file upload completion to update uploaded size
   * @param datasetId
   * @param file
   * @param eTag
   */
  private reportUploadedSize(datasetId: string, file: File, eTag: String): Observable<any> {
    const fileParam = {
      ContentType: file.type,
      Size: file.size,
      RelativePath: file.name,
      Etag: eTag,
      IsDeleted: false
    };

    const param = {
      Files: [fileParam]
    };

    return this.httpClient.post(this.getDirectUploadInfoUrl(datasetId), param);
  }

  private onUploadCompleted(datasetId: string) {
    return this.updateUploadStatus(datasetId, MultipartUploadStatus.COMPLETED);
  }

  private onUploadFailed(datasetId: string) {
    return this.updateUploadStatus(datasetId, MultipartUploadStatus.ABORTED);
  }

  private getDirectUploadInfoUrl(datasetId: string): string {
    return `${environment.apiEndpoint.endsWith('/') ? environment.apiEndpoint : environment.apiEndpoint + '/'}${DIRECT_UPLOAD_INFO_PATH}`.replace(PLACEHOLDER_DATASET_ID, datasetId);
  }
}

enum MultipartUploadStatus {
  STARTED = 'Started',
  ABORTED = 'Aborted',
  COMPLETED = 'Completed'
}
