import { Constants as AgGridConstants, GridOptions } from 'ag-grid-community';
import { CustomColDef } from '@bssh/comp-lib/lib/components/data-table/custom-colDef';
import { AppSearchColumnFields } from './app-search-grid-options-formatter';
import { BiosampleSearchColumnFields } from './biosample-search-grid-options-formatter';
import { FileSearchColumnFields } from './file-search-grid-options-formatter';
import { ProjectSearchColumnFields } from './project-search-grid-options-formatter';
import { RunSearchColumnFields } from './run-search-grid-options-formatter';
import { SampleSearchColumnFields } from './sample-search-grid-options-formatter';

export type SearchColumnFields =
    ProjectSearchColumnFields |
    RunSearchColumnFields |
    AppSearchColumnFields |
    BiosampleSearchColumnFields |
    SampleSearchColumnFields |
    FileSearchColumnFields;
export interface OverrideColumnDefinition<ColumnFields extends SearchColumnFields> extends CustomColDef {
    colId: ColumnFields;
}

export interface OverrideColumnParameters<ColumnFields extends SearchColumnFields> {
    inclusiveColumns?: SearchColumnFields[];
    overrideColumnDefinitions?: OverrideColumnDefinition<ColumnFields>[];
}
export abstract class SearchGridOptionsFormatter<ColumnFields extends SearchColumnFields> {
    public gridOptions: GridOptions;

    public getGridOptions(
        overrideGridOptions?: Partial<GridOptions>,
    ): GridOptions {
        const defaultGridOptions = {
            rowModelType: AgGridConstants.ROW_MODEL_TYPE_SERVER_SIDE,
            columnDefs: this.getColumnDefinitions(),
            rowData: null,
            rowSelection: 'single',
            domLayout: 'autoHeight',
            suppressRowClickSelection: true,
            pagination: true,
            paginationPageSize: 25,
            cacheBlockSize: 100,
            serverSideSortingAlwaysResets: true,
            onGridReady: (event) => {
                event.api.sizeColumnsToFit();
                this.gridOptions.api = event.api;
            }
        };
        this.gridOptions = Object.assign(defaultGridOptions, overrideGridOptions);
        return this.gridOptions;
    };

    protected abstract getDefaultColumnDefinitions(): CustomColDef[];
    protected abstract getOverrideColumnParameters(): OverrideColumnParameters<SearchColumnFields>;

    protected getColumnDefinitions(): CustomColDef[] {
        const defaultColumnDefinitions = this.getDefaultColumnDefinitions();
        const overrideColumnParameters = this.getOverrideColumnParameters();
        if(!overrideColumnParameters) {
            return defaultColumnDefinitions;
        } else {
            const columnDefinitions = this.applyOverridingColumnParameters(
                defaultColumnDefinitions,
                overrideColumnParameters
            );
            return columnDefinitions;
        }
    }

    private applyOverridingColumnParameters(
        defaultColumnDefinitions: CustomColDef[],
        overrideColumnParameters?: OverrideColumnParameters<SearchColumnFields>
    ): CustomColDef[] {
        let updatedColumnDefinitions = defaultColumnDefinitions;
        const { inclusiveColumns, overrideColumnDefinitions } = overrideColumnParameters;

        if(inclusiveColumns) {
            updatedColumnDefinitions = [];
            inclusiveColumns.forEach((columnField) => {
                const columnDefinition = defaultColumnDefinitions
                    .find((defaultColumnDefinition) => defaultColumnDefinition.colId === columnField);

                if(!columnDefinition) {
                    throw new Error(`Could not find column definition for ${columnField}`);
                }
                updatedColumnDefinitions.push(columnDefinition);
            });
        }

        if(overrideColumnDefinitions) {
            overrideColumnDefinitions.forEach((overrideColumnDefinition: OverrideColumnDefinition<ColumnFields>) => {
                const updatedColumnDefinition = updatedColumnDefinitions
                    .find((columnDefinition) => columnDefinition.colId === overrideColumnDefinition.colId);

                if(!updatedColumnDefinition) {
                    throw new Error(`Could not find column definition for ${overrideColumnDefinition.colId}`);
                }
                Object.assign(updatedColumnDefinition, overrideColumnDefinition);

                // ag-grid does not allow mixing of cellRendererSelector and cellRendererFramework
                if (overrideColumnDefinition.cellRendererSelector && updatedColumnDefinition.cellRendererFramework) {
                    delete updatedColumnDefinition.cellRendererFramework;
                } else if (overrideColumnDefinition.cellRendererFramework && updatedColumnDefinition.cellRendererSelector) {
                    delete updatedColumnDefinition.cellRendererSelector;
                }
            });
        }

        return updatedColumnDefinitions;
    }


}


