/**
 * Class representing a DataLocationUri
 * Format: icaapi://projects/${projectId}/data/${dataId}#${fileName}
 */
export class DataLocationUri {
  private static readonly REGEX_PATTERN = /icaapi:\/\/projects\/(.*)\/data\/([^#]*)#?(.*)?/g;

  private readonly _projectId: string;
  public get projectId(): string {
    return this._projectId;
  }

  private readonly _dataId: string;
  public get dataId(): string {
    return this._dataId;
  }

  private readonly _fileName: string;
  public get fileName(): string {
    return this._fileName;
  }

  private readonly _uriString: string;

  constructor(projectId: string, dataId: string, fileName: string) {
    if (!projectId || (projectId.trim() === '')) {
      throw new Error('projectId must not be empty');
    }

    if (!dataId || (dataId.trim() === '')) {
      throw new Error('dataId must not be empty');
    }

    fileName = fileName ? fileName.trim() : '';

    this._projectId = projectId;
    this._dataId = dataId;
    this._fileName = fileName;

    this._uriString = `icaapi://projects/${this._projectId}/data/${this._dataId}`;
    if (fileName.length > 0) {
      this._uriString += `#${this._fileName}`;
    }
  }

  /**
   * Parse a URI string into its components
   * @param uri a DataLocationURI string
   */
  static parse(uri: string): DataLocationUri {
    const groups = new RegExp(this.REGEX_PATTERN).exec(uri);
    const projectId = groups[1];
    const dataId = groups[2];
    const fileName = groups[3];

    return new DataLocationUri(projectId, dataId, fileName);
  }

  public toString(): string {
    return this._uriString;
  }
}
