import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {ComboBoxComponent} from '@app/cloud-run-prep/directives/combobox/combobox.component';
import {ControlValueAccessor} from '@angular/forms';
import {FormGroup} from '@angular/forms';
import {LOCAL_FILE_NAME_PREFIX} from '@app/run-planning/constants';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {FileListResponse} from '@stratus/gds-ng-sdk';
import {map} from 'rxjs/operators';
import {sortBy} from 'lodash';
import {ConfigSetupState} from '@app/run-planning/config-setup/config-setup.state';
import {SimpleOption} from '@app/core/store/cloud-run-prep/run-setup.state';
import {CUSTOM_FILE_INDEX} from '@app/cloud-run-prep/constants';
import { FileOptionsCacheService } from '@app/run-planning/services/file-options-cache/file-options-cache.service';
import { MapperService } from '@app/run-planning/services/mapper.service';
import {IFormField} from '@app/run-planning/model/form-field';
import {GroupedOption} from '@app/run-planning/model/option';
import { IDynamicLoadingComponent } from '@app/run-planning/interface';

@Component({
  selector: 'app-file-select-dropdown',
  templateUrl: './file-select-dropdown.component.html',
  styleUrls: ['./file-select-dropdown.component.scss']
})
export class FileSelectDropdownComponent implements OnInit, ControlValueAccessor, IDynamicLoadingComponent {
  public hasCustomOption$ = of(false);
  public groupedChoices$: Observable<GroupedOption[]>;

  @Input() parentFormGroup: FormGroup;
  @Input() formField: IFormField;
  @Input() analysisVersionDefinitionId: string;
  @Input() supportLocalUpload = true;
  @Input() supportUnselect = false;

  @ViewChild('fileSelectCombobox', { static: true })
  fileSelectComponent: ComboBoxComponent;

  @Input()
  state: ConfigSetupState;

  stateChangedEvent = new EventEmitter<Partial<ConfigSetupState>>();

  private loadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.loadingSubject.asObservable();

  constructor(
    private mapper: MapperService,
    private fileOptionsCacheService: FileOptionsCacheService
  ) { }

  ngOnInit() {
    this.loadFileOptions();
  }

  loadFileOptions() {
    this.loadingSubject.next(true);
    this.fileOptionsCacheService.getOptions$(this.analysisVersionDefinitionId, this.formField).subscribe(x => {
      this.loadingSubject.next(false);
        this.groupedChoices$ = of(x);
        this.hasCustomOption$ = of(x.length > 1);
    });
  }

  handleLocalFile(event: any, fieldId: string) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.groupedChoices$ = of(this.fileOptionsCacheService.uploadLocalFileAndUpdateCache(this.analysisVersionDefinitionId, fieldId, file));
    this.parentFormGroup.controls[fieldId].patchValue(this.fileOptionsCacheService.getLocalFileValue(file.name));
  }

  handleGdsFile(event: any, fieldId: string) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.groupedChoices$ = this.fileOptionsCacheService.uploadGdsFileAndUpdateCache$(this.analysisVersionDefinitionId, fieldId, file)
      .pipe(map((groupedChoices) => {
        const newlyUploadedFile = groupedChoices[CUSTOM_FILE_INDEX].options.find(x => x.text === file.name);
        this.parentFormGroup.controls[this.formField.id].patchValue(newlyUploadedFile.value);
        return groupedChoices;
      }));
  }

  updateGdsFieldChoice(fieldId: string, filesResponse: FileListResponse) {
    const existingConfig = this.state;
    const fields = existingConfig.analysisSettingsFormFields;
    const field = fields.find(x => x.id === fieldId);
    field.groupedChoices[CUSTOM_FILE_INDEX].options = this.mapper.mapFileOptions(filesResponse.items);

    this.state.analysisSettingsFormFields = fields;
    this.stateChangedEvent.emit(this.state);
  }

  uploadLocalFile(file: File, fieldId: string) {
    const existingConfig = this.state;
    const fields = existingConfig.analysisSettingsFormFields;
    // Get file listing if fileSelect field is there
    const fileField = fields.find(field => field.type === 'fileSelect' && field.id === fieldId);
    const newOptionText = `${file.name} - (Local)`;
    const newOptionValue = `${LOCAL_FILE_NAME_PREFIX}${file.name}`;
    const newOption: SimpleOption = { value: newOptionValue, text: newOptionText };
    this.addNewFileOptions(fileField, newOption);

    this.state.analysisSettingsFormFields = fields;
    this.stateChangedEvent.emit(this.state);
  }

  private addNewFileOptions(fileField: any, newOption: SimpleOption) {
    if (!fileField.groupedChoices[0].options) {
      fileField.groupedChoices[0].options = [];
    }
    const customFileIndex = fileField.groupedChoices[0].options.findIndex(x => x.value.includes(LOCAL_FILE_NAME_PREFIX));
    if (customFileIndex > -1) {
      fileField.groupedChoices[0].options.splice(customFileIndex, 1);
    }
    fileField.groupedChoices[0].options.push(newOption);
    fileField.groupedChoices[0].options = sortBy(fileField.groupedChoices[0].options, f => f.text);
  }


  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }
}
