<ng-container [formGroup]="parentFormGroup">
  <ng-container *ngIf="!(isLoading$ | async); else loadingOptionsSkeleton">
    <div hidden class="my-3">
      <label for="fileInput" class="mr-2">Upload reference file</label>
      <input #fileInput [attr.id]="fileInput" type="file" (change)="handleAddNewReferenceFile($event)">
    </div>
    <combobox [formControlName]="referenceFileFormControlName" [enableDeleteBtn]="true"
              label="Reference File" #referenceFileSelectCombobox
              data-cy="referenceFile">
      <ng-container *ngIf="!disableReferenceFileUpload">
        <!-- Open modal to upload new reference file -->
        <combobox-option
          [isCustomLink]="true"
          class="custom-link"
          label="+ Add Custom Reference File"
          (customLinkSelect)="fileInput.click()"
        >
        </combobox-option>
      </ng-container>
      <!-- Reference File Grouped (Custom/Standard) Options -->
      <ng-container *ngFor="let group of referenceFileOptions">
        <!-- Group Label (Custom/Standard) -->
        <combobox-option [isHeader]="true" [label]="group.groupName"></combobox-option>
        <!-- Reference File options -->
        <combobox-option *ngFor="let options of group.options"
                          [value]="options.value"
                          [label]="options.text">
        </combobox-option>
      </ng-container>
    </combobox>
  </ng-container>

  <!-- Loading Skeleton Options -->
  <ng-template #loadingOptionsSkeleton>
    <lib-skeleton type="text"
                  [lines]="1">
    </lib-skeleton>
  </ng-template>
</ng-container>
