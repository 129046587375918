import {Injectable} from '@angular/core';
import {
  AnalysisDefinitionCompactAnalysisDefinitionSortFieldsPagedItems, AnalysisVersionDefinition,
  AnalysisVersionDefinitionCompactItemList,
  LibraryPrepKit
} from '@stratus/gss-ng-sdk';
import {GSSKitsSortUtilities} from '@app/core/utilities/gss-kits-sort-utilities';
import {isEmpty} from 'lodash';
import {AnalysisDefinitionGroupedOption, CustomOption} from '@app/core/store/cloud-run-prep/run-setup.state';
import { IRunConfigurationsSummary } from '@app/run-planning/interface';
import { EMPTY_PHYSICAL_FIELD_CONFIG_DISPLAY_TEXT } from '@app/run-planning/constants';
import {RunSetupHelperService} from '@app/cloud-run-prep/run-setup/helper/run-setup-helper.service';
import {CloudRunMapperService} from '@app/core/services/mapper/cloud-run-mapper.service';
import {AnalysisDefinition} from '@app/run-planning/model/analysis-definition';
import {GroupedOption} from '@app/run-planning/model/option';
import {getDragenVersionDisplayName, getCombinedDisplayName} from '@app/run-planning/constants';
import { ConfigSetupState } from '../config-setup/config-setup.state';
import { AnalysisVersionDefinition as RawAnalysisVersionDefinition} from '../model/analysis-version-definition';
import { UserSubscriptionService } from '@app/core/services/user/user-subscription.service';
import {
  AnalysisConfigurationTemplateCompact
} from '@stratus/gss-ng-sdk/api/models/analysis-configuration-template-compact';

@Injectable({
  providedIn: 'root'
})
export class MapperService extends CloudRunMapperService {

  constructor(
    private helper: RunSetupHelperService,
    protected userSubscriptionService: UserSubscriptionService,
  ) {
    super(userSubscriptionService);
  }

  mapAdVersions(
    typeId: string,
    adVersions: AnalysisVersionDefinitionCompactItemList,
    isBclConvertAD = false,
    showDragenVersion: boolean = false,
  ): CustomOption[] {
    return (adVersions || {}).items.map(version => {
      const softwareVersion = (showDragenVersion && version.softwareExecutionVersion) ? version.softwareExecutionVersion : null;
      return {
        id: version.id,
        text: getCombinedDisplayName([version.displayVersion, getDragenVersionDisplayName(softwareVersion)]),
        value: `${typeId}|${version.version}|${version.id}|${version.displayVersion}${softwareVersion ? `|${softwareVersion}` : ''}`,
        ...isBclConvertAD && {originalData: version}
      };
    });
  }

  // TODO aim to remove this method, replaced by mapAnalysisDefinitionsExt
  // Prerequisite: overhaul xxxOption classes/interfaces
  mapAnalysisDefinitions(input: AnalysisDefinitionCompactAnalysisDefinitionSortFieldsPagedItems): AnalysisDefinitionGroupedOption[] {
    if (!input || !input.items) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(input.items
      .filter(t => t.analysisVersions && (t.analysisVersions || {}).items.length > 0))
      .map(analysisDef => {
        const isBclConvertAnalysisDef = this.helper.isGroupedOptionBclConvertAnalysisDefinition(analysisDef);
        return {
          groupName: analysisDef.displayName || analysisDef.name,
          name: analysisDef.name,
          analysisDefinitionId: analysisDef.id,
          options: this.mapAdVersions(analysisDef.id, analysisDef.analysisVersions, isBclConvertAnalysisDef)
        };
      });
  }

  mapAnalysisDefinitionsExt(input: AnalysisDefinition[], showDragenVersion: boolean = false): AnalysisDefinitionGroupedOption[] {
    if (!input) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(input
      .filter(t => t.analysisVersions && (t.analysisVersions || {}).items.length > 0))
      .map(analysisDef => {
        const isBclConvertAnalysisDef = this.helper.isGroupedOptionBclConvertAnalysisDefinition(analysisDef);
        return {
          groupName: analysisDef.displayName || analysisDef.name,
          name: analysisDef.name,
          analysisDefinitionId: analysisDef.id,
          options: this.mapAdVersions(analysisDef.id, analysisDef.analysisVersions, isBclConvertAnalysisDef, showDragenVersion)
        };
      });
  }

  mapActOptionsForConfigSetup(input: AnalysisConfigurationTemplateCompact[]): CustomOption<AnalysisConfigurationTemplateCompact>[] {
    if (!input) {
      return [];
    }
    return input.map(
      item => ({
        id: item.id,
        text: item.name,
        value: item.id,
        originalData: item,
      })
    );
  }

  mapAdVersionsForAct(typeId: string, adVersions: AnalysisVersionDefinitionCompactItemList): CustomOption[] {
    return (adVersions || {}).items.map(version => {
      return {
        id: version.id,
        text: version.displayVersion,
        value: `${typeId}|${version.version}|${version.id}|${version.displayVersion}`
      };
    });
  }

  mapAnalysisDefinitionsForAct(input: AnalysisDefinitionCompactAnalysisDefinitionSortFieldsPagedItems): AnalysisDefinitionGroupedOption[] {
    if (!input || !input.items) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(input.items
      .filter(t => t.analysisVersions && (t.analysisVersions || {}).items.length > 0))
      .map(type => {
        return {
          groupName: type.displayName || type.name,
          name: type.name,
          options: this.mapAdVersionsForAct(type.id, type.analysisVersions)
        };
      });
  }

  mapSortedLibraryPrepKitsFromAnalysisDefinition(analysisVersionDefinition: AnalysisVersionDefinition): CustomOption[] {
    if (!analysisVersionDefinition) {
      return [];
    }
    const compatibleLPKs = (new RawAnalysisVersionDefinition(analysisVersionDefinition)).compatibleLPKs;
    if (!compatibleLPKs) {
      return [];
    }
    return GSSKitsSortUtilities.sortKits(compatibleLPKs
      .filter(t => t.id))
      .map(type => {
        return {
          id: type.id,
          text: type.displayName ? type.displayName : type.name,
          value: type.id,
          originalData: type
        };
      });
  }

  mapSortedCompatibleIndexAdapterKitsFromLibraryPrepKit(libraryPrepKit: LibraryPrepKit): CustomOption[] {
    if (!libraryPrepKit || !libraryPrepKit.libraryPrepKitCompatibleIndexAdapterKits) {
      return [];
    }
    return GSSKitsSortUtilities.sortIAKs(libraryPrepKit.libraryPrepKitCompatibleIndexAdapterKits
      .filter(t => t.id))
      .map(type => {
        return {
          id: type.id,
          text: type.displayName ? type.displayName : type.name,
          value: type.id,
          originalData: type
        };
      });
  }

  mapPhysicalConfigOptions(configs: IRunConfigurationsSummary[]): GroupedOption[] {
    if (!configs) {
      return [];
    }
    const groupedOptions: GroupedOption[] = [];
    for (const config of configs) {
      let groupName:string;
      let dropdownText : string;
      if (Object.keys(config.physicalFormValuesForDisplay).length > 0) {
        groupName = `${config.analysisVersionDefinitionDisplayName}`;
      } else {
        // split from "Illumina BCL Convert - 4.1.20" into name and version num
        const separator = ' - ';
        const name = config.analysisVersionDefinitionDisplayName;
        const separatorIndex = name.indexOf(separator);
        groupName = name.substring(0, separatorIndex);
        // remove AVD name use in mapping fn
        dropdownText = name.substring(separatorIndex + separator.length);
      }
      const groupedOptionIndex = groupedOptions.findIndex(option => option.groupName === groupName);
      if (groupedOptionIndex > -1) {
        groupedOptions[groupedOptionIndex].options.push(this.mapPhysicalConfigToOption(config, dropdownText));
      } else {
        groupedOptions.push({ groupName, options: [this.mapPhysicalConfigToOption(config, dropdownText)] });
      }
    }
    return groupedOptions;
  }

  mapPhysicalConfigToOption(config: Partial<IRunConfigurationsSummary>, dropdownText?: string): CustomOption {
    const value = this.mapPhysicalConfigToValue(config);
    const text = dropdownText ? dropdownText : this.mapPhysicalConfigToText(config);
    return { text: text, value: value, id: value }
  }

  mapPhysicalConfigToText(config: Partial<IRunConfigurationsSummary>): string{
    if (!config || !config.physicalFormValuesForDisplay || isEmpty(config.physicalFormValuesForDisplay)) {
      return EMPTY_PHYSICAL_FIELD_CONFIG_DISPLAY_TEXT;
    }
    return  `${Object.values(config.physicalFormValuesForDisplay).join('+')}`
  }

  mapPhysicalConfigToValue(config: Partial<IRunConfigurationsSummary>): string{
    if (!config || !config.physicalConfig || isEmpty(config.physicalConfig)) {
      return config.analysisVersionDefinitionId;
    }
    return  `${config.analysisVersionDefinitionId}_${Object.values(config.physicalConfig).join('_')}`
  }
}
