import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChildren,
  QueryList,
  ViewRef,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";
import { Observable } from "rxjs";
import { RadioCheckboxComponent } from "@bssh/comp-lib";

@Component({
  selector: "lib-radio-checkbox-group",
  templateUrl: "./radio-checkbox-group.component.html",
  styleUrls: ["./radio-checkbox-group.component.scss"],
  exportAs: "radioCheckboxGroup",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioCheckboxGroupComponent
  implements ControlValueAccessor, OnInit {
  public selectedOptions: any[] | any;
  public isValueSelected: boolean = false;
  public _options: any;
  @Input() set options(value: any) {
    if (this._options && this._options !== value) {
      this.selectedOptions =
        this.type === "checkbox" ? [] : this.selectedOptions;
    }
    this._options = value;
  }
  get options(): any {
    return this._options;
  }
  @Input() public headerLabel: string;
  @Input() public type: "checkbox" | "radio" = "checkbox";
  @Input() public name = "__radioCheckboxGroupName__";
  @Input() public valueProperty = "value";
  @Input() public labelProperty = "label";
  @Input() public disabled: boolean;
  @Input() public required: boolean;
  @Input() public errorMessage = "This is required";
  @Input() public tagProperty = "";

  /**
   * Add function to check before proceeding change
   * Return value to indicate if should proceed or not
   */
  @Input()
  public onBeforeChange?: ($event) => Observable<boolean>;

  @ViewChildren(RadioCheckboxComponent)
  private _radioCheckboxList: QueryList<RadioCheckboxComponent>;

  @Output() public selectionChanged: EventEmitter<any> = new EventEmitter();

  constructor(public cd: ChangeDetectorRef, public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};

  set value(val: any) {
    if (val != undefined) {
      this.selectedOptions = val;
      if(!this.disabled) {
        this._onSelectionChange();
      }
    }
  }
  get value() {
    return this.selectedOptions;
  }

  // set value programmatically
  public writeValue(value: any): void {
    // Somehow WriteValue is called before ngInit
    // Using timeout to fix
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      // In case for radio button value is passed in as array, need to change it to a single value
      // Or for checkbox value is passed in as single value, need to change it to an array
      if (this.type === "checkbox" && value && !Array.isArray(value)) {
        this.selectedOptions = [value];
      } else if (this.type === "radio" && value && Array.isArray(value)) {
        if (value.length > 0 && this.required) {
          this.selectedOptions = value[0];
        } else {
          this.selectedOptions = null;
        }
      } else {
        this.selectedOptions = value;
      }
      if (!this.disabled) {
        this._onSelectionChange(false);
      }
    }, 0);
  }
  // On UI element value changes,
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  // On Touch
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // Update disabled/enabled status
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    setTimeout(() => {
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.markForCheck();
      }
    });
  }

  // Triggers when selection changes
  public onCheckboxChange(index, $event): void {
    const updatedSelectedOptions = this._getUpdateSelection(index, $event);
    // Check if any function needs to be executed before change
    if (this.onBeforeChange) {
      this.onBeforeChange({
        newValue: updatedSelectedOptions,
        oldValue: this.selectedOptions,
      }).subscribe((result: boolean = false) => {
        if (result) {
          // Proceed with testing
          this._updateSelection(updatedSelectedOptions);
        } else {
          this._radioCheckboxList.forEach((x, idx) => {
            // restore checked status
            x.inputRef.nativeElement.checked = this.valueSelected(
              this.options[idx]
            );
          });
        }
      });
    } else {
      this._updateSelection(updatedSelectedOptions);
    }
  }

  private _getUpdateSelection(index, $event) {
    let selectedOptions = this.selectedOptions;
    const val = $event.value || this.options[index][this.valueProperty];;
    if (this.type === "radio") {
      // For radio button, can only select one item
      selectedOptions = val;
    } else if ($event.target && index > -1 && index < this.options.length) {
      // For checkbox, need to update the selected list
      if ($event.target.checked && !this.valueSelected(val)) {
        // Add into selected option
        if (selectedOptions) {
          selectedOptions.push(val);
        } else {
          selectedOptions = [val];
        }
      } else if (!$event.target.checked && selectedOptions) {
        // Remove selected option from list upon unselect
        const indexOfOption = selectedOptions.indexOf(val);
        if (indexOfOption > -1) {
          selectedOptions.splice(indexOfOption, 1);
        }
      }
    }
    return selectedOptions;
  }

  private _updateSelection(updatedSelection) {
    this.selectedOptions = updatedSelection;
    this._onSelectionChange();
  }

  // Generally triggers necessary functions programatically when value changes
  private _onSelectionChange(callOnChange = true): void {
    if (callOnChange) {
      this.onChange(this.selectedOptions);
    }
    this.isValueSelected =
      this.type === "checkbox"
        ? this.selectedOptions && this.selectedOptions.length > 0
        : true;
    this.selectionChanged.emit(this.selectedOptions);
    setTimeout(() => {
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.markForCheck();
      }
    });
  }

  // Check if value in selection
  public valueSelected(value: any): boolean {
    if (this.selectedOptions && value && value[this.valueProperty]) {
      if (this.type === "checkbox") {
        // check checkbox value if value found in selected array
        return this.selectedOptions.indexOf(value[this.valueProperty]) !== -1;
      } else {
        // check radio only if value matches selected option
        return value[this.valueProperty] === this.selectedOptions;
      }
    }
  }

  ngOnInit() {}
}
