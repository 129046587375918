import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { IUserAgreement, AgreementCategory, AgreementStatus, IAgreement } from '@app/core/model/user-agreements/user-agreements';
import { BaseService } from '../../base.service';
import { BasespaceService } from '@bssh/ng-sdk';
import { shareReplay, retryWhen, map, catchError, filter } from 'rxjs/operators';
import { genericRetryWhen } from '@app/core/rxjsutils/rxjs-utilities';
import { BsMetaDataService } from '../meta/bs-meta-data.service';
import { HttpUtilityService } from '../../http-utility/http-utility.service';
import { HttpClient } from '@angular/common/http';


/**
 * A service to interact with BSSH Agreements API
 */
@Injectable({
  providedIn: 'root'
})
export class BsUserAgreementsService extends BaseService {

  private tcAgreementsCategoryTitle = 'main website terms and conditions agreement';

  private readonly hasSignedTcAgreementSubject = new ReplaySubject<boolean>(1);

  /**
   * An Observable which indicates whether the user is authenticated
   */
  public hasSignedTcAgreement$ = this.hasSignedTcAgreementSubject.asObservable();

  private hasSignedTcAgreement = false;

  constructor(
    private basespaceApi: BasespaceService,
    private bsMetaDataService: BsMetaDataService,
    private httpUtilityService: HttpUtilityService
  ) {
    super();
  }

  getUserAgreements(queryParams: BasespaceService.GetV2UseragreementsParams): Observable<IUserAgreement[]> {
    const { include, category, applicationid } = queryParams;

    const agreementsRequest = {
      include: include ? include : null,
      category: category ? category.toString() : null,
      applicationid: applicationid ? applicationid : null
    }

    return this.basespaceApi.GetV2Useragreements(agreementsRequest).pipe(
      shareReplay(1),
      retryWhen(genericRetryWhen()),
      map(apiResponse => apiResponse.Items)
    );
  }

  getAgreements(agreementParams: BasespaceService.GetV2AgreementsParams): Observable<IAgreement[]> {
    return this.basespaceApi.GetV2Agreements(agreementParams).pipe(
      shareReplay(1),
      retryWhen(genericRetryWhen()),
      map(apiResponse => apiResponse.Items)
    );
  }


  getPendingUserAgreements(category: AgreementCategory): Observable<IUserAgreement[]> {
    return this.getUserAgreements({ include: [AgreementStatus.PENDING], category }).pipe(
      map((userAgreements: IUserAgreement[]) => {
          return userAgreements.filter((userAgreement) => userAgreement.Status === AgreementStatus.PENDING)
        }
      )
    );
  }

  /**
   * Checks if the user has signed the main website Terms and conditions(TC) agreement.
   */
  checkForSignedUserTCAgreement(): Observable<boolean> {
    return this.getPendingUserAgreements(AgreementCategory.USER).pipe(
      map(pendingAgreements => {
        if (pendingAgreements != null && pendingAgreements.length > 0) {
          const hasSigned = pendingAgreements.some(agreement => agreement.Status.toLowerCase() === AgreementStatus.SIGNED &&
            agreement.Agreement.Title.toLowerCase() === this.tcAgreementsCategoryTitle);
          this.hasSignedTcAgreementSubject.next(hasSigned);
          return hasSigned;
        }
        return true;
      }),
      catchError((error) => {
        // Probably better to just return true instead of defaulting to a re-direct.
        return of(true);
      })

    );
  }

  /**
   * Redirects to TC agreements page.
   */
  redirectToTCAgreementsPage() {
    // To do remove after proxy set up is complete
    // since a Nginx proxy is not fully in place, we are using two domains(one for new website, another for old)
    // Once all the proxying is set up, getting the main website url from meta data api will not be necessary
    // It should be as simple as getting the base url from the current request url(using document api).
    this.bsMetaDataService.getBasespaceAppMetadata().subscribe({
      next: (metaData) => {
        const userTCAgreementUrl = this.getTCAgreementAcceptUrl(metaData.Response.HrefOAuthAuthorizeDialog);
        this.httpUtilityService.redirectToUrl(userTCAgreementUrl);
      }
    }
    );
  }

  setUserTCSignedStatus(): void {
    if (!this.hasSignedTcAgreement) {
      this.hasSignedTcAgreement = true;
      this.hasSignedTcAgreementSubject.next(this.hasSignedTcAgreement);
    }
  }

  public acceptUserAgreement(agreementId: string): Observable<any> {
    return this.basespaceApi.PostV2Useragreements({ AgreementId: agreementId }).pipe(
      shareReplay(1),
      retryWhen(genericRetryWhen())
    );
  }


  public getAgreement(agreementId: string): Observable<IAgreement> {
    return this.basespaceApi.GetV2AgreementsId(agreementId);
  }


  private getTCAgreementAcceptUrl(baseWebsiteUrl: string): string {
    const baseWebSiteUrl = this.httpUtilityService.getBaseUrl(baseWebsiteUrl);
    return `${baseWebSiteUrl}/agreements/accept?returnUrl=${this.httpUtilityService.getCurrentRequestUrl()}`;
  }

}
