import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ControlContainer, FormGroup, ControlValueAccessor } from '@angular/forms';
import { catchError, finalize, first, map, switchMap } from 'rxjs/operators';
import { get, isEmpty, sortBy } from 'lodash';
import { SubSink } from 'subsink';
import {
  GROUPED_OPTIONS_STANDARD_GROUP_INDEX,
  RunSetupHelperService
} from '@app/cloud-run-prep/run-setup/helper/run-setup-helper.service';
import { GroupedOption } from '@app/run-planning/model/option';
import { IDynamicLoadingComponent } from '@app/run-planning/interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GssApiService } from '@app/run-planning/services/gss-api-service';
import { MapperService } from '@app/run-planning/services/mapper.service';
import { NUM_DROPDOWN_OPTIONS_LIMIT } from '@app/run-planning/constants';

@Component({
  selector: 'app-genome-dropdown',
  templateUrl: './genome-dropdown.component.html',
  styleUrls: ['./genome-dropdown.component.scss']
})
export class GenomeDropdownComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy, IDynamicLoadingComponent {
  /* Genome form control Id (field Id) in the formGroup */
  @Input() genomeFormControlName: string;
  /* Field label (for displaying combobox error) */
  @Input() fieldLabel: string;
  @Input() analysisVersionDefinitionId: string;
  @Input() filterBySampleSheetFormat: string = null ;
  @Input() settings: any;
  /* Show hyper link in description */
  @Input() showHyperlinkInDescription: boolean = true;
  referenceGenomeOptions: GroupedOption[] = [];
  hashTableVersion: string = null;

  private loadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.loadingSubject.asObservable();

  private subs = new SubSink();

  constructor(
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef,
    private gssApiService: GssApiService,
    private mapper: MapperService,
    private helper: RunSetupHelperService
  ) { }

  get parentFormGroup(): FormGroup { return this.controlContainer.control as FormGroup; }
  get genomeControl() { return this.parentFormGroup.get(this.genomeFormControlName); }

  public genomeDescription = '';

  ngOnInit() {
    this.subs.sink = this.genomeControl.valueChanges.pipe(
      switchMap(value =>
        this.isLoading$.pipe(
          first(),
          map(isLoading => {return {value, isLoading}})
        )
      )
    ).subscribe((response) => {
      if (!response.isLoading) {
        this.updateSelectedGenomeDescription(response.value);
      }
    })
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings) {
      this.hashTableVersion = get(this.settings, 'hashTableVersion', null);
    }
    if (changes.analysisVersionDefinitionId) {
      // Wait for genome list to finish loading before triggering downstream events that involve selected genome value
      this.loadGenomeList().subscribe(() => {
        this.updateSelectedGenomeDescription(this.genomeControl.value);
        this.cdr.detectChanges();
      })
    }
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private updateSelectedGenomeDescription(genomeId) {
    // Only need to search within standard genome, as only standard genome has description
    const selectedStandardGenomeOption = this.referenceGenomeOptions[GROUPED_OPTIONS_STANDARD_GROUP_INDEX].options
      .find(option => option.value === genomeId);
    this.genomeDescription = selectedStandardGenomeOption ? get(selectedStandardGenomeOption, 'originalData.description', '') : '';
  }

  private loadGenomeList() {
    if (!this.analysisVersionDefinitionId) {
      this.referenceGenomeOptions = [];
      return;
    }
    return this.getGenomeOptions$(this.analysisVersionDefinitionId, this.filterBySampleSheetFormat, this.hashTableVersion)
      .pipe(
        first(),
        map((groupedOptions) => {
          this.referenceGenomeOptions = groupedOptions;
          return of();
        }),
        catchError(error => {
          this.referenceGenomeOptions = [];
          throw error;
        }),
        finalize(() => {
          /*
            Ensure that isLoading is set to false ONLY when referenceGenomeOptions has data.
            If isLoading is set to false prematurely, combobox might be rendered when referenceGenomeOptions is still empty,
            and combobox will clear the selected value (i.e. genomeControl.value) because options is empty.
            As a result, the selected value will remain empty even after referenceGenomeOptions has been populated.
          */
          this.loadingSubject.next(false);
        })
      )
  }

  private getGenomeOptions$(analysisVersionDefinitionId: string, sampleSheetFormatFiltering?: string,
    hashTableVersion?: string): Observable<GroupedOption[]> {
    const requestParams = {
      analysisVersionDefinitionId,
      pageSize: NUM_DROPDOWN_OPTIONS_LIMIT,
      hashTableVersion
    };
    return this.gssApiService.listGenomes(requestParams)
      .pipe(
        map(genomeList => {
          if (!genomeList || isEmpty(genomeList.items)) {
            throw new Error('Genome response contains no genome items');
          }
          let filteredGenomeList;
          if(sampleSheetFormatFiltering){
             filteredGenomeList = genomeList.items.filter((genome)=> (sampleSheetFormatFiltering in genome));
          }
          else{
            filteredGenomeList = genomeList.items;
          }
          const unsorted = this.mapper.mapGenomeOptions(filteredGenomeList);
          const sorted = sortBy(unsorted, item => item.text.toUpperCase());
          const groupedGenomes = this.helper.groupByCustomAndStandardOptions(sorted, 'Genomes');
          return groupedGenomes;
        })
      );
  }
}
