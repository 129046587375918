import { Injectable } from '@angular/core';
import environment from '@environments/environment';
import _ from 'lodash';
import { BsApiEndPoints } from '../bs-api/endpoints';

export interface ISiteNavigationLinksService {
  projectListUrl(): string;
  projectSummaryUrl(id: string): string;
}

/**
 * Formats site navigation urls
 */
@Injectable({
  providedIn: 'root'
})
export class SiteNavigationLinksService implements ISiteNavigationLinksService {

  private projectsRoute = '/projects/';
  private analysesRoute = '/analyses/';
  private bioSamplesRoute = '/biosamples/';
  private sampleRoute = '/sample/';
  private runRoute = '/run/';
  private appsRoute = '/apps/';
  private poolsRoute = '/pools/';
  private labRoute = '/lab/';
  
  constructor() { }

  public projectListUrl(): string { return this.projectsRoute; }

  public projectSummaryUrl(id: string): string { return this.projectsRoute + id + '/'; }

  public analysisSummaryUrl(id: string, queryParams?: { projectId?: string }): string {
    return !queryParams ? `${this.analysesRoute}${id}/` : `${this.analysesRoute}${id}/?${BsApiEndPoints.getQueryParamsUrl(queryParams)}`;
  }

  public bioSampleSummaryUrl(id: string): string { return this.bioSamplesRoute + id + '/'; }

  public sampleSummaryUrl(id: string, name: string): string { return `${this.sampleRoute}${id}/${name}`; }

  public runSummaryUrl(id: string): string { return this.runRoute + id + '/'; }

  public appSummaryUrl(id: string): string  { return `${this.appsRoute}${id}`; }

  public fileSummaryUrl(analysisId: string, fileId: string): string {
      return `${this.analysisSummaryUrl(analysisId)}files/${fileId}`;
  }

  public poolsListUrl(): string { return this.poolsRoute; }

  public poolsDetailsPageUrl(id: string): string { return `${this.poolsListUrl()}${id}`; }

  public labNeoPrepUrl(id: string): string { return `${this.labRoute}neoprep/${id}`; }

  public labLibraryPlatesUrl(id: string): string { return `${this.labRoute}library-plates/${id}`; }

  /**
   * Returns a regex to identify page urls for which, no authorization is required to access
   */
  public get noAuthPageUrlsRegex(): RegExp {
    if (!_.isEmpty(environment.noAuthRoutes)) {
      return new RegExp(environment.noAuthRoutes, 'i');
    }
    return new RegExp('');
  }

  // Add more navigation links as necessary.
}
