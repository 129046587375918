import * as gss from '@stratus/gss-ng-sdk';
import {assign, filter, find, get} from 'lodash';
import {IndexSequence, IndexStrategy} from '@stratus/gss-ng-sdk';

export class IndexAdapterKit implements gss.IndexAdapterKit {
  id?: string;
  urn?: string;
  href?: string;
  canUpdate?: boolean;
  name?: string;
  displayName?: string;
  organization?: string;
  isIllumina?: boolean;
  description?: string;
  allowedIndexStrategies?: Array<string>;
  adapterSequenceRead1?: string;
  adapterSequenceRead2?: string;
  settings?: gss.IndexAdapterKitSettingsResponse;
  tenantId?: string;
  subTenantId?: string;
  acl?: Array<string>;
  indexSequences?: Array<gss.IndexSequence>;
  compatibleLibraryPrepKits?: Array<gss.LibraryPrepKitCompact>;
  numCyclesIndex1?: number;
  numCyclesIndex2?: number;
  createdByClientId?: string;
  createdBy?: string;
  modifiedBy?: string;
  timeCreated?: string;
  timeModified?: string;

  constructor(obj: gss.IndexAdapterKit) {
    if (!obj) {
      return;
    }

    assign(this, obj);
  }

  /**
   * Change an object's type to IndexAdapterKit.
   * This makes the methods defined in IndexAdapterKit available for that object.
   */
  public static wrap(obj: gss.IndexAdapterKit): IndexAdapterKit {
    return obj ? Object.setPrototypeOf(obj, IndexAdapterKit.prototype) : null;
  }

  get isUnspecified(): boolean {
    return (this.id === UNSPECIFIED_INDEX_ADAPTER_KIT.id);
  }

  public static get UNSPECIFIED(): IndexAdapterKit {
    return IndexAdapterKit.wrap(UNSPECIFIED_INDEX_ADAPTER_KIT);
  }

  public getIndexNameBySequence(sequence: string, readNumber: number): string {
    if (!sequence) { return ''; }
    const selectedIndex: IndexSequence = find(this.indexSequences, {sequence, readNumber});
    return get(selectedIndex, 'name', '');
  }

  public get defaultIndexStrategy(): IndexStrategy {
    return get(this, 'settings.defaultIndexStrategy', IndexStrategy.DUAL);
  }

  public getIndexSequenceByNameAndReadNumber(indexName: string, readNumber: number): string {
    if (this.indexSequences === null) {
      return '';
    }
    const selectedIndex = filter(this.indexSequences, {
      name: indexName, readNumber
    });
    if (selectedIndex.length > 0) {
      return selectedIndex[0].sequence;
    } else {
      return '';
    }
  }

  public getIndexNameByPositionAndReadNumber(indexPosition: string, readNumber: 1 | 2): string {
    const fixedIndexPositions: string[] = get(this, 'settings.fixedIndexPositions', null)
      || get(this, 'settings.FixedIndexPositions', null);

    if (!fixedIndexPositions) {
      console.warn('Index adapter kit is not fixed layout or does not have fixedIndexPositions, unable to parse index1/2');
      return '';
    }

    const selectedIndex = find(fixedIndexPositions, fixedIndexPosition => fixedIndexPosition.split('/')[0] === indexPosition);
    if (!selectedIndex) {
      console.warn(`No matching index position ${indexPosition} found in kit fixedIndexPosition.`);
      return '';
    }
    // indexPositionFormat: <indexPosition>/<index1>-<index2>, eg. "A01/UDP0001-UDP0001", "C-C01/D701" for single-index-fixed-layout
    const indexReadRegex = /^(?<indexPosition>[^\/\s]+?)\/(?<index1>[^-]+)(?:-(?<index2>\w+))?$/;
    const match = selectedIndex.match(indexReadRegex);
    const readNumberGroupKey = readNumber === 2 ? 'index2' : 'index1';
    if (!match) {
      console.warn(`Failed to parse fixedIndexPosition ${selectedIndex}, does not match the expected format.`);
      return '';
    }
    return match.groups[readNumberGroupKey];
  }
}

export const UNSPECIFIED_INDEX_ADAPTER_KIT: gss.IndexAdapterKit = {
  id: 'Unspecified',
  name: 'Not Specified',
  displayName: 'Not Specified',
  allowedIndexStrategies: [
    gss.IndexStrategy.NO_INDEX,
    gss.IndexStrategy.SINGLE,
    gss.IndexStrategy.DUAL
  ],
  settings: {
    defaultIndexStrategy: gss.IndexStrategy.DUAL
  },
  numCyclesIndex1: 0,
  numCyclesIndex2: 0
};


