import {LibraryPrepKitCompact} from '@stratus/gss-ng-sdk/api/models/library-prep-kit-compact';
import {AnalysisVersionDefinitionCompactItemList} from '@stratus/gss-ng-sdk/api/models/analysis-version-definition-compact-item-list';
import * as gss from '@stratus/gss-ng-sdk';
import {AnalysisVersionDefinition} from '@app/run-planning/model/analysis-version-definition';
import {isBclConvert} from '@app/run-planning/constants';

export class AnalysisDefinition implements gss.AnalysisDefinition {
  /** This portion is copied from GSS, to be updated whenever we update the GSS-NG SDK */
  id?: string;
  urn?: string;
  href?: string;
  compatibleLibraryPrepKits?: Array<LibraryPrepKitCompact>;
  name?: string;
  organization?: string;
  isIllumina?: boolean;
  description?: string;
  status?: string;
  illuminaKitSupportMode?: string;
  regulatoryMode?: string;
  displayName?: string;
  analysisVersions?: AnalysisVersionDefinitionCompactItemList;
  checksum?: string;
  subTenantId?: string;
  acl?: Array<string>;
  tenantId?: string;
  tenantName?: string;
  createdByClientId?: string;
  createdBy?: string;
  modifiedBy?: string;
  timeCreated?: string;
  timeModified?: string;

  constructor(obj?: gss.AnalysisDefinition) {
    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.urn = obj.urn;
    this.href = obj.href;
    this.compatibleLibraryPrepKits = obj.compatibleLibraryPrepKits;
    this.name = obj.name;
    this.organization = obj.organization;
    this.isIllumina = obj.isIllumina;
    this.description = obj.description;
    this.status = obj.status;
    this.illuminaKitSupportMode = obj.illuminaKitSupportMode;
    this.regulatoryMode = obj.regulatoryMode;
    this.displayName = obj.displayName;
    this.analysisVersions = obj.analysisVersions;
    this.checksum = obj.checksum;
    this.subTenantId = obj.subTenantId;
    this.acl = obj.acl;
    this.tenantId = obj.tenantId;
    this.tenantName = obj.tenantName;
    this.createdByClientId = obj.createdByClientId;
    this.createdBy = obj.createdBy;
    this.modifiedBy = obj.modifiedBy;
    this.timeCreated = obj.timeCreated;
    this.timeModified = obj.timeModified;
  }

  /**
   * Change an object's type to AnalysisDefinition.
   * This makes the methods defined in AnalysisDefinition available for that object.
   */
  public static wrap(obj: gss.AnalysisDefinition): AnalysisDefinition {
    return obj ? Object.setPrototypeOf(obj, AnalysisDefinition.prototype) : null;
  }

  public get isBclConvert() {
    return isBclConvert(this.name);
  }

  public get analysisVersionDefinitions(): AnalysisVersionDefinition[] {
    return (this.analysisVersions && this.analysisVersions.items)
      ? this.analysisVersions.items.map(x => AnalysisVersionDefinition.wrap(x))
      : [];
  }
}
