import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ERROR_CODE_TO_EVENT, RUN_PREP_SETUP_PATH } from '@app/run-planning/constants';
import { IParentNotificationEvent, NotificationFromParentMessage, NotificationToParentEventType } from '@app/run-planning/interface';
import { AlertModalComponent } from '@app/shared/modals/alert-modal/alert-modal.component';
import { IAlertModalInput, IAlertModalOutput } from '@app/shared/modals/model/action-modal';
import { ModalService } from '@app/shared/modals/services/modal.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParentNotificationService {

  // Helper property to resolve the service dependency.
  private get router() { return this.injector.get(Router); }

  constructor(
    public injector: Injector,
    private modalService: ModalService
  ) {
  }

  /**
   * Trigger post message to parent window
   * When window.location is the same as window.parent.location
   * It means page is not embedded in iframe, postMessage won't be triggered in this case
   * https://stackoverflow.com/a/7739035
   */
  public postMessageToParentWindow = (message: IParentNotificationEvent | string, parentUrl: string = document.referrer) => {
    const msgString = JSON.stringify(message);
    console.debug('posting message to parent: ', msgString);
    if (parentUrl && this.isPageHostingUnderParent()) {
      window.parent.postMessage(msgString, parentUrl);
    }
  }

  /**
   * Post message with default content based on event type, to parent window
   */
  public postMessageToParentWindowFromEvent = (event: NotificationToParentEventType, payload: any) => {
    const message = this.defaultParentNotificationEvent(event, payload);
    this.postMessageToParentWindow(message);
    if (this.isPageHostingUnderParent() &&
      event === NotificationToParentEventType.RunPrepAuthenticationHasError) {
      this.router.navigate([RUN_PREP_SETUP_PATH, 'error'], { queryParams: { event } });
    }
  }

  /**
   * Get default message content based on event
   */
  public defaultParentNotificationEvent = (eventType: NotificationToParentEventType, payload: any) => {
    const event: IParentNotificationEvent = {
      eventType, payload
    };
    return event;
  }

  /**
   * Catches error response, and post message to parent view. E.g. EdgeOS
   * @param source$ observable source
   */
   public catchAndPostErrorToParent = (source$: Observable<any>) => {
    return source$.pipe(
      catchError((errorResponse) => {
        // post message to parent e.g. EdgeOS
        if (errorResponse.error instanceof ErrorEvent === false) {
            // server side error
            const errorEvent = ERROR_CODE_TO_EVENT[errorResponse.status] || NotificationToParentEventType.RunPrepOtherError;
            const errorMsg = JSON.parse(JSON.stringify(errorResponse));
            this.postMessageToParentWindowFromEvent(errorEvent, errorMsg);
        }
        return throwError(errorResponse);
      })
    );
  }

  // TODO: redirect user to no access page

  /**
   * Process message sent from parent to run-planning
   */
  public processNotificationFromParent(message, hasUnsavedChanges = false) {
    if (message === NotificationFromParentMessage.RunPrepClosePageRequest) {
      if(!hasUnsavedChanges) {
        // If no change needs to be saved, immediately send close-ok
        this.postMessageToParentWindow(NotificationToParentEventType.RunPrepModalCloseOK);
      } else {
        // If there are changes not saved, immediately send close-cancel
        // And show confirmation modal 
        this.postMessageToParentWindow(NotificationToParentEventType.RunPrepModalCloseCancel);
        this.showModal('LEAVE_RUN_SETUP_PAGE');
      }
    }
  }

  /**
   * Opens the alert modal
   * let Leaving Run Setup alert be the default modal
   */
   private showModal(type = 'LEAVE_RUN_SETUP_PAGE') {
    // open alert modal and extract user decision from output
    this.modalService.openModal({type} as IAlertModalInput, AlertModalComponent).confirm.pipe(
      map((modalOutput: IAlertModalOutput) => modalOutput.isProceed)
    ).subscribe(
      proceed => {
        if (proceed) {
          this.postMessageToParentWindow(NotificationToParentEventType.RunPrepModalCloseOK);
        } else {
          this.postMessageToParentWindow(NotificationToParentEventType.RunPrepModalCloseCancel);
        }
      }
    );
  }

  private isPageHostingUnderParent() {
    // Current page is hosting under parent if current window has a parent window with different url
    return window.location !== window.parent.location;
  }
}
