import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IApiHttpError, IBsOutageStatus, IHttpErrorMessages } from '@app/core/model/v2-api/http-error';
import { Constants } from '@app/core/utilities/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorMessagesService } from './service/http-error-messages.service';
import { PageTitleService } from '@app/core/services/title/page-title.service';
import _ from 'lodash';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HttpErrorComponent implements OnInit {

  public errorMessage: IHttpErrorMessages = null;

  constructor(
    private router: Router,
    private httpErrorMessagesService: HttpErrorMessagesService,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute) {

    const routeError = this.GetRouteError();

   // Use http error messages service to get user friendly error message texts
    this.errorMessage = this.httpErrorMessagesService.getErrorMessagesForApiError(routeError);
    if (this.errorMessage && !_.isEmpty(this.errorMessage.pageTitle)) {
     this.pageTitleService.setPageTitlePrefix(this.errorMessage.pageTitle);
   }
  }


  private GetRouteError(): IApiHttpError {
    // Unlikely. But default to unhandled error
    let error: IApiHttpError =  {
      httpErrorStatus: 503,
      data: {}
    };
    const currentNavigation = this.router.getCurrentNavigation();
    const routeData = this.activatedRoute.snapshot.data;
    if (routeData != null && routeData.httpErrorStatus != null) {
      // When routing to this component is static, error code needed by this component is set on the 'data' object on the route.
      // E.g: when defining a 'fall back' or 404 route in app routing module
      error = {
        httpErrorStatus: routeData.httpErrorStatus,
        data: {}
      };

    } else if (currentNavigation != null) {
      const currentNavigationExtras = currentNavigation.extras;
      if (currentNavigationExtras != null && currentNavigationExtras.state != null) {
        // When routing is dynamic e.g: using `router.navigateByUrl()` error details are set on the route 'state'
        // E.g: BsApiUnauthorizedInterceptor routes to this component dynamically when it intercepts a 403/404 error from BSSH API.
        error = currentNavigationExtras.state[Constants.ApiHttpErrorField];
      }
    }
    return error;
  }


  ngOnInit(): void {

  }


}
