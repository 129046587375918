import * as gss from '@stratus/gss-ng-sdk';
import {assign} from 'lodash';

export class LibraryPrepKit implements gss.LibraryPrepKit {
  id?: string;
  urn?: string;
  href?: string;
  canUpdate?: boolean;
  displayName?: string;
  name?: string;
  organization?: string;
  isIllumina?: boolean;
  description?: string;
  allowedReadTypes?: Array<string>;
  defaultRead1Length?: number;
  defaultRead2Length?: number;
  settings?: gss.LibraryPrepKitSettingsResponse;
  tenantId?: string;
  subTenantId?: string;
  acl?: Array<string>;
  libraryPrepKitCompatibleIndexAdapterKits?: Array<gss.IndexAdapterKitCompact>;
  createdByClientId?: string;
  createdBy?: string;
  modifiedBy?: string;
  timeCreated?: string;
  timeModified?: string;

  constructor(obj: gss.LibraryPrepKit) {
    if (!obj) {
      return;
    }

    assign(this, obj);
  }

  /**
   * Change an object's type to LibraryPrepKit.
   * This makes the methods defined in LibraryPrepKit available for that object.
   */
  public static wrap(obj: gss.LibraryPrepKit): LibraryPrepKit {
    return obj ? Object.setPrototypeOf(obj, LibraryPrepKit.prototype) : null;
  }

  get fullyLoaded(): boolean {
    return this.isUnspecified || this.libraryPrepKitCompatibleIndexAdapterKits !== undefined;
  }

  get isUnspecified(): boolean {
    return (this.id === UNSPECIFIED_LIBRARY_PREP_KIT.id);
  }

  public static get UNSPECIFIED(): LibraryPrepKit {
    return LibraryPrepKit.wrap(UNSPECIFIED_LIBRARY_PREP_KIT);
  }
}

export const UNSPECIFIED_LIBRARY_PREP_KIT: gss.LibraryPrepKit = {
  id: 'Unspecified',
  name: 'Not Specified',
  displayName: 'Not Specified',
  allowedReadTypes: [
    gss.ReadType.SINGLE,
    gss.ReadType.PAIRED
  ]
};
