import * as gss from '@stratus/gss-ng-sdk';
import {assign, get} from 'lodash';
import {AnalysisVersionDefinition} from '@app/run-planning/model/analysis-version-definition';
import {FormValueObject} from '@app/run-planning/interface';
import {IFormField} from '@app/run-planning/model/form-field';

export class SequencingRunAnalysisConfiguration implements gss.SequencingRunAnalysisConfiguration {
  /** This portion is copied from GSS, to be updated whenever we update the GSS-NG SDK */
  id?: string;
  urn?: string;
  href?: string;
  name?: string;
  description?: string;
  settings?: {};
  sampleSettingsSnapshot?: Array<any>;
  originalSettings?: {};
  originalSampleSettingsSnapshot?: Array<any>;
  analysisVersion?: AnalysisVersionDefinition;
  tenantId?: string;
  subTenantId?: string;
  acl?: Array<string>;
  sequencingRun?: gss.SequencingRunCompact;
  sampleSettings?: Array<gss.SequencingRunAnalysisSampleConfigurationCompact>;
  createdByClientId?: string;
  createdBy?: string;
  modifiedBy?: string;
  timeCreated?: string;
  timeModified?: string;

  constructor(obj?: gss.SequencingRunAnalysisConfiguration) {
    if (!obj) {
      return;
    }

    assign(this, obj);
    this.analysisVersion = new AnalysisVersionDefinition(obj.analysisVersion);
  }

  /**
   * Change an object's type to SequencingRunAnalysisConfiguration.
   * This makes the methods defined in SequencingRunAnalysisConfiguration available for that object.
   */
  public static wrap(obj: gss.SequencingRunAnalysisConfiguration): SequencingRunAnalysisConfiguration {
    return obj ? Object.setPrototypeOf(obj, SequencingRunAnalysisConfiguration.prototype) : null;
  }

  /**
   * Check if an analysis-config contains the default bcl convert.
   * For backward compatibility:
   * - We check two flags: IsBclDefaultConvert and IsBclConvert
   * - get() is purposely used, instead of the strong-typed access.
   */
  public get isDefaultBclConvert() {
    return get(this, 'analysisVersionDefinitionSettingsSnapshot.BclConvert.IsDefaultBclConvert', false)
      || get(this, 'analysisVersionDefinitionSettingsSnapshot.BclConvert.IsBclConvert', false);
  }

  public get isBclConvert(): boolean {
    return get(this, 'analysisVersionDefinitionSettingsSnapshot.BclConvert', false);
  }

  public get hasSamples(): boolean {
    return get(this, 'sampleSettingsSnapshot', []).length > 0;
  }

  public get isExplicitBclConvert(): boolean {
    return this.isBclConvert && this.hasSamples;
  }

  public get isImplicitBclConvert(): boolean {
    return this.isBclConvert && !this.hasSamples;
  }

  public get formFields(): IFormField[] {
    return get(this, 'analysisVersion.analysisSettings.fields');
  }

  public get formValues(): FormValueObject {
    return this.settings;
  }
}
