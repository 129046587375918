import { IndexAdapterKit } from "@stratus/gss-ng-sdk";
import { REGEX_VALIDATIONS } from "../constants";

/**
 * a.k.a ReadLength
 */
export class OverrideCycles {

  public constructor(read1?: string, index1?: string, index2?: string, read2?: string) {
    this.read1 = read1;
    this.index1 = index1;
    this.index2 = index2;
    this.read2 = read2;
  }
  public read1: string;
  public index1: string;
  public index2: string;
  public read2: string;

  /**
   * Converts override cycle string format into read lengths display format (numCyclesIndex/Read 1/2)
   */
  public static parse(
    overrideCyclesString: string,
    numCyclesIndex1: string, numCyclesIndex2: string,
    numCyclesRead2: string): OverrideCycles {

    const isIndex1Used = +numCyclesIndex1 > 0;
    const isIndex2Used = +numCyclesIndex2 > 0;
    const isRead2Used = +numCyclesRead2 > 0;

    const overrideCyclesArray = overrideCyclesString.split(';');

    // read1 is always used, so format: <read1>;<index1>;<index2>;<read2>
    const read1 = overrideCyclesArray.shift();

    let index1;
    if (isIndex1Used) {
      index1 = overrideCyclesArray.shift();
    }

    let index2;
    if (isIndex2Used) {
      index2 = overrideCyclesArray.shift();
    }

    let read2;
    if (isRead2Used) {
      read2 = overrideCyclesArray.shift();
    }

    if (overrideCyclesArray.length > 0) {
      console.warn('overrideCycles not assigned: ' + overrideCyclesArray);
    }

    return new OverrideCycles(read1, index1, index2, read2);
  }

  public haveValue(): boolean {
    return Boolean(this.read1 || this.read2 || this.index1 || this.index2);
  }

  public haveDualIndex(): boolean {
    return this.isIndex1Present() && this.isIndex2Present();
  }

  public haveSingleIndex(): boolean {
    return this.isIndex1Present() !== this.isIndex2Present();
  }

  public haveNoIndex(): boolean {
    return !this.isIndex1Present() && !this.isIndex2Present();
  }

  public shouldRemoveIndex1RelatedFields(): boolean {
    return this.haveNoIndex() || (this.haveSingleIndex() && !this.isIndex1Present());
  }

  public shouldRemoveIndex2RelatedFields(): boolean {
    return this.haveNoIndex() || (this.haveSingleIndex() && !this.isIndex2Present());
  }

  public isRead2Present(): boolean {
    return this.read2 && this.read2.includes('Y');
  }

  private isIndex1Present(): boolean {
    return this.index1 && this.index1.includes('I');
  }

  private isIndex2Present(): boolean {
    return this.index2 && this.index2.includes('I');
  }

  public loadIndexInfo(): { hasIndex1: boolean, hasIndex2: boolean } {
    if (!this.index1 && !this.index2 && !this.read1 && !this.read2) {
      return null;
    }
    return { hasIndex1: this.isIndex1Present(), hasIndex2: this.isIndex2Present() };
  }

  public isValidWith(numCyclesIndex1: string, numCyclesIndex2: string,
    numCyclesRead2: string) {
    const isIndex1Used = +numCyclesIndex1 > 0;
    const isIndex2Used = +numCyclesIndex2 > 0;
    const isRead2Used = +numCyclesRead2 > 0;
    return REGEX_VALIDATIONS.overrideCyclesReadRegex.test(this.read1) &&
      (!isIndex1Used || REGEX_VALIDATIONS.overrideCyclesIndexRegex.test(this.index1)) &&
      (!isIndex2Used || REGEX_VALIDATIONS.overrideCyclesIndexRegex.test(this.index2)) &&
      (!isRead2Used || REGEX_VALIDATIONS.overrideCyclesReadRegex.test(this.read2));
  }

  /**
   * Change an object's type to OverrideCycles.
   * This makes the methods defined in OverrideCycles available for that object.
   */
  public static wrap(obj: any): OverrideCycles {
    return obj ? Object.setPrototypeOf(obj, OverrideCycles.prototype) : null;
  }

  /**
   * Converts override cycle input into gss model string format (joined with ';')
   */
  public toString(): string {
    return [this.read1, this.index1, this.index2, this.read2]
      .filter(field => !!field) // filter out undefined fields
      .join(';');
  }
}
