import {HttpClient, HttpHeaders} from '@angular/common/http';
import {delay, map, retryWhen} from 'rxjs/operators';
import {genericRetryWhen, observableEmitDelay} from '@app/core/rxjsutils/rxjs-utilities';
import {get} from 'lodash';
import {S3DirectUploadService, UploadCredential} from '@app/cloud-run-prep/analysis-setup/services/s3-direct-upload.service';
import environment from '@environments/environment';
import {StringUtilities} from '@app/core/utilities/string-utilities';
import {AWSError} from 'aws-sdk/lib/error';
import { EventEmitter } from '@angular/core';
import {Observable} from 'rxjs';

export enum IcaFolderType {
  ReferenceFiles = 'ReferenceFiles',
  GmsFiles = 'GmsFiles'
}

export interface DirectUploadInfo {
  tempUploadCredential: UploadCredential;
  projectId: string;
  folderId: string;
  uploadSessionId: string;
}

/**
 * A generic service to upload file(s) to a folder in ICA.
 * Only upload to a certain "Folder Types" are allowed.
 */
export abstract class IcaFileUploadService {
  public bytesLoaded$: Observable<number>;

  protected constructor(
    private folderType: IcaFolderType,
    private httpClient: HttpClient,
    private s3FileUploadService: S3DirectUploadService
  ) {
    this.bytesLoaded$ = this.s3FileUploadService.bytesLoaded$;
  }

  private static readonly httpClientOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'}),
    withCredentials: true
  };

  public getDirectUploadInfoPath(): string {
    return `${environment.apiEndpoint}/icaUploads/${this.folderType.toString()}/direct-upload-info`;
  }

  public getCompleteUploadSessionPath(): string {
    return `${environment.apiEndpoint}/icaUploads/${this.folderType.toString()}/complete-upload`;
  }

  public getDeleteDataPath(dataId: string): string {
    return `${environment.apiEndpoint}/icaUploads/${this.folderType.toString()}/${dataId}`;
  }

  /**
   * Retrieve information required to do a direct-upload to a folder in ICA
   * @param folderName Name of the folder (in ICA) to store the uploaded files. It will be created automatically if not exist.
   */
  public async getDirectUploadInfo(folderName: string): Promise<DirectUploadInfo> {
    const body = {
      folderName
    };

    return this.httpClient.post(this.getDirectUploadInfoPath(), body, IcaFileUploadService.httpClientOptions)
      .pipe(
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay),
        map((response: any) => ({
          tempUploadCredential: this.mapResponseToUploadCredential(response),
          projectId:  get(response, 'IcaProjectId'),
          folderId: get(response, 'FolderId'),
          uploadSessionId: get(response, 'UploadSessionId')
        }))
      ).toPromise();
  }

  /**
   * user S3DirectUploadService to perform SINGLE file upload.
   * Since S3 using callback, we need to convert it to promise so that we can use promise chain
   * @returns promise with ETag as string
   */
  public async uploadFile(credentials: UploadCredential, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      this.s3FileUploadService.startFileUpload(credentials, file, (err: AWSError, tag: string) => {
        if (err) {
          return reject(err);
        }
        resolve(tag.replace(/['"]+/g, ''));
      });
    });
  }

  public cancelUpload(file: File) {
    this.s3FileUploadService.abortUpload(file.name);
  }

  /**
   * Mark the upload session as completed and retrieve the file(s) information back from ICA
   */
  public completeUploadSession(
    uploadSessionId: string, folderName: string, uploadedFileNames: string[]): Promise<any> {
    const body = {
      folderName, uploadSessionId, uploadedFileNames
    };
    return this.httpClient.post(this.getCompleteUploadSessionPath(), body, IcaFileUploadService.httpClientOptions)
      .pipe(
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay)
      ).toPromise();
  }

  public deleteData(dataId: string): Promise<any> {
    return this.httpClient.delete(this.getDeleteDataPath(dataId)).toPromise();
  }

  /**
   * Get upload credentials from direct-upload endpoint
   */
  private mapResponseToUploadCredential(response: any): UploadCredential {
    return {
      AuthKey: get(response, 'AccessKey'),
      AuthSecret: get(response, 'SecretKey'),
      AuthToken: get(response, 'SessionToken'),
      BucketName: get(response, 'Bucket'),
      Prefix: StringUtilities.trimTrailingSlash(get(response, 'ObjectPrefix', '')),
      Region: get(response, 'Region'),
    };
  }
}
