import {Injectable} from '@angular/core';
import * as gss from '@stratus/gss-ng-sdk';
import {Observable, of} from 'rxjs';
import { filter as rxjsFilter, switchMap, map, catchError } from 'rxjs/operators';
import {GetIndexInfoRequest} from '@stratus/gss-ng-sdk/api/models/get-index-info-request';
import {SequencingRun} from '@stratus/gss-ng-sdk/api/models/sequencing-run';
import {
  ReferenceFileCompactReferenceFileSortFieldsPagedItems
} from '@stratus/gss-ng-sdk/api/models/reference-file-compact-reference-file-sort-fields-paged-items';
import {AnalysisDefinition} from '@app/run-planning/model/analysis-definition';
import {AnalysisLocation, FormValueObject, SubscriptionType} from '@app/run-planning/interface';
import {AnalysisVersionDefinition} from '@app/run-planning/model/analysis-version-definition';
import {SequencingRunAnalysisConfiguration} from '@app/run-planning/model/sequencing-run-analysis-configuration';
import {ParentNotificationService} from '@app/run-planning/services/handle-parent-message/parent-notification.service';
import {get, isEmpty, set} from 'lodash';
import {SequencingRunContentsResponse} from '@app/run-planning/model/sequencing-run-contents-response';
import {IndexAdapterKit} from '../model/index-adapter-kit';
import {LibraryPrepKit} from '@app/run-planning/model/library-prep-kit';
import {RunPlanningState} from '@app/run-planning/run-planning.state';
import {RunPlanningContext} from '@app/run-planning/run-planning-context';
import {CreateSequencingRunConfigurationRequest} from '@app/run-planning/model/create-sequencing-run-configuration-request';
import {UpdateSequencingRunContentsRequest} from '@app/run-planning/model/update-sequencing-run-contents-request';
import {ConfigSetupState} from '@app/run-planning/config-setup/config-setup.state';
import {
  CreateSequencingRunAnalysisConfigurationRequest
} from '@app/run-planning/model/create-sequencing-run-analysis-configuration-request';
import { FileOptionsCacheService } from '@app/run-planning/services/file-options-cache/file-options-cache.service';
import {SAMPLE_SHEET_FILENAME} from '@app/core/store/runs/runs-planned.store';
import {DownloadUtils} from '@app/core/utilities/download-utilities';
import {RenderAnalysisVersionDefinitionRequest} from '@app/run-planning/model/render-analysis-version-definition-request';
import { ACT_LISTING_DEFAULT_SORT, NUM_DROPDOWN_OPTIONS_LIMIT, removeEmpty, RUN_PREP_SETUP_PATH } from '../constants';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { SubSink } from 'subsink';
import { HttpClient } from '@angular/common/http';
import {UserSubscriptionService} from '@app/core/services/user/user-subscription.service';
import {first} from 'rxjs/operators';
import { FormFieldType } from "@app/run-planning/model/form-field";
import { BsApiService } from '@app/core/services/bs-api/bs-api-service';
import { ParseSampleSheetResponse } from '@stratus/gss-ng-sdk';
import { ImportPlannedRunWarning } from '@app/shared/modals/model/import-planned-run-warning';
import {
  AnalysisConfigurationTemplateCompactAnalysisConfigurationTemplateSortFieldsPagedItems
} from '@stratus/gss-ng-sdk/api/models/analysis-configuration-template-compact-analysis-configuration-template-sort-fields-paged-items';
import { GssApiCacheService } from './gss-api-cache-service';

type IFilesSelected = {
  localFilesSelected: File[],
  referenceFilesSelected: Promise<[string, File]>[]
}

/**
 * All calls to GSS API should be done through this class.
 * This class will:
 * - Act as anti-corruption layer, i.e. maintain consistency even when GSS API is not consistent
 * - Enhance GSS API responses, by wrapping the data into classes (see run-planning\ext folder)
 * - Transform, as required, GSS data model into UI data model, i.e. for sample-data table with multi-lane selection
 */
@Injectable({
  providedIn: 'root'
})
export class GssApiService {
  private subs = new SubSink();  
  /**
  * Max number of items to retrieve from GSS API for list operations
  */
 public static readonly MAX_ITEMS_COUNT = 1000;

 private isStandalone: boolean = false;

  constructor(
    private parentNotificationService: ParentNotificationService,
    private fileOptionsCacheService: FileOptionsCacheService,
    private downloadUtils: DownloadUtils,
    private httpClient: HttpClient,
    private userStore: CurrentUserStore,
    private userSubscriptionService: UserSubscriptionService,
    private bsApiService: BsApiService,
    private gssApiCacheService: GssApiCacheService
  ) {
    this.resetCacheService();
    this.subs.sink = this.userStore.stateWithPropertyChanges.pipe(
      rxjsFilter(stateWithPropertyChanges => get(stateWithPropertyChanges, 'stateChanges.currentUserId') &&
        !get(stateWithPropertyChanges, 'stateChanges.currentUserStateError'))
    ).subscribe({
      next: (stateWithPropertyChanges) => {
        if(this.hasCache()) {
          this.resetCacheService();
          if (window.location.pathname.includes(RUN_PREP_SETUP_PATH)) {
            // Refresh the page to re-render the components, when at run-planning module
            window.location.reload();
          }
        }
      }
    });
  }

  public setStandaloneContext(isStandalone: boolean): void {
    this.isStandalone = isStandalone;
  }

  public resetCacheService() {
    this.gssApiCacheService.resetCacheService();
  }

  public hasCache() {
    return this.gssApiCacheService.hasCache();
  }

  public listAnalysisDefinitions(instrumentPlatform: string, analysisLocation: AnalysisLocation, forceRefresh = false): Observable<AnalysisDefinition[]> {

    const params: gss.AnalysisDefinitionsService.ListAnalysisDefinitionsParams = {
      sort: 'name',
      pageSize: GssApiService.MAX_ITEMS_COUNT,
      include: [gss.ListAnalysisDefinitionsIncludeFlags.ANALYSIS_VERSIONS],
      analysisLocation,
      instrumentPlatform,
    };

    if (this.isStandalone) {
      // don't pass any `subscriptions` query parameter
    } else if (this.userSubscriptionService.hasActiveIcaSubscription()) {
      params.subscriptions = [SubscriptionType.BSSH, SubscriptionType.ICA];
    } else {
      params.subscriptions = [SubscriptionType.BSSH];
    }

    return this.gssApiCacheService.listAnalysisDefinitions(params, forceRefresh)
      .pipe(
        map((x: any) => x.items.map(y => AnalysisDefinition.wrap(y)))
      );
  }

  public getAnalysisVersionDefinition(analysisDefinitionId: string, versionName: string, forceRefresh = false)
    : Observable<AnalysisVersionDefinition> {
    const params: gss.AnalysisVersionDefinitionsService.GetAnalysisVersionDefinitionParams = {
      analysisDefinitionId,
      versionName,
      include: ['CompatibleLibraryPrepKits']
    };
    return this.gssApiCacheService.getAnalysisVersionDefinition(params, forceRefresh)
      .pipe(
        map(x => AnalysisVersionDefinition.wrap(x))
      );
  }

  public renderAnalysisVersionDefinition(
    state: RunPlanningState, config?: ConfigSetupState, changedFieldId?: string,
    currentFormValues?: FormValueObject, currentPhysicalAndLogicalConfigValues?: FormValueObject)
    : Observable<gss.RenderAnalysisVersionDefinitionResponse> {

    const configSetupState = config || state.currentConfigSetup;
    const analysisVersionDefinitionId = configSetupState.analysisVersionDefinitionId;
    const body = new RenderAnalysisVersionDefinitionRequest(state, configSetupState,
      changedFieldId, currentFormValues, currentPhysicalAndLogicalConfigValues);
    const params = { analysisVersionDefinitionId, body };

    return this.gssApiCacheService.renderAnalysisVersionDefinitionByIdOrUrn(params)
      .pipe(
        this.parentNotificationService.catchAndPostErrorToParent
      );
  }

  public getIndexAdapterKit(params: gss.IndexAdapterKitsService.GetIndexAdapterKitParams, forceRefresh = false): Observable<IndexAdapterKit> {
    return this.gssApiCacheService.getIndexAdapterKit(params, forceRefresh)
      .pipe(
        map(x => IndexAdapterKit.wrap(x))
      );
  }

  public listAllIndexAdapterKits(params: gss.IndexAdapterKitsService.ListIndexAdapterKitsParams = null, forceRefresh = false):
    Observable<IndexAdapterKit[]> {
    params = params || {
      sort: 'name',
      pageSize: NUM_DROPDOWN_OPTIONS_LIMIT
    };

    return this.gssApiCacheService.listIndexAdapterKits(params, forceRefresh)
      .pipe(
        map((x: any) => x.items.map(y => IndexAdapterKit.wrap(y)))
      );
  }

  public listCompatibleIndexAdapterKits(libraryPrepKitId: string, forceRefresh = false)
    : Observable<IndexAdapterKit[]> {
    if (libraryPrepKitId === LibraryPrepKit.UNSPECIFIED.id) {
      return of([IndexAdapterKit.UNSPECIFIED]);
    }
    const params: gss.LibraryPrepKitsService.GetLibraryPrepKitParams = { libraryPrepKitId };
    return this.getLibraryPrepKit(params, forceRefresh)
      .pipe(
        map(x => x.libraryPrepKitCompatibleIndexAdapterKits.map(y => IndexAdapterKit.wrap(y))),
      );
  }

  public getLibraryPrepKit(params: gss.LibraryPrepKitsService.GetLibraryPrepKitParams, forceRefresh = false): Observable<LibraryPrepKit> {
    return this.gssApiCacheService.getLibraryPrepKit(params, forceRefresh)
      .pipe(
        map(x => LibraryPrepKit.wrap(x))
      );
  }

  public listAllLibraryPrepKits(params: gss.LibraryPrepKitsService.ListLibraryPrepKitsParams = null, forceRefresh = false)
    : Observable<LibraryPrepKit[]> {
    params = params || {
      sort: 'name',
      pageSize: NUM_DROPDOWN_OPTIONS_LIMIT
    };
    return this.gssApiCacheService.listLibraryPrepKits(params, forceRefresh)
      .pipe(
        map((x: any) => x.items.map(y => LibraryPrepKit.wrap(y)))
      );
  }

  public listAllAnalysisConfigurationTemplates(
    params: gss.AnalysisConfigurationTemplatesService.ListAnalysisConfigurationTemplatesParams = null,
    forceRefresh = false,
  ): Observable<AnalysisConfigurationTemplateCompactAnalysisConfigurationTemplateSortFieldsPagedItems> {
    params = {
      sort: ACT_LISTING_DEFAULT_SORT,
      pageSize: 100,
      ...params,
    };
    return this.gssApiCacheService.listAnalysisConfigurationTemplates(params, forceRefresh);
  }

  public listCompatibleStandardLibraryPrepKits(analysisDefinitionId: string, versionName: string, forceRefresh = false)
    : Observable<LibraryPrepKit[]> {
    return this.getAnalysisVersionDefinition(analysisDefinitionId, versionName, forceRefresh)
      .pipe(
        map(x => x.compatibleLPKs.map(y => LibraryPrepKit.wrap(y))),
      );
  }

  public listGenomes(params: gss.GenomesService.ListGenomesParams, forceRefresh = false):
    Observable<gss.GenomeCompactGenomeSortFieldsPagedItems> {
    return this.gssApiCacheService.listGenomes(params, forceRefresh);
  }

  public getSequencingRun(runId: string): Observable<SequencingRun> {
    const params: gss.SequencingRunsService.GetSequencingRunParams = {
      runId, include: ['AnalysisConfigurationsSummary']
    };
    return this.gssApiCacheService.getSequencingRun(params)
      .pipe(
        this.parentNotificationService.catchAndPostErrorToParent
      );
  }

  public listAnalysisConfigurations(runId: string): Observable<SequencingRunAnalysisConfiguration[]> {
    const params: gss.SequencingRunsService.ListAnalysisConfigurationsParams = {
      runId, pageSize: GssApiService.MAX_ITEMS_COUNT
    };
    return this.gssApiCacheService.listAnalysisConfigurations(params)
      .pipe(
        this.parentNotificationService.catchAndPostErrorToParent,
        map(x => x.items.map(y => SequencingRunAnalysisConfiguration.wrap(y)))
      );
  }

  public getSequencingRunContents(runId: string): Observable<SequencingRunContentsResponse> {
    const params: gss.SequencingRunsService.GetSequencingRunContentsParams = {
      runId,
      useFlatRunContent: true,
      include: ['ReferencedResourceModels'] // for lpk/iak id
    };
    return this.gssApiCacheService.getSequencingRunContents(params)
      .pipe(
        this.parentNotificationService.catchAndPostErrorToParent,
        map(x => SequencingRunContentsResponse.wrap(x))
      );
  }

  /**
   * Transform UI store states to runAnalysisConfig portion of gss request model, consolidates all analysis configs
   * to an array of run configuration request
   */
  private getRunAnalysisConfigurationsRequest(runPlanningState: RunPlanningState, includeImplicitBclConvert = true): gss.CreateSequencingRunAnalysisConfigurationRequest[] {
    const configRequests = runPlanningState.configSetups
      .map((config: ConfigSetupState) => CreateSequencingRunAnalysisConfigurationRequest.fromConfigSetup(config));

    if (runPlanningState.requireImplicitBclConvert && includeImplicitBclConvert) {
      configRequests.unshift(CreateSequencingRunAnalysisConfigurationRequest.fromRunPlanning(runPlanningState));
    }
    return configRequests;
  }

  /**
   * Create or replace planned run
   * For ns1k2k, there is no runId when saving.
   * Call createPlanRun endpoint instead
   */
  public createOrReplacePlannedRun(state: RunPlanningState, context: RunPlanningContext, saveAsPlannedRun = false, includeImplicitBclConvert = true)
    : Observable<gss.SequencingRun> {

    const runId = context.editedRunId || state.runSettings.runId;
    // Save as draft when state is dirty, unless specified saving as planned run
    const isDraft = !saveAsPlannedRun && (state.runSettings.isDraft || context.globalState.isDirty);
    const runConfiguration = new CreateSequencingRunConfigurationRequest(state.runSettings);
    const runContents = new UpdateSequencingRunContentsRequest(state.configSetups, context.globalState.runSettings.isMultiAnalysis);
    const runAnalysisConfigurations = this.getRunAnalysisConfigurationsRequest(state, includeImplicitBclConvert);

    const body = {
      runConfiguration,
      runContents,
      runAnalysisConfigurations,
      isDraft
    };
    if (!isEmpty(state.restrictedUiOperations)) {
      set(body, 'restrictedUiOperations', state.restrictedUiOperations);
    }
    removeEmpty(body);

    const run$ = runId
      ? this.gssApiCacheService.replacePlannedRun({runId, body})
      : this.gssApiCacheService.createPlannedRun(body);

    return run$
      .pipe(
        this.parentNotificationService.catchAndPostErrorToParent
      );
  }

  public getIndexInfo(runConfiguration: CreateSequencingRunConfigurationRequest, libraryPrepKitId: string, indexAdapterKitId: string) {
    const requestParams: GetIndexInfoRequest = {
      runConfiguration
    };
    if (libraryPrepKitId !== LibraryPrepKit.UNSPECIFIED.id) {
      requestParams.libraryPrepKitId = libraryPrepKitId;
    }
    if (indexAdapterKitId !== IndexAdapterKit.UNSPECIFIED.id) {
      requestParams.indexAdapterKitId = indexAdapterKitId;
    }

    removeEmpty(requestParams);
    return this.gssApiCacheService.getIndexInfo(requestParams).pipe(
      first()
    );
  }

  public validatePlannedRun(state: RunPlanningState)
    : Observable<gss.SequencingRun> {

    const runId = state.runSettings.runId;
    const isDraft = state.runSettings.isDraft;
    const runConfiguration = new CreateSequencingRunConfigurationRequest(state.runSettings);
    const runContents = new UpdateSequencingRunContentsRequest(state.configSetups, state.runSettings.isMultiAnalysis);
    const runAnalysisConfigurations = this.getRunAnalysisConfigurationsRequest(state);
    const performFullValidation = true;

    const body = {
      runConfiguration,
      runContents,
      runAnalysisConfigurations,
      isDraft,
      performFullValidation
    };
    removeEmpty(body);

    return this.gssApiCacheService.replacePlannedRun({
      runId, body
    })
      .pipe(
        this.parentNotificationService.catchAndPostErrorToParent
    );
  }

  public prepareRequeuedRun(
    state: RunPlanningState, context: RunPlanningContext, replaceExistingRunRequeue = false)
    : Observable<gss.SequencingRun> {

    const runId = context.editedRunId;
    const runName = context.requeueInformation.runName;
    const requeueReason = context.requeueInformation.requeueReason;
    const externalLocation = context.requeueInformation.dataFilePath;
    const analysisExternalLocation = context.requeueInformation.analysisDataFilePath;
    const runContents = new UpdateSequencingRunContentsRequest(state.configSetups, state.runSettings.isMultiAnalysis);
    const runAnalysisConfigurations = this.getRunAnalysisConfigurationsRequest(state);

    const body = {
      runName,
      requeueReason,
      analysisExternalLocation,
      externalLocation,
      runContents,
      runAnalysisConfigurations,
      replaceExistingRunRequeue
    };
    removeEmpty(body);

    return this.gssApiCacheService.prepareRequeue({
      runId,
      body
    }).pipe(
      this.parentNotificationService.catchAndPostErrorToParent
    );
  }

  /**
   * Starts previously prepared requeue run for analysis.
   */
  public startRequeue(runId: string): Observable<gss.SequencingRun> {
    return this.gssApiCacheService.startRequeue(runId).pipe(
      this.parentNotificationService.catchAndPostErrorToParent
    );
  }

  public getInstrumentTypes(forceRefresh = false): Observable<gss.SupportedInstrumentTypes> {
    return this.gssApiCacheService.getInstrumentTypes(forceRefresh);
  }

  public listReferenceFiles(params: gss.ReferenceFilesService.ListReferenceFilesParams, forceRefresh = false)
    : Observable<ReferenceFileCompactReferenceFileSortFieldsPagedItems> {
    return this.gssApiCacheService.listReferenceFiles(params, forceRefresh);
  }

  /**
   * Go through files selected
   * Check if any gds file selected
   * And get selected local files
   */
  private checkSelectedFiles = (configs: ConfigSetupState[], shouldIncludeReferenceFiles: boolean): [boolean, File[], Promise<[string, File]>[]] => {
    const regexForGdsFile = /(?=file:).*(?=#)/g;
    let isGdsFileSelected = false;
    const filesSelected: IFilesSelected = { localFilesSelected: [], referenceFilesSelected: [] };
    for (let config of configs) {
      const localFileFields = [];
      const referenceFileFields = [];
      config.analysisSettingsFormFields.forEach((field) => {
        if (field.type === FormFieldType.FILESELECT) {
          localFileFields.push(field);
        } else if (field.type === FormFieldType.REFERENCE_FILE && !get(field.settings, 'isAuxiliaryFile', false)) {
          referenceFileFields.push(field);
        }
      });

      if (!isEmpty(localFileFields)) {
        const selectedLocalFilesInConfig = localFileFields.reduce((localFilesInSingleConfig, field) => {
          // Get file value from config
          const selectedFile = config.analysisSettingsFormValues[field.id];
          if (regexForGdsFile.test(selectedFile)) {
            // If file value matches with gds file regex format, it's gds file
            isGdsFileSelected = true;
            return localFilesInSingleConfig;
          }
          // Get selected local file from file cache service
          const cachedLocalFile = this.fileOptionsCacheService.getLocalSelectedFiles(
            config.analysisVersionDefinitionId, field.id, selectedFile);
          if (cachedLocalFile) {
            localFilesInSingleConfig = localFilesInSingleConfig.concat(cachedLocalFile);
          }
          return localFilesInSingleConfig;
        }, []);

        if (!isEmpty(selectedLocalFilesInConfig)) {
          filesSelected.localFilesSelected = filesSelected.localFilesSelected.concat(selectedLocalFilesInConfig);
        }
      }
      if (shouldIncludeReferenceFiles && !isEmpty(referenceFileFields)) {
        const referenceFilePromises: Promise<[string, File]>[] = referenceFileFields.map((field) => {
          const referenceFileId = config.analysisSettingsFormValues[field.id];
          if (referenceFileId) {
            let name:string = '';
            return this.gssApiCacheService.getReferenceFile({referenceFileId: referenceFileId})
              .pipe(
                switchMap((result: gss.ReferenceFile) => {
                  name = result.name;
                  return this.bsApiService.getIcaDataDownloadUrl(result.dataLocationUri)
                }),
                switchMap((response: {url: string}) => this.httpClient.get(response.url, {responseType: 'blob'})),
                map((blob: Blob) => {
                  var x: [string, File] = [config.rawAnalysisVersionDefinition.analysisDefinition.name, new File([blob], name)]
                  return x;
                }),
                first()
              ).toPromise();
          }
        }).filter(x => x); // remove undefined (if !referenceFileId)
        filesSelected.referenceFilesSelected = filesSelected.referenceFilesSelected.concat(referenceFilePromises);
      }
    }
    return [isGdsFileSelected, filesSelected.localFilesSelected, filesSelected.referenceFilesSelected];
  }

  /**
   * Export sample sheet and files
   */
  private async exportRunSampleSheetAndSelectedFiles(runName: string, sampleSheetContent, selectedGdsFiles = [], selectedLocalFiles = [], selectedReferenceFiles:Promise<[string, File]>[] = []): Promise<boolean> {
    if (selectedGdsFiles && selectedGdsFiles.length > 0) {
      // Gds file selected
      // Get gds file reference url from response to download the file
      const presignedUrls: string[] = selectedGdsFiles.map((file) => {
        return file.presignedUrl;
      });
      const presignedUrlFileNames: string[] = selectedGdsFiles.map((file) => {
        return file.fileName;
      });
      // presignedUrls and presignedUrlFileNames are in sync since map maintain responseFiles sequence
      if (presignedUrls) {
        await this.downloadUtils.triggerZipSaveFile(sampleSheetContent, SAMPLE_SHEET_FILENAME,
          presignedUrls, presignedUrlFileNames, runName, selectedLocalFiles).toPromise();
        return true;
      } else {
        throw new Error('You do not have the right to download this file.');
      }
    } else {
      // No Gds file selected
      if (!isEmpty(selectedLocalFiles) || !isEmpty(selectedReferenceFiles)) {
        // Has local file selected
        // Zip sample sheet and selected local file(s)
        await this.downloadUtils.triggerSaveFile(sampleSheetContent, SAMPLE_SHEET_FILENAME,
          selectedLocalFiles, selectedReferenceFiles, runName);
        return true;
      } else {
        // No Gds file selected, download sample sheet only
        await this.downloadUtils.triggerSaveFile(sampleSheetContent, SAMPLE_SHEET_FILENAME);
        return true;
      }
    }
  }

  public generateSampleSheet(requestBody: gss.GenerateSampleSheetRequest): Observable<gss.SampleSheet> {
    removeEmpty(requestBody);
    return this.gssApiCacheService.generateSampleSheet(requestBody);
  }

  public generateSampleSheetFromRunPlanning(state: RunPlanningState, context: RunPlanningContext, includeFileReference = false)
    : Observable<gss.SampleSheet> {

    const runConfiguration = new CreateSequencingRunConfigurationRequest(state.runSettings);
    const runContents = new UpdateSequencingRunContentsRequest(state.configSetups, context.globalState.runSettings.isMultiAnalysis);
    const runAnalysisConfigurations = this.getRunAnalysisConfigurationsRequest(state);

    const requestBody: gss.GenerateSampleSheetRequest = {
      runConfiguration,
      runContents,
      runAnalysisConfigurations
    };
    if (includeFileReference) {
      requestBody.include = [gss.GenerateSampleSheetIncludeFlags.INCLUDE_FILE_REFERENCES];
    }
    return this.generateSampleSheet(requestBody);
  }

  public exportPlannedRun(state: RunPlanningState, context: RunPlanningContext, triggerSaveFile = true)
    : Observable<boolean> {
    // Get selected file info from config
    const [hasGdsFileSelected, selectedLocalFiles, selectedReferenceFiles] = this.checkSelectedFiles(state.configSetups, state.shouldIncludeReferenceFiles);

    return this.generateSampleSheetFromRunPlanning(state, context, state.shouldIncludeGdsFiles && hasGdsFileSelected).pipe(
      switchMap(response => {
        const responseFiles = response.fileReferences;
        const runName = state.runSettings.runName;
        if(triggerSaveFile){
          return state.shouldIncludeReferenceFiles ?
            this.exportRunSampleSheetAndSelectedFiles(runName, response.sampleSheetContent, responseFiles, selectedLocalFiles, selectedReferenceFiles) :
            this.exportRunSampleSheetAndSelectedFiles(runName, response.sampleSheetContent, responseFiles, selectedLocalFiles);
        } else {
          return of(true);
        }
      }),
    );
  }

  /**
   * Converts the sample sheet content string into a sequencing run object using
   * the parseSampleSheet GSS endpoint.
   * @param sampleSheetString sample sheet content
   */
  public parseSampleSheet(sampleSheetString: string) {
    const sampleSheetContent = sampleSheetString.replace(/ - /g, '_');
    return this.gssApiCacheService.parseSampleSheet({
      sampleSheetContent,
      include: ['PrepKitInfo'],
      resolvePrepKits: true,
      resolvePrepKitsByName: true,
    });
  }

  /**
   * GSS has yet to expose an endpoint to fully validate sampleSheet (SS)
   * This is a workaround to `parseSampleSheet` and then use the response to `generateSampleSheet`
   * to get the validation errors
   */
  public validateSampleSheet(sampleSheetString: string): Observable<ImportPlannedRunWarning[]> {
    return this.parseSampleSheet(sampleSheetString).pipe(
      catchError(err => {
        const extractedMessages = this.extractGssErrorMessages(err);
        // If failed to extract any message, then just give a generic message
        if (extractedMessages.length === 0) {
          extractedMessages.push(
            new ImportPlannedRunWarning({ message: 'Failed to parse Sample Sheet', severity: 'Error'})
          );
        }
        return of(extractedMessages);
      }),
      switchMap((response) => {
        if (Array.isArray(response) && 'severity' in response[0]) {
          return of(response);
        } else {
          const parsedResponse = response as ParseSampleSheetResponse;
          const parseSampleSheetWarnings = (parsedResponse.warnings || []).map(warning => new ImportPlannedRunWarning(warning));
          const requestBody: gss.GenerateSampleSheetRequest = {
            runConfiguration: parsedResponse.sequencingRun.runConfiguration as CreateSequencingRunConfigurationRequest,
            runContents: parsedResponse.sequencingRun.runContents,
            runAnalysisConfigurations: parsedResponse.sequencingRun.runAnalysisConfigurations,
            // Without the "include" field, as we are not interested in the final response here
          };
          return this.generateSampleSheet(requestBody).pipe(
            // If no error from generateSampleSheet API call, then nothing to add to parseSampleSheetWarnings
            map(_res => parseSampleSheetWarnings),
            catchError(err => {
              const extractedMessages = this.extractGssErrorMessages(err);
              // If failed to extract any message, then just give a generic message
              if (extractedMessages.length === 0 && parseSampleSheetWarnings.length === 0) {
                extractedMessages.push(
                  new ImportPlannedRunWarning({ message: 'Failed to validate Sample Sheet', severity: 'Error'})
                );
              }
              return of([...extractedMessages, ...parseSampleSheetWarnings]);
            }),
          );
        }
      }),
    );
  }

  /**
   * Temporary helper to help deconfuzzle the variable format of GSS error responses
   */
  private extractGssErrorMessages(error: any): ImportPlannedRunWarning[] {
    const collectedMessages: ImportPlannedRunWarning[] = [];

    if (error.message || error.Message) {
      collectedMessages.push(new ImportPlannedRunWarning({ message: error.message || error.Message, severity: 'Error' }));
    }

    if (error.code && error.code.match(/GenomicSequencingService\.\S*Error/))  {
      const nestedMessages = (error.details || error.Details || [])
        .filter(detail => detail.ErrorMessage)
        .map(detail => {
          if (detail.Type === 'RunContentError' && detail.SampleName) {
            let contentErrorMessage = `Sample '${detail.SampleName}': ${detail.ErrorMessage}`;
            if (detail.LaneNumber) {
              contentErrorMessage = `Lane ${detail.LaneNumber}, ` + contentErrorMessage;
            }
            return new ImportPlannedRunWarning({ message: contentErrorMessage, severity: 'Error' });
          } else if (detail.Type === 'AnalysisConfigurationError'  && Array.isArray(detail.Details)) {
            const nestedDetailsAsString = detail.Details
              .map(d => {
                if (d.Type === 'AnalysisSampleSettingError' && d.FieldId && d.SampleId) {
                  return `Field ${d.FieldId}, Sample ${d.SampleId}: ${d.ErrorMessage}`;
                } else {
                  return `${d.ErrorMessage}`;
                }
              })
              .join('<br/>');
            return new ImportPlannedRunWarning({
              message: detail.ErrorMessage + '<br/>' + nestedDetailsAsString,
              severity: 'Error',
            });
          } else {
            return new ImportPlannedRunWarning({ message: detail.ErrorMessage, severity: 'Error' });
          }
        });
      collectedMessages.push(...nestedMessages);
    }

    return collectedMessages;
  }

  /**
   * Converts the sample sheet content string into a sequencing run object using
   * the parseSampleSheet GSS endpoint.
   * @param sampleSheetString sample sheet content
   */
  public getRunFromSampleSheet(sampleSheetContent) {
    return this.gssApiCacheService.transformSampleSheet({ sampleSheetContent, include: ['SequencingRunWithDetails'] });
  };
}
