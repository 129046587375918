import { IBaseState } from '@app/core/store/infrastructure/base.state';
import {
  FormValueObject,
  IPerSampleSettingsDataRow,
  ISampleDataRow,
  ISampleTableColumnVisibility,
  RepeatSamplesAcrossLanesState,
  SubscriptionType
} from '@app/run-planning/interface';
import {AnalysisVersionDefinition} from '@app/run-planning/model/analysis-version-definition';
import {OverrideCycles} from '@app/run-planning/model/override-cycles';
import { get } from 'lodash';
import * as gss from '@stratus/gss-ng-sdk';
import { IFormField } from '@app/run-planning/model/form-field';
import { LibraryPrepKit } from '../model/library-prep-kit';
import { IndexAdapterKit } from '../model/index-adapter-kit';
import { SampleTableService } from '@app/run-planning/services/sample-table/sample-table-service';
import { InteractiveTableColDef } from '@app/cloud-run-prep/run-setup/interactive-table/InteractiveTableColDef.interface';
import { getCombinedDisplayName, getDragenVersionDisplayName, SUPPORTED_HIDDEN_UI_FIELDS } from '../constants';
import { LaneLibrary } from '@stratus/gss-ng-sdk';
import { AnalysisConfigurationTemplateCompact } from '@stratus/gss-ng-sdk';

export interface ISampleSetting {
  sampleId: string;
  settings: FormValueObject;
}

export class ConfigSetupState implements IBaseState {
  name: string;

  // GSS fields
  /* application selection, format: <analysisDefinitionId>\_<versionString>\_<avdId> */
  analysisDefinition: string;
  analysisDescription: string;
  libraryPrepKitId: string;
  indexAdapterKitId: string;
  referenceGenome: string;
  adapterRead1: string;
  adapterRead2: string;
  overrideCycles: OverrideCycles;
  indexRead?: gss.IndexStrategy;
  readType?: string;
  sampleData: ISampleDataRow[];

  // UI data
  applicationDisplayName: string;
  avdReleaseNote?: string;
  lpkDisplayName: string;
  lpkDescription: string;
  iakDisplayName: string;
  iakDescription: string;
  referenceGenomeDisplayName: string;
  avdPhysicalConfigValue: string;
  avdPhysicalConfigDisplayName: string;
  dragenVersion: string;
  /* selected avd gss resource for the current config */
  rawAnalysisVersionDefinition: AnalysisVersionDefinition;
  /* for checking the supported read type */
  rawLibraryPrepKit: LibraryPrepKit;
  /* for well position/index name in sample data table */
  rawIndexAdapterKit: IndexAdapterKit;
  /* show/hide sample data table index columns */

  sampleTableColumnVisibility: ISampleTableColumnVisibility;
  sampleTableReadOnly: boolean;
  columnDefinitions: InteractiveTableColDef[];

  isAdapterReadEditable: boolean;
  /* default values for read length for single-analysis mode */
  kitDefaultIndex?: {index1: string, index2: string};
  /* default values for read length for single-analysis mode */
  kitDefaultReads?: {read1: string, read2: string};
  /* Reads for single-analysis mode */
  numCyclesRead1?: string;
  numCyclesRead2?: string;
  numCyclesIndex1?: string;
  numCyclesIndex2?: string;
  /* allowed index read options for single-analysis mode */
  supportedIndexStrategies?: string[];
  /* allowed read type options for single-analysis mode */
  allowedReadTypes?: string[];
  /* default values for override cycles as received from GSS */
  defaultOverrideCycles?: string;
  /**
  * When fields are specified in hidden UI fields, expects these behaviour
  * - Remove field from sample analysis settings form
  * - Exclude value from submitting plan payload
  * - Exlucde value from import run/requeue sample data
  */
  hiddenUiFields: Array<keyof LaneLibrary> = [];
  /**
   * When loading run, customSettingsFields is inherited from run-planning state.
   * When importing new sample sheet to the run (for single-analysis only), customSettingsFields is recomputed and updated in this config state.
   *   - since only single-analysis can import, there is no need to update the run-planning level's customSettingsFields.
   */
  customSettingsFields: string[] = [];

  analysisSettingsFormFields: IFormField[];
  analysisSettingsFormValues: FormValueObject;
  analysisSettingsFormValuesForDisplay: {};

  logicalConfigFormFields: IFormField[];
  logicalConfigValues: FormValueObject;
  logicalFormValuesForDisplay: {};

  physicalConfigFormFields: IFormField[];
  physicalConfigValues: FormValueObject;
  physicalFormValuesForDisplay: {};

  perSampleSettingsFormFields: IFormField[];
  perSampleSettingsData: IPerSampleSettingsDataRow[];
  /* for list genome api, gds BaseSpace file upload, render avd */
  analysisVersionDefinitionId: string;
  // decide whether to filter out certain reference genome
  filterBySampleSheetFormat: string;

  /* Internal use to identify if the config is a new one or an existing one, for discard change purpose */
  isNew: boolean;
  /* Internal use to identify each config */
  configIndex: number;
  // for reading existing runs, caching for extracting sample rows by sampleId
  sampleSettingsSnapshot: ISampleSetting[];
  /* Internal use to identify if show per sample setting table */
  hasPerSampleSettingTable: boolean;
  /** Internal use to identify if need to include custom kit in LPK and IAK selection */
  enableCustomKit: boolean;
  /* Internal use to identify if analysis is BCL and setting has override cycles field */
  bclAvdWithOverrideCyclesSetting: boolean;

  // For Render AVD API (dynamic-ui)
  canInvokeRenderAvd: boolean;  // the Render AVD API fails if it is called on AVD without updateRenderOnChange=true
  rawRenderAnalysisVersionDefinitionResponse: gss.RenderAnalysisVersionDefinitionResponse;
  rawRenderResponseAnalysisSetting: any;
  rawRenderResponseAnalysisSampleSetting: any;
  renderErrors: any[];
  apiErrors: any; // TODO to merge this with RunPrepComponent.apiErrorResponse

  // For ACT response
  rawACTResponse: AnalysisConfigurationTemplateCompact;

  // UI control enablement variables
  enableImportData: boolean;
  enableDownloadTemplate: boolean;
  enableAddRow: boolean;
  enableDeleteRow: boolean;
  enableSelectRow: boolean;
  enableRepeatSamplesAcrossLanes: RepeatSamplesAcrossLanesState = RepeatSamplesAcrossLanesState.HIDDEN;
  enableSetRepeatSamplesAcrossLanes: boolean;

  hasSubSampleChanges: boolean;

  isDirty?: boolean;

  public constructor(
    config?: gss.SequencingRunAnalysisConfiguration,
    index?: number,
    isMultiAnalysis?: boolean,
    isStandAlone?: boolean,
    hasAct?: boolean,
    softwareVersion?: string,
    supportMultiLane?: boolean,
    showEnableRepeatSamplesAcrossLanes?: boolean,
    customSettingsFields?: string[]
  ) {

    // tslint:disable-next-line:max-line-length
    this.name = config ? config.name : '';
    this.isNew = !config;
    this.configIndex = index ? index : 0;

    const avd = config ? config.analysisVersion : null;
    const ad = avd ? avd.analysisDefinition : null;

    this.analysisVersionDefinitionId = avd ? avd.id : null;
    this.analysisDescription = config ? config.description : null;
    this.analysisDefinition = avd && ad
      ? `${ad.id}|${avd.version}|${avd.id}|${avd.displayVersion}`
      : '';

    this.rawAnalysisVersionDefinition = config ? new AnalysisVersionDefinition(config.analysisVersion) : null;
    this.analysisSettingsFormFields = config
      ? get(config, 'analysisVersion.analysisSettings.fields', [])
      : [];
    this.analysisSettingsFormValues = config ? config.settings : {};
    this.analysisSettingsFormValuesForDisplay = {};
    this.sampleSettingsSnapshot = config ? config.sampleSettingsSnapshot : []; // cache associated sampleId for sampleDataRow extraction

    this.dragenVersion = softwareVersion || null;

    this.enableRepeatSamplesAcrossLanes = showEnableRepeatSamplesAcrossLanes
      ? RepeatSamplesAcrossLanesState.UNCHECKED
      : RepeatSamplesAcrossLanesState.HIDDEN;
    const tableSvc = new SampleTableService();
    this.sampleTableColumnVisibility = tableSvc.getColumnVisibility(
      isMultiAnalysis, hasAct, null,
      null, this.enableRepeatSamplesAcrossLanes, supportMultiLane, null, this.hiddenUiFields);
    this.sampleTableReadOnly = false;
    this.columnDefinitions = tableSvc.getDefaultColDefs();
    this.customSettingsFields = customSettingsFields ? customSettingsFields : [];

    this.adapterRead1 = undefined;
    this.adapterRead2 = undefined;
    this.applicationDisplayName = '';
    this.iakDisplayName = '';
    this.iakDescription = '';
    this.indexAdapterKitId = null;
    this.libraryPrepKitId = null;
    this.logicalConfigFormFields = [];
    this.logicalConfigValues = undefined;
    this.lpkDisplayName = '';
    this.lpkDescription = '';
    this.overrideCycles = new OverrideCycles();
    this.allowedReadTypes = [gss.ReadType.SINGLE, gss.ReadType.PAIRED];
    this.numCyclesRead1 = undefined;
    this.numCyclesRead2 = undefined;
    this.readType = undefined;
    this.indexRead = undefined;
    this.isAdapterReadEditable = false;
    this.perSampleSettingsData = [];
    this.perSampleSettingsFormFields = [];
    this.physicalConfigFormFields = [];
    this.physicalConfigValues = {};
    this.physicalFormValuesForDisplay = {};
    this.logicalFormValuesForDisplay = {};
    this.avdPhysicalConfigValue = '';
    this.avdPhysicalConfigDisplayName = '';
    this.hiddenUiFields = [];
    this.rawLibraryPrepKit = undefined;
    this.rawIndexAdapterKit = undefined;
    this.referenceGenome = '';
    this.referenceGenomeDisplayName = '';
    this.filterBySampleSheetFormat = null;
    this.sampleData = [];
    this.hasPerSampleSettingTable = false;
    this.canInvokeRenderAvd = false;
    this.bclAvdWithOverrideCyclesSetting = false;
    this.rawRenderAnalysisVersionDefinitionResponse = null;
    this.rawRenderResponseAnalysisSetting = null;
    this.rawRenderResponseAnalysisSampleSetting = null;
    this.renderErrors = [];
    this.apiErrors = null;

    this.enableImportData = this.enableDownloadTemplate = this.enableAddRow = this.enableDeleteRow = this.enableSetRepeatSamplesAcrossLanes = !hasAct;
    this.enableSelectRow = true;
    this.enableCustomKit = true;
  }

  /**
   * Make sure that the object's type is ConfigSetupState.
   */
  // TODO find a generic version of this method, if possible
  public static wrap(obj: ConfigSetupState): ConfigSetupState {
    return obj ? Object.setPrototypeOf(obj, ConfigSetupState.prototype) : null;
  }

  public get isBclConvert(): boolean {
    return this.rawAnalysisVersionDefinition
      ? this.rawAnalysisVersionDefinition.isBclConvert
      : false;
  }

  public get isRunBasedAvd(): boolean {
    return this.rawAnalysisVersionDefinition && (
      this.rawAnalysisVersionDefinition.analysisType === 'CloudRun' ||
      this.rawAnalysisVersionDefinition.analysisType === 'Local'
    );
  }

  /**
   * In multi-analysis, Run based AVD's workflow will handle BCL Convert on their own, no need implicit BCL convert
   * Though they look similar to explicit BCL Convert AVDs, they are not exactly so too,
   * in that they are more than just BCL Convert and has analysis component to it.
   * That means that they cannot be used as implicit BCL convert configs.
   */
  public isRunBasedWithOwnBclConvert(isMultiAnalysis: boolean): boolean {
    return isMultiAnalysis && this.isRunBasedAvd && !this.isExplicitBclConvert;
  }

  public uiShouldManageOverrideCycles(isMultiAnalysis: boolean): boolean {
    return this.rawAnalysisVersionDefinition ? this.rawAnalysisVersionDefinition.uiShouldManageOverrideCycles(isMultiAnalysis) : false;
  }

  public get hasSamples(): boolean {
    return this.sampleData && (this.sampleData.length > 0);
  }

  public get isExplicitBclConvert(): boolean {
    return this.isBclConvert;
  }

  public get avdDisplayName(): string {
    return getCombinedDisplayName([this.applicationDisplayName, getDragenVersionDisplayName(this.dragenVersion)]);
  }

  public get physicalConfigKey() : string {
    if(!this.analysisVersionDefinitionId) {
      return null;
    }
    return `${this.analysisVersionDefinitionId}_${JSON.stringify(this.physicalConfigValues)}`;
  }

  public collectSamplesAndKits(allSampleDataRows: ISampleDataRow[], isMultiAnalysisRun: boolean) {
    const configSamples: ISampleDataRow[] = [];

    if (isMultiAnalysisRun) {
      // When the run is multi analysis config run,
      // sampleSettingsSnapshot tells us which samples (by Id/Name) belong to the config
      const sampleIds = new Set(this.sampleSettingsSnapshot.map(x => x.sampleId));
      allSampleDataRows.forEach(sampleRow => {
        if (!sampleRow.isMappedToConfig && sampleIds.has(sampleRow.sampleName)) {
          configSamples.push(sampleRow);
          sampleRow.isMappedToConfig = true;
        }
      });
    } else {
      // When the run is single analysis run, all samples should belong to the single config
      allSampleDataRows.forEach(sample => configSamples.push(sample));
    }

    this.sampleData = configSamples;

    // assign kits to config (kits same throughout samples of a config, config guaranteed to have >=1 sample)
    const firstConfigSample = configSamples[0];
    this.libraryPrepKitId = firstConfigSample.libraryPrepKitId;
    this.indexAdapterKitId = firstConfigSample.indexAdapterKitId;
  }

  public hasSubscription(subscription: SubscriptionType): boolean {
    const requiredSubscriptions = get(this.rawAnalysisVersionDefinition, 'requiredSubscriptions');
    return requiredSubscriptions ? requiredSubscriptions.includes(subscription) : false;
  }
}
