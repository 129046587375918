import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService, ToastrService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { IEditSharedBclSettingsModalInput } from '../model/action-modal';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IFormField } from '@app/run-planning/model/form-field';
import { FormValueObject } from '@app/run-planning/interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-shared-bcl-settings-modal',
  templateUrl: './edit-shared-bcl-settings-modal.component.html',
  styleUrls: ['./edit-shared-bcl-settings-modal.component.scss']
})
export class EditSharedBclSettingsModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {

  public modalType = 'EditSharedBclConvertSettingsModal';
  public modalTitle = ModalTexts.EDIT_SHARED_BCL_CONVERT_SETTINGS.MODAL_TITLE;
  public closeButtonText = ModalTexts.EDIT_SHARED_BCL_CONVERT_SETTINGS.CLOSE_BUTTON_TEXT;
  public confirmButtonText = ModalTexts.EDIT_SHARED_BCL_CONVERT_SETTINGS.CONFIRM_BUTTON_TEXT;

  configTitle: string;
  bclConvertSettingsFields: IFormField[];
  showHyperLink: boolean;
  formGroup: FormGroup;

  save: (...args: any[]) => Observable<any>;

  get bclConvertSettingsValues() { return this.formGroup ? this.formGroup.get('bclConvertSettingsValues') : null; }
  
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastrService: ToastrService,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    // Assign modal text content according to input
    this.subs.sink = this.data.subscribe((modalInput: IEditSharedBclSettingsModalInput) => {
      if (modalInput) {
        this.configTitle = modalInput.configTitle;
        this.bclConvertSettingsFields = modalInput.bclConvertSettingsFields;
        this.showHyperLink = modalInput.showHyperLink;
        this.save = modalInput.saveFunction;
        this.buildFormGroup(modalInput.bclConvertSettingsValues);
      }
    });
  }

  private buildFormGroup(bclConvertSettingsValues: FormValueObject) {
    this.formGroup = new FormBuilder().group({
      bclConvertSettingsValues: new FormControl(bclConvertSettingsValues)
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * To be called whenever the modal is closed.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  close(modal: NgxSmartModalComponent) {
    this.confirm.emit();
    this.confirm.complete();
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this.save(this.bclConvertSettingsValues.value).subscribe({
      next: () => {
        this.toastrService.success('Changes saved successfully.');
        modal.close();
      },
      error: (err) => {
        this.toastrService.error(err.message, 'Error saving changes.');
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
