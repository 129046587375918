import {LibraryPrepKitCompact} from '@stratus/gss-ng-sdk/api/models/library-prep-kit-compact';
import * as gss from '@stratus/gss-ng-sdk';
import {InstrumentPlatformAndTypesResponse} from '@stratus/gss-ng-sdk/api/models/instrument-platform-and-types-response';
import {assign, find, get, isEmpty} from 'lodash';
import {isBclConvert, OVERRIDE_CYCLES_FIELD_ID, SUPPORTED_HIDDEN_UI_FIELDS} from '@app/run-planning/constants';
import { FormFieldType, IFormField } from '@app/run-planning/model/form-field';
import {SubSampleDefinition} from '@stratus/gss-ng-sdk/api/models/sub-sample-definition';
import { SubSamplesConfiguration } from '@stratus/gss-ng-sdk/api/models/sub-samples-configuration';
import { IRunContentSettingsField } from '../interface';

export enum SampleSettingsConfigurationLevel {
  PHYSICAL = 'physicalConfiguration',
  LOGICAL = 'logicalConfiguration',
}

export class AnalysisVersionDefinition implements gss.AnalysisVersionDefinition {
  /** This portion is copied from GSS, to be updated whenever we update the GSS-NG SDK */
  id?: string;
  urn?: string;
  href?: string;
  compatibleLibraryPrepKits?: Array<LibraryPrepKitCompact>;
  compatibleLibraryPrepKitsDefinedForVersion?: Array<LibraryPrepKitCompact>;
  compatibleBclConvertVersions?: Array<string>;
  supportedGenomes?: Array<gss.GenomeCompact>;
  excludedGenomes?: Array<gss.GenomeCompact>;
  onSubmitFunction?: string;
  onRenderFunction?: string;
  version?: string;
  displayVersion?: string;
  softwareExecutionVersion?: string;
  description?: string;
  helpText?: string;
  supportedInstrumentPlatformAndTypes?: Array<InstrumentPlatformAndTypesResponse>;
  status?: string;
  analysisType?: string;
  isDragen?: boolean;
  analysisSettings?: any;
  settings?: gss.AnalysisVersionDefinitionSettings;
  skipAnalysisSection?: boolean;
  analysisSampleSettings?: any;
  runContentSettings?: any;
  onRenderRequireRunContents?: boolean;
  checksum?: string;
  requiredSubscriptions?: Array<string>;
  subTenantId?: string;
  acl?: Array<string>;
  tenantId?: string;
  tenantName?: string;
  createdByClientId?: string;
  createdBy?: string;
  modifiedBy?: string;
  timeCreated?: string;
  timeModified?: string;

  /** This portion is not copied directly from GSS, but transformed from GSS data by run-planning */
  // Fields from runContentSettings that are to be displayed in sample table.
  sampleTableRunContentSettingsFields: IRunContentSettingsField[];

  /**
   * This is purposely left using the original interface (instead of using wrapper object)
   * to avoid circular dependency
   */
  analysisDefinition?: gss.AnalysisDefinition;

  constructor(obj?: gss.AnalysisVersionDefinition) {
    if (!obj) {
      return;
    }
    assign(this, obj);
    this.updateReferenceFileFieldSettings();
    this.mapRunContentSettingsFieldsForSampleTable();
  }

  /**
   * Change an object's type to AnalysisVersionDefinition.
   * This makes the methods defined in AnalysisVersionDefinition available for that object.
   */
  public static wrap(obj: gss.AnalysisVersionDefinition): AnalysisVersionDefinition {
    const avd: AnalysisVersionDefinition = obj ? Object.setPrototypeOf(obj, AnalysisVersionDefinition.prototype) : null;
    if (avd) {
      avd.updateReferenceFileFieldSettings();
      avd.mapRunContentSettingsFieldsForSampleTable();
    }
    return avd;
  }

  public get isBclConvert() {
    return isBclConvert(this.analysisDefinition.name);
  }

  public get isArchived() {
    return this.status === 'Archived';
  }

  public get hasOverrideCyclesField(): boolean {
    const fields = get(this, 'analysisSettings.fields', []);
    return fields.findIndex(x => x.id === OVERRIDE_CYCLES_FIELD_ID) > -1;
  }
  
  public uiShouldManageOverrideCycles(isMultiAnalysis: boolean): boolean {
    return (isMultiAnalysis && !get(this, 'settings.hiddenUiFields', []).find(x => x === SUPPORTED_HIDDEN_UI_FIELDS.overrideCycles))
      || (!isMultiAnalysis && this.bclAvdWithOverrideCyclesSetting) || false;
  }

  public get bclAvdWithOverrideCyclesSetting(): boolean {
    const fields = get(this, 'analysisSettings.fields', []);
    return this.isBclConvert && fields.findIndex(x => x.id === OVERRIDE_CYCLES_FIELD_ID) > -1;
  }

  public get isDefaultBclConvert(): boolean {
    return this.isBclConvert
      && (get(this, 'settings.bclConvert.isDefaultBclConvert', false)
        || get(this, 'settings.bclConvert.isBclConvert', false));
  }

  public get formFields(): IFormField[] {
    return this.analysisSettings ? this.analysisSettings.fields : [];
  }

  public get visibleFormFields(): IFormField[] {
    return this.formFields ? this.formFields.filter(field => !field.hidden) : [];
  }

  public get canInvokeRenderAvd(): boolean {
    const settingsFields = get(this, 'analysisSettings.fields', []);
    const sampleSettingsFields = get(this, 'analysisSampleSettings.fields', []);

    return settingsFields.some(sf =>
      sf.updateRenderOnChange) || (sampleSettingsFields.some(ssf =>
        ssf.updateRenderOnChange && (
          ssf.configurationLevel === SampleSettingsConfigurationLevel.PHYSICAL ||
          ssf.configurationLevel === SampleSettingsConfigurationLevel.LOGICAL
        )
      )
    );
  }

  public get formatedVersion(): string {
    const version = this.softwareExecutionVersion || this.displayVersion || this.version;
    const regexForVersion = /\d+\.\d+\.\d+/;
    const matchedGroups = regexForVersion.exec(version);
    return isEmpty(matchedGroups) ? version : matchedGroups[0];
  }

  public fieldHasUpdateRenderOnChange(fieldId: string): boolean {
    if (isEmpty(fieldId)) {
      return false;
    }

    let fields = get(this, 'analysisSettings.fields', []);
    let field = fields.find(x => x.id === fieldId);
    if (field && field.updateRenderOnChange && !field.hidden) {
      return true;
    }

    fields = get(this, 'analysisSampleSettings.fields', []);
    field = fields.find(x => x.id === fieldId);
    return  (field && field.updateRenderOnChange && !field.hidden);
  }

  /**
   * Get AVD compatible library prep kits
   * Use AVD.compatibleLibraryPrepKitsDefinedForVersion if not empty
   * If AVD.compatibleLibraryPrepKitsDefinedForVersion is empty, use AVD.compatibleLibraryPrepKits
   */
  public get compatibleLPKs(): Array<LibraryPrepKitCompact> {
    return isEmpty(this.compatibleLibraryPrepKitsDefinedForVersion) ?
      this.compatibleLibraryPrepKits :
      this.compatibleLibraryPrepKitsDefinedForVersion;
  }

  public get effectiveCompatibleBclConvertVersions(): string[] {
    return isEmpty(this.compatibleBclConvertVersions) ? [this.softwareExecutionVersion || this.displayVersion] : this.compatibleBclConvertVersions;
  }

  public get hasSubSamples(): boolean {
    return Boolean(this.settings && this.settings.subSamplesConfiguration
      && !isEmpty(this.settings.subSamplesConfiguration.subSampleDefinitions));
  }

  public get subSamplesConfiguration(): SubSamplesConfiguration {
    return this.settings ? this.settings.subSamplesConfiguration : null;
  }

  public get subSampleDefinitions(): SubSampleDefinition[] {
    return this.hasSubSamples
      ? this.settings.subSamplesConfiguration.subSampleDefinitions
      : [];
  }

  /**
   * Get the field default-value either from sub-sample config or the field definition itself
   */
  public getSampleSettingFieldDefaultValue(field: any, subSampleDefId?: string): string {
    if (this.hasSubSamples) {
      const subSampleDef = this.settings.subSamplesConfiguration.subSampleDefinitions
        .find(x => x.id === subSampleDefId);

      if (subSampleDef && (field.id in subSampleDef.settings)) {
        return subSampleDef.settings[field.id];
      }
    }
    return this.getFieldDefaultValue(field);
  }

  /**
   * Get default value of a field definition.
   * This currently duplicate getDefaultValue function in helper-service, just to avoid circular dependency.
   */
  // TODO to remove the duplicated method in helper-service, perhaps by making IFormField a class.
  public getFieldDefaultValue(field) {
    let defaultValue: any = '';
    if (field.type === 'radio' || field.type === 'checkbox' || field.type === 'select') {
      // if field is optional, ignore default value returned by GSS
      if (field.required) {
        const selectedChoice = find(field.choices, 'selected');
        defaultValue = get(selectedChoice, 'value') || '';
      }
    } else if (field.value) {
      defaultValue = field.value;
    }

    // set value for required checkbox, if it has no value
    if ((field.type === 'checkbox') && field.required && (defaultValue === '')) {
      defaultValue = false;
    }
    return defaultValue;
  }

  /**
   * Genome field settings may contain hashTableVersion.
   * Reference file field needs the hashTableVersion in case user uploads a new reference file and
   *  need to tag genomes to the reference file metadata.
   * Genome and Reference file fields can be found in both analysisSettings and analysisSampleSettings.
   * In the context of a single config, fields in either can be cross-checked for hashTable version.
   */
  public updateReferenceFileFieldSettings() {
    const genomeFields: IFormField[] = [
      ...(this.analysisSampleSettings ? this.analysisSampleSettings.fields.filter(field => field.type === FormFieldType.GENOME) : []),
      ...(this.analysisSettings ? this.analysisSettings.fields.filter(field => field.type === FormFieldType.GENOME) : [])
    ];

    if (isEmpty(genomeFields)) {
      return;
    }

    if (this.analysisSettings) {
      this.analysisSettings.fields = this.modifyFields(this.analysisSettings.fields, genomeFields);
    }

    if (this.analysisSampleSettings) {
      this.analysisSampleSettings.fields = this.modifyFields(this.analysisSampleSettings.fields, genomeFields);
    }
  }

  private modifyFields(fields: IFormField[], genomeFields: IFormField[]): IFormField[] {
    return fields.map((field: IFormField) => {
      if (field.type === FormFieldType.REFERENCE_FILE) {
        const genomeFieldId = get(field, 'settings.referenceFileGenomeFilterFieldId');
        const genomeField = genomeFields.find(g => genomeFieldId && g.id === genomeFieldId);
        const hashTableVersion = get(genomeField, 'settings.hashTableVersion');
        if (hashTableVersion) {
          field.settings.referenceFileGenomeHashTableVersion = hashTableVersion;
        }
      }
      return field;
    });
  }

  private mapRunContentSettingsFieldsForSampleTable() {
    if (this.runContentSettings && this.runContentSettings.fields) {
      this.sampleTableRunContentSettingsFields = [];
      const visibleFields = this.runContentSettings.fields.filter(field => !field.hidden);
  
      for (const rawField of visibleFields) {
        const { id, label, type, required } = rawField;
        const field: IRunContentSettingsField = { id, label, type, required };
        this.sampleTableRunContentSettingsFields.push(field);
      }
    }
  }
}
